@import './mh_color_system.scss';
.shadow {
    box-shadow: 0 0.063rem 0.188rem 0 black-color(25);
}

.campaign-chart-container {
    @extend .d-flex;
    @extend .py-4;
    @extend .bg-white;
    @extend .border-radius-3
}

.exec-dash-min-height {
    @include min-height(17.69rem);
}

.exec-dash-min-height-big {
    min-height: 25rem;
    .error-container {
        min-height: 18rem;
    }
}

.no-data-found {
    min-height: 33rem !important;
    .error-container {
        min-height: 31rem;
    }
}

.less-height {
    min-height: 5.75rem;
}

.chart-panel {
    margin-top: 1.5rem;
}

.helper-text {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    hr {
        border: 0;
        border-top: 0.063rem solid $black_10;
        margin-bottom: .2rem!important;
        margin-top: .2rem!important;
    }
    p {
        color: $text-dark;
        margin-bottom: .3rem;
    }
    bottom: 0;
}

.chart-bg-container {
    background-color: $white;
    border: solid 0.063rem $border_group_title;
    border-radius: 0.313rem;
    @include border-radius(0.25rem);
    .chart-container {
        width: 100%;
        display: block;
        padding: 0.625rem;
    }
    &.container-border {
      border: solid 0.063rem $color_gray_silver_border;
      box-shadow: none !important;
    }

    .chart-title-icon {
        padding-top: 0.625rem;
        padding-left: 1.25rem;
        color: $text-dark;
        font-size: 0.938rem;
        padding-right: 0.625rem;
        font-family: $primary-font-bold;
        text-align: left;
        margin-bottom: 0;
        .mh-material-icons {
            color: $text-dark;
        }
    }
}

.highcharts-legend-item>span {
    width: 9.650rem;
}

.highcharts-legend-item .custom-legend>span {
    word-wrap: break-word;
    white-space: normal;
}

.pie-chart {
    .highcharts-legend div div {
        margin-top: 0.188rem;
    }
}

.highcharts-tooltip span {
    opacity: 1;
    z-index: 9999 !important;
}

.highcharts-legend g g {
    display: none;
}

.display-legend {
    .highcharts-legend g g {
        display: block !important;
    }
}

//for exec dashboard impresssions hr
.highcharts-subtitle {
    hr {
        margin-top: 0.425rem;
        margin-bottom: 0.375rem;
    }
}

.highcharts-legend-item:hover text,
.highcharts-legend-item:hover span {
    cursor: default !important;
}

.error-container {
    @extend .d-flex;
    @extend .justify-content-center;
    @extend .align-items-center;
    @include min-height(16.80rem);
    @extend .fst-italic;
}

.circle {
    margin-right: 0.313rem;
    width: 0.875rem;
    height: 0.875rem;
    @include border-radius( 0.5rem);
    font-size: 0.625rem;
    color: $white;
    line-height: 0.9375rem;
    text-align: center;
    display: inline-block;
    float: left;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    _::-webkit-full-page-media,
    _:future,
     :root,
    .custom-legend {
        line-height: 0.9 !important;
    }
}

.metric {
    h4 {
        font-size: 0.9375rem;
        font-family: $primary-font-bold;
        color: $text-dark;
        .mh-material-icons {
            color: $color_scarpa_flow_approx;
        }
    }
    .metric-value {
        color: $text-dark;
    }
    h5 {
        font-size: 0.75rem;
        font-family: $primary-font-bold;
        line-height: 1rem !important;
        color: $text-dark;
    }
    span {
        color: $text-dark;
    }
    a {
        color: $primary-blue-50 !important;
    }
}

.campaign-reports-wrapper {
    .chart-bg-container {
        margin: 1.25rem 0;
        .metric {
            h4 {
                font-size: 1.25rem;
                color: $text-dark;
                line-height: 1.25rem;
            }
            .error-container {
                min-height: 17rem;
            }
        }
    }
}

.campaign-reports-desc {
    border-left: solid 0.063rem $neutral-50;
    float: left
}