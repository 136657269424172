.popover {
    font-family: $primary-font;
    border-radius: 0px;
    .popover-header {
        border-bottom: none;
        background: transparent;
        text-align: center;
    }
    .popover-body {
        padding: 0.625rem 1.5625rem 1.25rem 1.5625rem !important;
        max-height: 20rem;
        overflow-y: auto;
    }
    &.preview-popover{
        min-width: 500px;
    }
}

.criteria-segment {
    min-width: 32rem;
    .popover-body {
        width: 11.625rem;
        padding: 0.125rem !important;
        .btn-close {
            padding: 0.25rem 0.8125rem !important;
        }
        .btn-yes {
            padding: 0.25rem 1.375rem !important;
        }
    }
}

.warning-message {
    min-width: 8.125rem;
    .popover-body {
        width: 14.625rem;
        padding: 0.125rem !important;
        .btn-close {
            padding: 0.25rem 0.8125rem !important;
            margin-right: 0.525rem!important;
        }
        .btn-yes {
            padding: 0.25rem 1.375rem !important;
        }
    }
}

//adding new class in bottom-right for popover position bottom-right.
.bs-popover-bottom-right {
    @extend .bs-popover-bottom
}