.nav-tabs {
  border-bottom: 0.063rem solid $neutral-30;
  z-index: 9;
  background-color: $white;
  .nav-item {
    cursor: pointer;
    list-style-type: none;
    font-size: .875rem;
    line-height: 1.5rem;
    color: $primary-green-40;
    padding: 0rem 1rem;
    border-bottom: 0.063rem solid transparent;
    border-radius: 0.125rem;
    &.active {
      border-bottom: 0.063rem solid $primary-green-40;
      border-radius: 0.125rem;
    }
    a.nav-link {
      color: $text-dark;
      padding: 0.56rem 1rem;
      border: none;
      .close-button {
        color: $neutral-90;
      }
      .circle {
        color: $neutral-90;
        width: 1.6rem;
        height: 1.62rem;
        line-height: 1rem;
        background: transparent;
        border: 0.063rem solid $neutral-50;
        border-radius: 50%;
        cursor: pointer;
        font-weight: 600;
      }
      &:hover,
      &:focus,
      &:active {
        border: none;
      }
      &.active {
        .circle {
          color: $white;
          background-color: $primary-green-40;
          font-weight: 600;
          border: 0.063rem solid $primary-green-40;
        }
      }
    }
    &.active {
      a.nav-link {
        font-weight: 600;
        color: $text-dark;
        .circle {
          color: $white;
          background-color: $primary-green-40;
          font-weight: 600;
          border: 0.063rem solid $primary-green-40;
        }
      }
    }
    & + li {
      & > .badge {
      background-color: $color_badge_light_gray;
      bottom: 0.625rem;
      color: $color_badge_blue;

        & + .beta-badge-tooltip {
          .tooltip-inner {
            max-width: 13.75rem;
          }
        }
      }
    }
  }
}

.sorter {
  right: 0.938rem;
  top: 0.938rem;
  width: 22%;
  font-size: .75rem;
  span {
    font-weight: 400;
  }
}