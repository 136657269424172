@import './mh_color_system.scss';

.uib-datepicker-popup {
  color: $primary-text-dark;
  padding: 0.313rem !important;
  @include border-radius(0.125rem);
  box-shadow: 0 1.125rem 2.375rem 0 rgba(0, 0, 0, 0.3),
    0 0.9375rem 0.75rem 0 rgba(0, 0, 0, 0.22);
  border: solid 0.0625rem transparent;
  background-image: linear-gradient($calendar-background, $calendar-background), linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.4) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0));
  background-origin: border-box;
  background-clip: content-box, border-box;

  .btn-light {
    font-size: 0.766rem;
    color: rgba(0, 0, 0, .87);
    font-weight: 700;
  }

  .disabled {
    background-color: $color_disabled !important;
    color: $text-gray_chalice !important;
    border: $color_disabled 0.063rem solid !important;
    pointer-events: none;
    font-weight: 700;
  }

  .ngb-dp-header {
    .ngb-dp-arrow {
      button {
        &.btn-link {
          &.ngb-dp-arrow-btn {
            color: $primary-text-dark;
            font-size: 12px;
          }
        }
      }
    }
  }

  .ngb-dp-content {
    &.ngb-dp-months {
      .ngb-dp-month {
        width: 100%;
        .ngb-dp-week {
          margin-bottom: 5px;
          display: flex !important;
          justify-content: space-between !important;
          &.ngb-dp-weekdays {
            >.ngb-dp-weekday {
              &.small {
                color: $primary-text-dark;
                font-weight: 700;
                font-size: 0.766rem;
                font-style: normal;
              }
            }
          }
          >.ngb-dp-day {
            &.ngb-dp-today {
              .btn-light {
                &.bg-primary {
                  color: $pem-template-bg-white;
                  background-color: $primary-blue-sapphire !important;
                  border: $primary-blue-sapphire 0.063rem solid !important;
                  &:hover {
                    color: $pem-template-bg-white !important;
                  }
                }
              }
            }
            .btn-light {
              &.text-muted {
                &.outside {
                  color: #6c757d !important;
                }
              }
              &.bg-primary {
                &.text-white {
                  color: $pem-template-bg-white;
                  background-color: $primary-blue-sapphire !important;
                  border: $primary-blue-sapphire 0.063rem solid !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
