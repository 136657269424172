@charset "UTF-8";
/*!
 * Bootstrap  v5.3.3 (https://getbootstrap.com/)
 * Copyright 2011-2024 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #cfe2ff;
  --bs-table-border-color: #a6b5cc;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e2e3e5;
  --bs-table-border-color: #b5b6b7;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #a7b9b1;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #a6c3ca;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #ccc2a4;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #c6acae;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #c6c7c8;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #4d5154;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}
.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-secondary-bg);
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn-check:checked:focus-visible + .btn {
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, .btn.disabled-opacity-1, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary, .btn-tertiary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5c636a;
  --bs-btn-hover-border-color: #565e64;
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #565e64;
  --bs-btn-active-border-color: #51585e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #31d2f2;
  --bs-btn-hover-border-color: #25cff2;
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3dd5f3;
  --bs-btn-active-border-color: #25cff2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffca2c;
  --bs-btn-hover-border-color: #ffc720;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffcd39;
  --bs-btn-active-border-color: #ffc720;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #bb2d3b;
  --bs-btn-hover-border-color: #b02a37;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b02a37;
  --bs-btn-active-border-color: #a52834;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #dc3545;
  --bs-btn-disabled-border-color: #dc3545;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d3d4d5;
  --bs-btn-hover-border-color: #c6c7c8;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #424649;
  --bs-btn-hover-border-color: #373b3e;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #373b3e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-outline-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-border-color: #0d6efd;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0d6efd;
  --bs-btn-hover-border-color: #0d6efd;
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0d6efd;
  --bs-btn-active-border-color: #0d6efd;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0d6efd;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0d6efd;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #6c757d;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #198754;
  --bs-btn-hover-border-color: #198754;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #198754;
  --bs-btn-active-border-color: #198754;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-border-color: #0dcaf0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #0dcaf0;
  --bs-btn-hover-border-color: #0dcaf0;
  --bs-btn-focus-shadow-rgb: 13, 202, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #0dcaf0;
  --bs-btn-active-border-color: #0dcaf0;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #0dcaf0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0dcaf0;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-border-color: #ffc107;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffc107;
  --bs-btn-hover-border-color: #ffc107;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffc107;
  --bs-btn-active-border-color: #ffc107;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffc107;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #dc3545;
  --bs-btn-border-color: #dc3545;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #dc3545;
  --bs-btn-hover-border-color: #dc3545;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #dc3545;
  --bs-btn-active-border-color: #dc3545;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dc3545;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dc3545;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f8f9fa;
  --bs-btn-border-color: #f8f9fa;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f8f9fa;
  --bs-btn-hover-border-color: #f8f9fa;
  --bs-btn-focus-shadow-rgb: 248, 249, 250;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f8f9fa;
  --bs-btn-active-border-color: #f8f9fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #f8f9fa;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f8f9fa;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #212529;
  --bs-btn-border-color: #212529;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #212529;
  --bs-btn-hover-border-color: #212529;
  --bs-btn-focus-shadow-rgb: 33, 37, 41;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #212529;
  --bs-btn-active-border-color: #212529;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #212529;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: underline;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.25rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item.disabled-opacity-1, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.875rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.nav-link.disabled, .nav-link.disabled-opacity-1, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #0d6efd;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.25rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23212529' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23052c65' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type > .accordion-header .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type > .accordion-header .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type > .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush > .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush > .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush > .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush > .accordion-item > .accordion-header .accordion-button, .accordion-flush > .accordion-item > .accordion-header .accordion-button.collapsed {
  border-radius: 0;
}
.accordion-flush > .accordion-item > .accordion-collapse {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #0d6efd;
  --bs-pagination-active-border-color: #0d6efd;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .page-link.disabled-opacity-1, .disabled > .page-link, .disabled-opacity-1 > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.25rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #0d6efd;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #0d6efd;
  --bs-list-group-active-border-color: #0d6efd;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item.disabled-opacity-1, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled, .btn-close.disabled-opacity-1 {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.875rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.875rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1rem;
  --bs-popover-header-color: inherit;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-bottom-right > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-bottom-right > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-bottom-right > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-bottom-right > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-bottom-right > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-bottom-right .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")*/;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")*/;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-xxl, .offcanvas-xl, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 991.98px) {
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1199.98px) {
  .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
    visibility: visible;
  }
}
@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xl .offcanvas-header {
    display: none;
  }
  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-xxl .offcanvas-header {
    display: none;
  }
  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-offcanvas-padding-y)) calc(-0.5 * var(--bs-offcanvas-padding-x)) calc(-0.5 * var(--bs-offcanvas-padding-y)) auto;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(10, 88, 202, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(10, 88, 202, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(86, 94, 100, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(86, 94, 100, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(20, 108, 67, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(20, 108, 67, var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-info:hover, .link-info:focus {
  color: RGBA(61, 213, 243, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(61, 213, 243, var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-warning:hover, .link-warning:focus {
  color: RGBA(255, 205, 57, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 205, 57, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(176, 42, 55, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(176, 42, 55, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(249, 250, 251, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(249, 250, 251, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(26, 30, 33, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(26, 30, 33, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex, .error-container, .campaign-chart-container {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: var(--bs-box-shadow) !important;
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important;
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center, .error-container {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center, .error-container {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4, .campaign-chart-container {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic, .error-container {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white, .campaign-chart-container {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-md-3 {
    row-gap: 1rem !important;
  }
  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-md-5 {
    row-gap: 3rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-md-3 {
    column-gap: 1rem !important;
  }
  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-md-5 {
    column-gap: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .object-fit-xl-contain {
    object-fit: contain !important;
  }
  .object-fit-xl-cover {
    object-fit: cover !important;
  }
  .object-fit-xl-fill {
    object-fit: fill !important;
  }
  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xl-none {
    object-fit: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-inline-grid {
    display: inline-grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .row-gap-xl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .object-fit-xxl-contain {
    object-fit: contain !important;
  }
  .object-fit-xxl-cover {
    object-fit: cover !important;
  }
  .object-fit-xxl-fill {
    object-fit: fill !important;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }
  .object-fit-xxl-none {
    object-fit: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-inline-grid {
    display: inline-grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }
  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }
  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }
  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }
  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }
  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }
  .fs-2 {
    font-size: 2rem !important;
  }
  .fs-3 {
    font-size: 1.75rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
/*
 * Patient Engagement Solutions Color System
 * source: https://www.figma.com/file/Lu88Lo49fvHlkG9qDh1SfC/_DesignSystem?node-id=0%3A1
 */
/* box-shadow for popover*/
/* box-shadow for card tiles*/
/* variables for button group*/
/* legend bullet for chart  */
/* mercury standard text colors */
/* Form-controls */
/* Bootstrap overrides */
/* border radius for all browsers*/
/* min height for containers*/
/* common style for Map Containers */
/**** Common Classes***/
/* dynamic width generator */
.width-1 {
  width: 1%;
}

.width-2 {
  width: 2%;
}

.width-3 {
  width: 3%;
}

.width-4 {
  width: 4%;
}

.width-5 {
  width: 5%;
}

.width-6 {
  width: 6%;
}

.width-7 {
  width: 7%;
}

.width-8 {
  width: 8%;
}

.width-9 {
  width: 9%;
}

.width-10 {
  width: 10%;
}

.width-11 {
  width: 11%;
}

.width-12 {
  width: 12%;
}

.width-13 {
  width: 13%;
}

.width-14 {
  width: 14%;
}

.width-15 {
  width: 15%;
}

.width-16 {
  width: 16%;
}

.width-17 {
  width: 17%;
}

.width-18 {
  width: 18%;
}

.width-19 {
  width: 19%;
}

.width-20 {
  width: 20%;
}

.width-21 {
  width: 21%;
}

.width-22 {
  width: 22%;
}

.width-23 {
  width: 23%;
}

.width-24 {
  width: 24%;
}

.width-25 {
  width: 25%;
}

.width-26 {
  width: 26%;
}

.width-27 {
  width: 27%;
}

.width-28 {
  width: 28%;
}

.width-29 {
  width: 29%;
}

.width-30 {
  width: 30%;
}

.width-31 {
  width: 31%;
}

.width-32 {
  width: 32%;
}

.width-33 {
  width: 33%;
}

.width-34 {
  width: 34%;
}

.width-35 {
  width: 35%;
}

.width-36 {
  width: 36%;
}

.width-37 {
  width: 37%;
}

.width-38 {
  width: 38%;
}

.width-39 {
  width: 39%;
}

.width-40 {
  width: 40%;
}

.width-41 {
  width: 41%;
}

.width-42 {
  width: 42%;
}

.width-43 {
  width: 43%;
}

.width-44 {
  width: 44%;
}

.width-45 {
  width: 45%;
}

.width-46 {
  width: 46%;
}

.width-47 {
  width: 47%;
}

.width-48 {
  width: 48%;
}

.width-49 {
  width: 49%;
}

.width-50 {
  width: 50%;
}

.width-51 {
  width: 51%;
}

.width-52 {
  width: 52%;
}

.width-53 {
  width: 53%;
}

.width-54 {
  width: 54%;
}

.width-55 {
  width: 55%;
}

.width-56 {
  width: 56%;
}

.width-57 {
  width: 57%;
}

.width-58 {
  width: 58%;
}

.width-59 {
  width: 59%;
}

.width-60 {
  width: 60%;
}

.width-61 {
  width: 61%;
}

.width-62 {
  width: 62%;
}

.width-63 {
  width: 63%;
}

.width-64 {
  width: 64%;
}

.width-65 {
  width: 65%;
}

.width-66 {
  width: 66%;
}

.width-67 {
  width: 67%;
}

.width-68 {
  width: 68%;
}

.width-69 {
  width: 69%;
}

.width-70 {
  width: 70%;
}

.width-71 {
  width: 71%;
}

.width-72 {
  width: 72%;
}

.width-73 {
  width: 73%;
}

.width-74 {
  width: 74%;
}

.width-75 {
  width: 75%;
}

.width-76 {
  width: 76%;
}

.width-77 {
  width: 77%;
}

.width-78 {
  width: 78%;
}

.width-79 {
  width: 79%;
}

.width-80 {
  width: 80%;
}

.width-81 {
  width: 81%;
}

.width-82 {
  width: 82%;
}

.width-83 {
  width: 83%;
}

.width-84 {
  width: 84%;
}

.width-85 {
  width: 85%;
}

.width-86 {
  width: 86%;
}

.width-87 {
  width: 87%;
}

.width-88 {
  width: 88%;
}

.width-89 {
  width: 89%;
}

.width-90 {
  width: 90%;
}

.width-91 {
  width: 91%;
}

.width-92 {
  width: 92%;
}

.width-93 {
  width: 93%;
}

.width-94 {
  width: 94%;
}

.width-95 {
  width: 95%;
}

.width-96 {
  width: 96%;
}

.width-97 {
  width: 97%;
}

.width-98 {
  width: 98%;
}

.width-99 {
  width: 99%;
}

.width-100 {
  width: 100%;
}

/* dynamic top generator */
.top-1 {
  top: 1%;
}

.top-2 {
  top: 2%;
}

.top-3 {
  top: 3%;
}

.top-4 {
  top: 4%;
}

.top-5 {
  top: 5%;
}

.top-6 {
  top: 6%;
}

.top-7 {
  top: 7%;
}

.top-8 {
  top: 8%;
}

.top-9 {
  top: 9%;
}

.top-10 {
  top: 10%;
}

.top-11 {
  top: 11%;
}

.top-12 {
  top: 12%;
}

.top-13 {
  top: 13%;
}

.top-14 {
  top: 14%;
}

.top-15 {
  top: 15%;
}

.top-16 {
  top: 16%;
}

.top-17 {
  top: 17%;
}

.top-18 {
  top: 18%;
}

.top-19 {
  top: 19%;
}

.top-20 {
  top: 20%;
}

.top-21 {
  top: 21%;
}

.top-22 {
  top: 22%;
}

.top-23 {
  top: 23%;
}

.top-24 {
  top: 24%;
}

.top-25 {
  top: 25%;
}

.top-26 {
  top: 26%;
}

.top-27 {
  top: 27%;
}

.top-28 {
  top: 28%;
}

.top-29 {
  top: 29%;
}

.top-30 {
  top: 30%;
}

.top-31 {
  top: 31%;
}

.top-32 {
  top: 32%;
}

.top-33 {
  top: 33%;
}

.top-34 {
  top: 34%;
}

.top-35 {
  top: 35%;
}

.top-36 {
  top: 36%;
}

.top-37 {
  top: 37%;
}

.top-38 {
  top: 38%;
}

.top-39 {
  top: 39%;
}

.top-40 {
  top: 40%;
}

.top-41 {
  top: 41%;
}

.top-42 {
  top: 42%;
}

.top-43 {
  top: 43%;
}

.top-44 {
  top: 44%;
}

.top-45 {
  top: 45%;
}

.top-46 {
  top: 46%;
}

.top-47 {
  top: 47%;
}

.top-48 {
  top: 48%;
}

.top-49 {
  top: 49%;
}

.top-50 {
  top: 50%;
}

.top-51 {
  top: 51%;
}

.top-52 {
  top: 52%;
}

.top-53 {
  top: 53%;
}

.top-54 {
  top: 54%;
}

.top-55 {
  top: 55%;
}

.top-56 {
  top: 56%;
}

.top-57 {
  top: 57%;
}

.top-58 {
  top: 58%;
}

.top-59 {
  top: 59%;
}

.top-60 {
  top: 60%;
}

.top-61 {
  top: 61%;
}

.top-62 {
  top: 62%;
}

.top-63 {
  top: 63%;
}

.top-64 {
  top: 64%;
}

.top-65 {
  top: 65%;
}

.top-66 {
  top: 66%;
}

.top-67 {
  top: 67%;
}

.top-68 {
  top: 68%;
}

.top-69 {
  top: 69%;
}

.top-70 {
  top: 70%;
}

.top-71 {
  top: 71%;
}

.top-72 {
  top: 72%;
}

.top-73 {
  top: 73%;
}

.top-74 {
  top: 74%;
}

.top-75 {
  top: 75%;
}

.top-76 {
  top: 76%;
}

.top-77 {
  top: 77%;
}

.top-78 {
  top: 78%;
}

.top-79 {
  top: 79%;
}

.top-80 {
  top: 80%;
}

.top-81 {
  top: 81%;
}

.top-82 {
  top: 82%;
}

.top-83 {
  top: 83%;
}

.top-84 {
  top: 84%;
}

.top-85 {
  top: 85%;
}

.top-86 {
  top: 86%;
}

.top-87 {
  top: 87%;
}

.top-88 {
  top: 88%;
}

.top-89 {
  top: 89%;
}

.top-90 {
  top: 90%;
}

.top-91 {
  top: 91%;
}

.top-92 {
  top: 92%;
}

.top-93 {
  top: 93%;
}

.top-94 {
  top: 94%;
}

.top-95 {
  top: 95%;
}

.top-96 {
  top: 96%;
}

.top-97 {
  top: 97%;
}

.top-98 {
  top: 98%;
}

.top-99 {
  top: 99%;
}

.top-100 {
  top: 100%;
}

/* dynamic font size generator */
.font-size-1 {
  font-size: 0.0625rem !important;
}

.font-size-2 {
  font-size: 0.125rem !important;
}

.font-size-3 {
  font-size: 0.1875rem !important;
}

.font-size-4 {
  font-size: 0.25rem !important;
}

.font-size-5 {
  font-size: 0.3125rem !important;
}

.font-size-6 {
  font-size: 0.375rem !important;
}

.font-size-7 {
  font-size: 0.4375rem !important;
}

.font-size-8 {
  font-size: 0.5rem !important;
}

.font-size-9 {
  font-size: 0.5625rem !important;
}

.font-size-10 {
  font-size: 0.625rem !important;
}

.font-size-11 {
  font-size: 0.6875rem !important;
}

.font-size-12 {
  font-size: 0.75rem !important;
}

.font-size-13 {
  font-size: 0.8125rem !important;
}

.font-size-14 {
  font-size: 0.875rem !important;
}

.font-size-15 {
  font-size: 0.9375rem !important;
}

.font-size-16 {
  font-size: 1rem !important;
}

.font-size-17 {
  font-size: 1.0625rem !important;
}

.font-size-18 {
  font-size: 1.125rem !important;
}

.font-size-19 {
  font-size: 1.1875rem !important;
}

.font-size-20 {
  font-size: 1.25rem !important;
}

.font-size-21 {
  font-size: 1.3125rem !important;
}

.font-size-22 {
  font-size: 1.375rem !important;
}

.font-size-23 {
  font-size: 1.4375rem !important;
}

.font-size-24 {
  font-size: 1.5rem !important;
}

.font-size-25 {
  font-size: 1.5625rem !important;
}

.font-size-26 {
  font-size: 1.625rem !important;
}

.font-size-27 {
  font-size: 1.6875rem !important;
}

.font-size-28 {
  font-size: 1.75rem !important;
}

.font-size-29 {
  font-size: 1.8125rem !important;
}

.font-size-30 {
  font-size: 1.875rem !important;
}

.font-size-31 {
  font-size: 1.9375rem !important;
}

.font-size-32 {
  font-size: 2rem !important;
}

.font-size-33 {
  font-size: 2.0625rem !important;
}

.font-size-34 {
  font-size: 2.125rem !important;
}

.font-size-35 {
  font-size: 2.1875rem !important;
}

.font-size-36 {
  font-size: 2.25rem !important;
}

.font-size-37 {
  font-size: 2.3125rem !important;
}

.font-size-38 {
  font-size: 2.375rem !important;
}

.font-size-39 {
  font-size: 2.4375rem !important;
}

.font-size-40 {
  font-size: 2.5rem !important;
}

.font-size-41 {
  font-size: 2.5625rem !important;
}

.font-size-42 {
  font-size: 2.625rem !important;
}

.font-size-43 {
  font-size: 2.6875rem !important;
}

.font-size-44 {
  font-size: 2.75rem !important;
}

.font-size-45 {
  font-size: 2.8125rem !important;
}

.font-size-46 {
  font-size: 2.875rem !important;
}

.font-size-47 {
  font-size: 2.9375rem !important;
}

.font-size-48 {
  font-size: 3rem !important;
}

.font-size-49 {
  font-size: 3.0625rem !important;
}

.font-size-50 {
  font-size: 3.125rem !important;
}

.font-size-51 {
  font-size: 3.1875rem !important;
}

.font-size-52 {
  font-size: 3.25rem !important;
}

.font-size-53 {
  font-size: 3.3125rem !important;
}

.font-size-54 {
  font-size: 3.375rem !important;
}

.font-size-55 {
  font-size: 3.4375rem !important;
}

.font-size-56 {
  font-size: 3.5rem !important;
}

.font-size-57 {
  font-size: 3.5625rem !important;
}

.font-size-58 {
  font-size: 3.625rem !important;
}

.font-size-59 {
  font-size: 3.6875rem !important;
}

.font-size-60 {
  font-size: 3.75rem !important;
}

.font-size-61 {
  font-size: 3.8125rem !important;
}

.font-size-62 {
  font-size: 3.875rem !important;
}

.font-size-63 {
  font-size: 3.9375rem !important;
}

.font-size-64 {
  font-size: 4rem !important;
}

.font-size-65 {
  font-size: 4.0625rem !important;
}

.font-size-66 {
  font-size: 4.125rem !important;
}

.font-size-67 {
  font-size: 4.1875rem !important;
}

.font-size-68 {
  font-size: 4.25rem !important;
}

.font-size-69 {
  font-size: 4.3125rem !important;
}

.font-size-70 {
  font-size: 4.375rem !important;
}

.font-size-71 {
  font-size: 4.4375rem !important;
}

.font-size-72 {
  font-size: 4.5rem !important;
}

.font-size-73 {
  font-size: 4.5625rem !important;
}

.font-size-74 {
  font-size: 4.625rem !important;
}

.font-size-75 {
  font-size: 4.6875rem !important;
}

.font-size-76 {
  font-size: 4.75rem !important;
}

.font-size-77 {
  font-size: 4.8125rem !important;
}

.font-size-78 {
  font-size: 4.875rem !important;
}

.font-size-79 {
  font-size: 4.9375rem !important;
}

.font-size-80 {
  font-size: 5rem !important;
}

.font-size-81 {
  font-size: 5.0625rem !important;
}

.font-size-82 {
  font-size: 5.125rem !important;
}

.font-size-83 {
  font-size: 5.1875rem !important;
}

.font-size-84 {
  font-size: 5.25rem !important;
}

.font-size-85 {
  font-size: 5.3125rem !important;
}

.font-size-86 {
  font-size: 5.375rem !important;
}

.font-size-87 {
  font-size: 5.4375rem !important;
}

.font-size-88 {
  font-size: 5.5rem !important;
}

.font-size-89 {
  font-size: 5.5625rem !important;
}

.font-size-90 {
  font-size: 5.625rem !important;
}

.font-size-91 {
  font-size: 5.6875rem !important;
}

.font-size-92 {
  font-size: 5.75rem !important;
}

.font-size-93 {
  font-size: 5.8125rem !important;
}

.font-size-94 {
  font-size: 5.875rem !important;
}

.font-size-95 {
  font-size: 5.9375rem !important;
}

.font-size-96 {
  font-size: 6rem !important;
}

.font-size-97 {
  font-size: 6.0625rem !important;
}

.font-size-98 {
  font-size: 6.125rem !important;
}

.font-size-99 {
  font-size: 6.1875rem !important;
}

.font-size-100 {
  font-size: 6.25rem !important;
}

/*class for border radius dynamic*/
.border-radius-1 {
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  -ms-border-radius: 1px;
  border-radius: 1px;
}

.border-radius-2 {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
}

.border-radius-3, .campaign-chart-container {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

.border-radius-4 {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}

.border-radius-5 {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
}

.border-radius-6 {
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  border-radius: 6px;
}

.border-radius-7 {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  border-radius: 7px;
}

.border-radius-8 {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  border-radius: 8px;
}

.border-radius-9 {
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  -ms-border-radius: 9px;
  border-radius: 9px;
}

.border-radius-10 {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}

.border-radius-11 {
  -webkit-border-radius: 11px;
  -moz-border-radius: 11px;
  -ms-border-radius: 11px;
  border-radius: 11px;
}

.border-radius-12 {
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  border-radius: 12px;
}

.border-radius-13 {
  -webkit-border-radius: 13px;
  -moz-border-radius: 13px;
  -ms-border-radius: 13px;
  border-radius: 13px;
}

.border-radius-14 {
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  border-radius: 14px;
}

.border-radius-15 {
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  border-radius: 15px;
}

.border-radius-16 {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  -ms-border-radius: 16px;
  border-radius: 16px;
}

.border-radius-17 {
  -webkit-border-radius: 17px;
  -moz-border-radius: 17px;
  -ms-border-radius: 17px;
  border-radius: 17px;
}

.border-radius-18 {
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  -ms-border-radius: 18px;
  border-radius: 18px;
}

.border-radius-19 {
  -webkit-border-radius: 19px;
  -moz-border-radius: 19px;
  -ms-border-radius: 19px;
  border-radius: 19px;
}

.border-radius-20 {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  border-radius: 20px;
}

.border-radius-21 {
  -webkit-border-radius: 21px;
  -moz-border-radius: 21px;
  -ms-border-radius: 21px;
  border-radius: 21px;
}

.border-radius-22 {
  -webkit-border-radius: 22px;
  -moz-border-radius: 22px;
  -ms-border-radius: 22px;
  border-radius: 22px;
}

.border-radius-23 {
  -webkit-border-radius: 23px;
  -moz-border-radius: 23px;
  -ms-border-radius: 23px;
  border-radius: 23px;
}

.border-radius-24 {
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  border-radius: 24px;
}

.border-radius-25 {
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  border-radius: 25px;
}

.border-radius-26 {
  -webkit-border-radius: 26px;
  -moz-border-radius: 26px;
  -ms-border-radius: 26px;
  border-radius: 26px;
}

.border-radius-27 {
  -webkit-border-radius: 27px;
  -moz-border-radius: 27px;
  -ms-border-radius: 27px;
  border-radius: 27px;
}

.border-radius-28 {
  -webkit-border-radius: 28px;
  -moz-border-radius: 28px;
  -ms-border-radius: 28px;
  border-radius: 28px;
}

.border-radius-29 {
  -webkit-border-radius: 29px;
  -moz-border-radius: 29px;
  -ms-border-radius: 29px;
  border-radius: 29px;
}

.border-radius-30 {
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
}

.border-radius-31 {
  -webkit-border-radius: 31px;
  -moz-border-radius: 31px;
  -ms-border-radius: 31px;
  border-radius: 31px;
}

.border-radius-32 {
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  -ms-border-radius: 32px;
  border-radius: 32px;
}

.border-radius-33 {
  -webkit-border-radius: 33px;
  -moz-border-radius: 33px;
  -ms-border-radius: 33px;
  border-radius: 33px;
}

.border-radius-34 {
  -webkit-border-radius: 34px;
  -moz-border-radius: 34px;
  -ms-border-radius: 34px;
  border-radius: 34px;
}

.border-radius-35 {
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  -ms-border-radius: 35px;
  border-radius: 35px;
}

.border-radius-36 {
  -webkit-border-radius: 36px;
  -moz-border-radius: 36px;
  -ms-border-radius: 36px;
  border-radius: 36px;
}

.border-radius-37 {
  -webkit-border-radius: 37px;
  -moz-border-radius: 37px;
  -ms-border-radius: 37px;
  border-radius: 37px;
}

.border-radius-38 {
  -webkit-border-radius: 38px;
  -moz-border-radius: 38px;
  -ms-border-radius: 38px;
  border-radius: 38px;
}

.border-radius-39 {
  -webkit-border-radius: 39px;
  -moz-border-radius: 39px;
  -ms-border-radius: 39px;
  border-radius: 39px;
}

.border-radius-40 {
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  border-radius: 40px;
}

.border-radius-41 {
  -webkit-border-radius: 41px;
  -moz-border-radius: 41px;
  -ms-border-radius: 41px;
  border-radius: 41px;
}

.border-radius-42 {
  -webkit-border-radius: 42px;
  -moz-border-radius: 42px;
  -ms-border-radius: 42px;
  border-radius: 42px;
}

.border-radius-43 {
  -webkit-border-radius: 43px;
  -moz-border-radius: 43px;
  -ms-border-radius: 43px;
  border-radius: 43px;
}

.border-radius-44 {
  -webkit-border-radius: 44px;
  -moz-border-radius: 44px;
  -ms-border-radius: 44px;
  border-radius: 44px;
}

.border-radius-45 {
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  -ms-border-radius: 45px;
  border-radius: 45px;
}

.border-radius-46 {
  -webkit-border-radius: 46px;
  -moz-border-radius: 46px;
  -ms-border-radius: 46px;
  border-radius: 46px;
}

.border-radius-47 {
  -webkit-border-radius: 47px;
  -moz-border-radius: 47px;
  -ms-border-radius: 47px;
  border-radius: 47px;
}

.border-radius-48 {
  -webkit-border-radius: 48px;
  -moz-border-radius: 48px;
  -ms-border-radius: 48px;
  border-radius: 48px;
}

.border-radius-49 {
  -webkit-border-radius: 49px;
  -moz-border-radius: 49px;
  -ms-border-radius: 49px;
  border-radius: 49px;
}

.border-radius-50 {
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px;
}

.border-radius-51 {
  -webkit-border-radius: 51px;
  -moz-border-radius: 51px;
  -ms-border-radius: 51px;
  border-radius: 51px;
}

.border-radius-52 {
  -webkit-border-radius: 52px;
  -moz-border-radius: 52px;
  -ms-border-radius: 52px;
  border-radius: 52px;
}

.border-radius-53 {
  -webkit-border-radius: 53px;
  -moz-border-radius: 53px;
  -ms-border-radius: 53px;
  border-radius: 53px;
}

.border-radius-54 {
  -webkit-border-radius: 54px;
  -moz-border-radius: 54px;
  -ms-border-radius: 54px;
  border-radius: 54px;
}

.border-radius-55 {
  -webkit-border-radius: 55px;
  -moz-border-radius: 55px;
  -ms-border-radius: 55px;
  border-radius: 55px;
}

.border-radius-56 {
  -webkit-border-radius: 56px;
  -moz-border-radius: 56px;
  -ms-border-radius: 56px;
  border-radius: 56px;
}

.border-radius-57 {
  -webkit-border-radius: 57px;
  -moz-border-radius: 57px;
  -ms-border-radius: 57px;
  border-radius: 57px;
}

.border-radius-58 {
  -webkit-border-radius: 58px;
  -moz-border-radius: 58px;
  -ms-border-radius: 58px;
  border-radius: 58px;
}

.border-radius-59 {
  -webkit-border-radius: 59px;
  -moz-border-radius: 59px;
  -ms-border-radius: 59px;
  border-radius: 59px;
}

.border-radius-60 {
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  border-radius: 60px;
}

.border-radius-61 {
  -webkit-border-radius: 61px;
  -moz-border-radius: 61px;
  -ms-border-radius: 61px;
  border-radius: 61px;
}

.border-radius-62 {
  -webkit-border-radius: 62px;
  -moz-border-radius: 62px;
  -ms-border-radius: 62px;
  border-radius: 62px;
}

.border-radius-63 {
  -webkit-border-radius: 63px;
  -moz-border-radius: 63px;
  -ms-border-radius: 63px;
  border-radius: 63px;
}

.border-radius-64 {
  -webkit-border-radius: 64px;
  -moz-border-radius: 64px;
  -ms-border-radius: 64px;
  border-radius: 64px;
}

.border-radius-65 {
  -webkit-border-radius: 65px;
  -moz-border-radius: 65px;
  -ms-border-radius: 65px;
  border-radius: 65px;
}

.border-radius-66 {
  -webkit-border-radius: 66px;
  -moz-border-radius: 66px;
  -ms-border-radius: 66px;
  border-radius: 66px;
}

.border-radius-67 {
  -webkit-border-radius: 67px;
  -moz-border-radius: 67px;
  -ms-border-radius: 67px;
  border-radius: 67px;
}

.border-radius-68 {
  -webkit-border-radius: 68px;
  -moz-border-radius: 68px;
  -ms-border-radius: 68px;
  border-radius: 68px;
}

.border-radius-69 {
  -webkit-border-radius: 69px;
  -moz-border-radius: 69px;
  -ms-border-radius: 69px;
  border-radius: 69px;
}

.border-radius-70 {
  -webkit-border-radius: 70px;
  -moz-border-radius: 70px;
  -ms-border-radius: 70px;
  border-radius: 70px;
}

.border-radius-71 {
  -webkit-border-radius: 71px;
  -moz-border-radius: 71px;
  -ms-border-radius: 71px;
  border-radius: 71px;
}

.border-radius-72 {
  -webkit-border-radius: 72px;
  -moz-border-radius: 72px;
  -ms-border-radius: 72px;
  border-radius: 72px;
}

.border-radius-73 {
  -webkit-border-radius: 73px;
  -moz-border-radius: 73px;
  -ms-border-radius: 73px;
  border-radius: 73px;
}

.border-radius-74 {
  -webkit-border-radius: 74px;
  -moz-border-radius: 74px;
  -ms-border-radius: 74px;
  border-radius: 74px;
}

.border-radius-75 {
  -webkit-border-radius: 75px;
  -moz-border-radius: 75px;
  -ms-border-radius: 75px;
  border-radius: 75px;
}

.border-radius-76 {
  -webkit-border-radius: 76px;
  -moz-border-radius: 76px;
  -ms-border-radius: 76px;
  border-radius: 76px;
}

.border-radius-77 {
  -webkit-border-radius: 77px;
  -moz-border-radius: 77px;
  -ms-border-radius: 77px;
  border-radius: 77px;
}

.border-radius-78 {
  -webkit-border-radius: 78px;
  -moz-border-radius: 78px;
  -ms-border-radius: 78px;
  border-radius: 78px;
}

.border-radius-79 {
  -webkit-border-radius: 79px;
  -moz-border-radius: 79px;
  -ms-border-radius: 79px;
  border-radius: 79px;
}

.border-radius-80 {
  -webkit-border-radius: 80px;
  -moz-border-radius: 80px;
  -ms-border-radius: 80px;
  border-radius: 80px;
}

.border-radius-81 {
  -webkit-border-radius: 81px;
  -moz-border-radius: 81px;
  -ms-border-radius: 81px;
  border-radius: 81px;
}

.border-radius-82 {
  -webkit-border-radius: 82px;
  -moz-border-radius: 82px;
  -ms-border-radius: 82px;
  border-radius: 82px;
}

.border-radius-83 {
  -webkit-border-radius: 83px;
  -moz-border-radius: 83px;
  -ms-border-radius: 83px;
  border-radius: 83px;
}

.border-radius-84 {
  -webkit-border-radius: 84px;
  -moz-border-radius: 84px;
  -ms-border-radius: 84px;
  border-radius: 84px;
}

.border-radius-85 {
  -webkit-border-radius: 85px;
  -moz-border-radius: 85px;
  -ms-border-radius: 85px;
  border-radius: 85px;
}

.border-radius-86 {
  -webkit-border-radius: 86px;
  -moz-border-radius: 86px;
  -ms-border-radius: 86px;
  border-radius: 86px;
}

.border-radius-87 {
  -webkit-border-radius: 87px;
  -moz-border-radius: 87px;
  -ms-border-radius: 87px;
  border-radius: 87px;
}

.border-radius-88 {
  -webkit-border-radius: 88px;
  -moz-border-radius: 88px;
  -ms-border-radius: 88px;
  border-radius: 88px;
}

.border-radius-89 {
  -webkit-border-radius: 89px;
  -moz-border-radius: 89px;
  -ms-border-radius: 89px;
  border-radius: 89px;
}

.border-radius-90 {
  -webkit-border-radius: 90px;
  -moz-border-radius: 90px;
  -ms-border-radius: 90px;
  border-radius: 90px;
}

.border-radius-91 {
  -webkit-border-radius: 91px;
  -moz-border-radius: 91px;
  -ms-border-radius: 91px;
  border-radius: 91px;
}

.border-radius-92 {
  -webkit-border-radius: 92px;
  -moz-border-radius: 92px;
  -ms-border-radius: 92px;
  border-radius: 92px;
}

.border-radius-93 {
  -webkit-border-radius: 93px;
  -moz-border-radius: 93px;
  -ms-border-radius: 93px;
  border-radius: 93px;
}

.border-radius-94 {
  -webkit-border-radius: 94px;
  -moz-border-radius: 94px;
  -ms-border-radius: 94px;
  border-radius: 94px;
}

.border-radius-95 {
  -webkit-border-radius: 95px;
  -moz-border-radius: 95px;
  -ms-border-radius: 95px;
  border-radius: 95px;
}

.border-radius-96 {
  -webkit-border-radius: 96px;
  -moz-border-radius: 96px;
  -ms-border-radius: 96px;
  border-radius: 96px;
}

.border-radius-97 {
  -webkit-border-radius: 97px;
  -moz-border-radius: 97px;
  -ms-border-radius: 97px;
  border-radius: 97px;
}

.border-radius-98 {
  -webkit-border-radius: 98px;
  -moz-border-radius: 98px;
  -ms-border-radius: 98px;
  border-radius: 98px;
}

.border-radius-99 {
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  -ms-border-radius: 99px;
  border-radius: 99px;
}

.border-radius-100 {
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  border-radius: 100px;
}

/*class for min height dynamic*/
.min-height-1 {
  min-height: 1px;
}

.min-height-2 {
  min-height: 2px;
}

.min-height-3 {
  min-height: 3px;
}

.min-height-4 {
  min-height: 4px;
}

.min-height-5 {
  min-height: 5px;
}

.min-height-6 {
  min-height: 6px;
}

.min-height-7 {
  min-height: 7px;
}

.min-height-8 {
  min-height: 8px;
}

.min-height-9 {
  min-height: 9px;
}

.min-height-10 {
  min-height: 10px;
}

.min-height-11 {
  min-height: 11px;
}

.min-height-12 {
  min-height: 12px;
}

.min-height-13 {
  min-height: 13px;
}

.min-height-14 {
  min-height: 14px;
}

.min-height-15 {
  min-height: 15px;
}

.min-height-16 {
  min-height: 16px;
}

.min-height-17 {
  min-height: 17px;
}

.min-height-18 {
  min-height: 18px;
}

.min-height-19 {
  min-height: 19px;
}

.min-height-20 {
  min-height: 20px;
}

.min-height-21 {
  min-height: 21px;
}

.min-height-22 {
  min-height: 22px;
}

.min-height-23 {
  min-height: 23px;
}

.min-height-24 {
  min-height: 24px;
}

.min-height-25 {
  min-height: 25px;
}

.min-height-26 {
  min-height: 26px;
}

.min-height-27 {
  min-height: 27px;
}

.min-height-28 {
  min-height: 28px;
}

.min-height-29 {
  min-height: 29px;
}

.min-height-30 {
  min-height: 30px;
}

.min-height-31 {
  min-height: 31px;
}

.min-height-32 {
  min-height: 32px;
}

.min-height-33 {
  min-height: 33px;
}

.min-height-34 {
  min-height: 34px;
}

.min-height-35 {
  min-height: 35px;
}

.min-height-36 {
  min-height: 36px;
}

.min-height-37 {
  min-height: 37px;
}

.min-height-38 {
  min-height: 38px;
}

.min-height-39 {
  min-height: 39px;
}

.min-height-40 {
  min-height: 40px;
}

.min-height-41 {
  min-height: 41px;
}

.min-height-42 {
  min-height: 42px;
}

.min-height-43 {
  min-height: 43px;
}

.min-height-44 {
  min-height: 44px;
}

.min-height-45 {
  min-height: 45px;
}

.min-height-46 {
  min-height: 46px;
}

.min-height-47 {
  min-height: 47px;
}

.min-height-48 {
  min-height: 48px;
}

.min-height-49 {
  min-height: 49px;
}

.min-height-50 {
  min-height: 50px;
}

.min-height-51 {
  min-height: 51px;
}

.min-height-52 {
  min-height: 52px;
}

.min-height-53 {
  min-height: 53px;
}

.min-height-54 {
  min-height: 54px;
}

.min-height-55 {
  min-height: 55px;
}

.min-height-56 {
  min-height: 56px;
}

.min-height-57 {
  min-height: 57px;
}

.min-height-58 {
  min-height: 58px;
}

.min-height-59 {
  min-height: 59px;
}

.min-height-60 {
  min-height: 60px;
}

.min-height-61 {
  min-height: 61px;
}

.min-height-62 {
  min-height: 62px;
}

.min-height-63 {
  min-height: 63px;
}

.min-height-64 {
  min-height: 64px;
}

.min-height-65 {
  min-height: 65px;
}

.min-height-66 {
  min-height: 66px;
}

.min-height-67 {
  min-height: 67px;
}

.min-height-68 {
  min-height: 68px;
}

.min-height-69 {
  min-height: 69px;
}

.min-height-70 {
  min-height: 70px;
}

.min-height-71 {
  min-height: 71px;
}

.min-height-72 {
  min-height: 72px;
}

.min-height-73 {
  min-height: 73px;
}

.min-height-74 {
  min-height: 74px;
}

.min-height-75 {
  min-height: 75px;
}

.min-height-76 {
  min-height: 76px;
}

.min-height-77 {
  min-height: 77px;
}

.min-height-78 {
  min-height: 78px;
}

.min-height-79 {
  min-height: 79px;
}

.min-height-80 {
  min-height: 80px;
}

.min-height-81 {
  min-height: 81px;
}

.min-height-82 {
  min-height: 82px;
}

.min-height-83 {
  min-height: 83px;
}

.min-height-84 {
  min-height: 84px;
}

.min-height-85 {
  min-height: 85px;
}

.min-height-86 {
  min-height: 86px;
}

.min-height-87 {
  min-height: 87px;
}

.min-height-88 {
  min-height: 88px;
}

.min-height-89 {
  min-height: 89px;
}

.min-height-90 {
  min-height: 90px;
}

.min-height-91 {
  min-height: 91px;
}

.min-height-92 {
  min-height: 92px;
}

.min-height-93 {
  min-height: 93px;
}

.min-height-94 {
  min-height: 94px;
}

.min-height-95 {
  min-height: 95px;
}

.min-height-96 {
  min-height: 96px;
}

.min-height-97 {
  min-height: 97px;
}

.min-height-98 {
  min-height: 98px;
}

.min-height-99 {
  min-height: 99px;
}

.min-height-100 {
  min-height: 100px;
}

.min-height-101 {
  min-height: 101px;
}

.min-height-102 {
  min-height: 102px;
}

.min-height-103 {
  min-height: 103px;
}

.min-height-104 {
  min-height: 104px;
}

.min-height-105 {
  min-height: 105px;
}

.min-height-106 {
  min-height: 106px;
}

.min-height-107 {
  min-height: 107px;
}

.min-height-108 {
  min-height: 108px;
}

.min-height-109 {
  min-height: 109px;
}

.min-height-110 {
  min-height: 110px;
}

.min-height-111 {
  min-height: 111px;
}

.min-height-112 {
  min-height: 112px;
}

.min-height-113 {
  min-height: 113px;
}

.min-height-114 {
  min-height: 114px;
}

.min-height-115 {
  min-height: 115px;
}

.min-height-116 {
  min-height: 116px;
}

.min-height-117 {
  min-height: 117px;
}

.min-height-118 {
  min-height: 118px;
}

.min-height-119 {
  min-height: 119px;
}

.min-height-120 {
  min-height: 120px;
}

.min-height-121 {
  min-height: 121px;
}

.min-height-122 {
  min-height: 122px;
}

.min-height-123 {
  min-height: 123px;
}

.min-height-124 {
  min-height: 124px;
}

.min-height-125 {
  min-height: 125px;
}

.min-height-126 {
  min-height: 126px;
}

.min-height-127 {
  min-height: 127px;
}

.min-height-128 {
  min-height: 128px;
}

.min-height-129 {
  min-height: 129px;
}

.min-height-130 {
  min-height: 130px;
}

.min-height-131 {
  min-height: 131px;
}

.min-height-132 {
  min-height: 132px;
}

.min-height-133 {
  min-height: 133px;
}

.min-height-134 {
  min-height: 134px;
}

.min-height-135 {
  min-height: 135px;
}

.min-height-136 {
  min-height: 136px;
}

.min-height-137 {
  min-height: 137px;
}

.min-height-138 {
  min-height: 138px;
}

.min-height-139 {
  min-height: 139px;
}

.min-height-140 {
  min-height: 140px;
}

.min-height-141 {
  min-height: 141px;
}

.min-height-142 {
  min-height: 142px;
}

.min-height-143 {
  min-height: 143px;
}

.min-height-144 {
  min-height: 144px;
}

.min-height-145 {
  min-height: 145px;
}

.min-height-146 {
  min-height: 146px;
}

.min-height-147 {
  min-height: 147px;
}

.min-height-148 {
  min-height: 148px;
}

.min-height-149 {
  min-height: 149px;
}

.min-height-150 {
  min-height: 150px;
}

.min-height-151 {
  min-height: 151px;
}

.min-height-152 {
  min-height: 152px;
}

.min-height-153 {
  min-height: 153px;
}

.min-height-154 {
  min-height: 154px;
}

.min-height-155 {
  min-height: 155px;
}

.min-height-156 {
  min-height: 156px;
}

.min-height-157 {
  min-height: 157px;
}

.min-height-158 {
  min-height: 158px;
}

.min-height-159 {
  min-height: 159px;
}

.min-height-160 {
  min-height: 160px;
}

.min-height-161 {
  min-height: 161px;
}

.min-height-162 {
  min-height: 162px;
}

.min-height-163 {
  min-height: 163px;
}

.min-height-164 {
  min-height: 164px;
}

.min-height-165 {
  min-height: 165px;
}

.min-height-166 {
  min-height: 166px;
}

.min-height-167 {
  min-height: 167px;
}

.min-height-168 {
  min-height: 168px;
}

.min-height-169 {
  min-height: 169px;
}

.min-height-170 {
  min-height: 170px;
}

.min-height-171 {
  min-height: 171px;
}

.min-height-172 {
  min-height: 172px;
}

.min-height-173 {
  min-height: 173px;
}

.min-height-174 {
  min-height: 174px;
}

.min-height-175 {
  min-height: 175px;
}

.min-height-176 {
  min-height: 176px;
}

.min-height-177 {
  min-height: 177px;
}

.min-height-178 {
  min-height: 178px;
}

.min-height-179 {
  min-height: 179px;
}

.min-height-180 {
  min-height: 180px;
}

.min-height-181 {
  min-height: 181px;
}

.min-height-182 {
  min-height: 182px;
}

.min-height-183 {
  min-height: 183px;
}

.min-height-184 {
  min-height: 184px;
}

.min-height-185 {
  min-height: 185px;
}

.min-height-186 {
  min-height: 186px;
}

.min-height-187 {
  min-height: 187px;
}

.min-height-188 {
  min-height: 188px;
}

.min-height-189 {
  min-height: 189px;
}

.min-height-190 {
  min-height: 190px;
}

.min-height-191 {
  min-height: 191px;
}

.min-height-192 {
  min-height: 192px;
}

.min-height-193 {
  min-height: 193px;
}

.min-height-194 {
  min-height: 194px;
}

.min-height-195 {
  min-height: 195px;
}

.min-height-196 {
  min-height: 196px;
}

.min-height-197 {
  min-height: 197px;
}

.min-height-198 {
  min-height: 198px;
}

.min-height-199 {
  min-height: 199px;
}

.min-height-200 {
  min-height: 200px;
}

.min-height-201 {
  min-height: 201px;
}

.min-height-202 {
  min-height: 202px;
}

.min-height-203 {
  min-height: 203px;
}

.min-height-204 {
  min-height: 204px;
}

.min-height-205 {
  min-height: 205px;
}

.min-height-206 {
  min-height: 206px;
}

.min-height-207 {
  min-height: 207px;
}

.min-height-208 {
  min-height: 208px;
}

.min-height-209 {
  min-height: 209px;
}

.min-height-210 {
  min-height: 210px;
}

.min-height-211 {
  min-height: 211px;
}

.min-height-212 {
  min-height: 212px;
}

.min-height-213 {
  min-height: 213px;
}

.min-height-214 {
  min-height: 214px;
}

.min-height-215 {
  min-height: 215px;
}

.min-height-216 {
  min-height: 216px;
}

.min-height-217 {
  min-height: 217px;
}

.min-height-218 {
  min-height: 218px;
}

.min-height-219 {
  min-height: 219px;
}

.min-height-220 {
  min-height: 220px;
}

.min-height-221 {
  min-height: 221px;
}

.min-height-222 {
  min-height: 222px;
}

.min-height-223 {
  min-height: 223px;
}

.min-height-224 {
  min-height: 224px;
}

.min-height-225 {
  min-height: 225px;
}

.min-height-226 {
  min-height: 226px;
}

.min-height-227 {
  min-height: 227px;
}

.min-height-228 {
  min-height: 228px;
}

.min-height-229 {
  min-height: 229px;
}

.min-height-230 {
  min-height: 230px;
}

.min-height-231 {
  min-height: 231px;
}

.min-height-232 {
  min-height: 232px;
}

.min-height-233 {
  min-height: 233px;
}

.min-height-234 {
  min-height: 234px;
}

.min-height-235 {
  min-height: 235px;
}

.min-height-236 {
  min-height: 236px;
}

.min-height-237 {
  min-height: 237px;
}

.min-height-238 {
  min-height: 238px;
}

.min-height-239 {
  min-height: 239px;
}

.min-height-240 {
  min-height: 240px;
}

.min-height-241 {
  min-height: 241px;
}

.min-height-242 {
  min-height: 242px;
}

.min-height-243 {
  min-height: 243px;
}

.min-height-244 {
  min-height: 244px;
}

.min-height-245 {
  min-height: 245px;
}

.min-height-246 {
  min-height: 246px;
}

.min-height-247 {
  min-height: 247px;
}

.min-height-248 {
  min-height: 248px;
}

.min-height-249 {
  min-height: 249px;
}

.min-height-250 {
  min-height: 250px;
}

.min-height-251 {
  min-height: 251px;
}

.min-height-252 {
  min-height: 252px;
}

.min-height-253 {
  min-height: 253px;
}

.min-height-254 {
  min-height: 254px;
}

.min-height-255 {
  min-height: 255px;
}

.min-height-256 {
  min-height: 256px;
}

.min-height-257 {
  min-height: 257px;
}

.min-height-258 {
  min-height: 258px;
}

.min-height-259 {
  min-height: 259px;
}

.min-height-260 {
  min-height: 260px;
}

.min-height-261 {
  min-height: 261px;
}

.min-height-262 {
  min-height: 262px;
}

.min-height-263 {
  min-height: 263px;
}

.min-height-264 {
  min-height: 264px;
}

.min-height-265 {
  min-height: 265px;
}

.min-height-266 {
  min-height: 266px;
}

.min-height-267 {
  min-height: 267px;
}

.min-height-268 {
  min-height: 268px;
}

.min-height-269 {
  min-height: 269px;
}

.min-height-270 {
  min-height: 270px;
}

.min-height-271 {
  min-height: 271px;
}

.min-height-272 {
  min-height: 272px;
}

.min-height-273 {
  min-height: 273px;
}

.min-height-274 {
  min-height: 274px;
}

.min-height-275 {
  min-height: 275px;
}

.min-height-276 {
  min-height: 276px;
}

.min-height-277 {
  min-height: 277px;
}

.min-height-278 {
  min-height: 278px;
}

.min-height-279 {
  min-height: 279px;
}

.min-height-280 {
  min-height: 280px;
}

.min-height-281 {
  min-height: 281px;
}

.min-height-282 {
  min-height: 282px;
}

.min-height-283 {
  min-height: 283px;
}

.min-height-284 {
  min-height: 284px;
}

.min-height-285 {
  min-height: 285px;
}

.min-height-286 {
  min-height: 286px;
}

.min-height-287 {
  min-height: 287px;
}

.min-height-288 {
  min-height: 288px;
}

.min-height-289 {
  min-height: 289px;
}

.min-height-290 {
  min-height: 290px;
}

.min-height-291 {
  min-height: 291px;
}

.min-height-292 {
  min-height: 292px;
}

.min-height-293 {
  min-height: 293px;
}

.min-height-294 {
  min-height: 294px;
}

.min-height-295 {
  min-height: 295px;
}

.min-height-296 {
  min-height: 296px;
}

.min-height-297 {
  min-height: 297px;
}

.min-height-298 {
  min-height: 298px;
}

.min-height-299 {
  min-height: 299px;
}

.min-height-300 {
  min-height: 300px;
}

.min-height-301 {
  min-height: 301px;
}

.min-height-302 {
  min-height: 302px;
}

.min-height-303 {
  min-height: 303px;
}

.min-height-304 {
  min-height: 304px;
}

.min-height-305 {
  min-height: 305px;
}

.min-height-306 {
  min-height: 306px;
}

.min-height-307 {
  min-height: 307px;
}

.min-height-308 {
  min-height: 308px;
}

.min-height-309 {
  min-height: 309px;
}

.min-height-310 {
  min-height: 310px;
}

.min-height-311 {
  min-height: 311px;
}

.min-height-312 {
  min-height: 312px;
}

.min-height-313 {
  min-height: 313px;
}

.min-height-314 {
  min-height: 314px;
}

.min-height-315 {
  min-height: 315px;
}

.min-height-316 {
  min-height: 316px;
}

.min-height-317 {
  min-height: 317px;
}

.min-height-318 {
  min-height: 318px;
}

.min-height-319 {
  min-height: 319px;
}

.min-height-320 {
  min-height: 320px;
}

.min-height-321 {
  min-height: 321px;
}

.min-height-322 {
  min-height: 322px;
}

.min-height-323 {
  min-height: 323px;
}

.min-height-324 {
  min-height: 324px;
}

.min-height-325 {
  min-height: 325px;
}

.min-height-326 {
  min-height: 326px;
}

.min-height-327 {
  min-height: 327px;
}

.min-height-328 {
  min-height: 328px;
}

.min-height-329 {
  min-height: 329px;
}

.min-height-330 {
  min-height: 330px;
}

.min-height-331 {
  min-height: 331px;
}

.min-height-332 {
  min-height: 332px;
}

.min-height-333 {
  min-height: 333px;
}

.min-height-334 {
  min-height: 334px;
}

.min-height-335 {
  min-height: 335px;
}

.min-height-336 {
  min-height: 336px;
}

.min-height-337 {
  min-height: 337px;
}

.min-height-338 {
  min-height: 338px;
}

.min-height-339 {
  min-height: 339px;
}

.min-height-340 {
  min-height: 340px;
}

.min-height-341 {
  min-height: 341px;
}

.min-height-342 {
  min-height: 342px;
}

.min-height-343 {
  min-height: 343px;
}

.min-height-344 {
  min-height: 344px;
}

.min-height-345 {
  min-height: 345px;
}

.min-height-346 {
  min-height: 346px;
}

.min-height-347 {
  min-height: 347px;
}

.min-height-348 {
  min-height: 348px;
}

.min-height-349 {
  min-height: 349px;
}

.min-height-350 {
  min-height: 350px;
}

.min-height-351 {
  min-height: 351px;
}

.min-height-352 {
  min-height: 352px;
}

.min-height-353 {
  min-height: 353px;
}

.min-height-354 {
  min-height: 354px;
}

.min-height-355 {
  min-height: 355px;
}

.min-height-356 {
  min-height: 356px;
}

.min-height-357 {
  min-height: 357px;
}

.min-height-358 {
  min-height: 358px;
}

.min-height-359 {
  min-height: 359px;
}

.min-height-360 {
  min-height: 360px;
}

.min-height-361 {
  min-height: 361px;
}

.min-height-362 {
  min-height: 362px;
}

.min-height-363 {
  min-height: 363px;
}

.min-height-364 {
  min-height: 364px;
}

.min-height-365 {
  min-height: 365px;
}

.min-height-366 {
  min-height: 366px;
}

.min-height-367 {
  min-height: 367px;
}

.min-height-368 {
  min-height: 368px;
}

.min-height-369 {
  min-height: 369px;
}

.min-height-370 {
  min-height: 370px;
}

.min-height-371 {
  min-height: 371px;
}

.min-height-372 {
  min-height: 372px;
}

.min-height-373 {
  min-height: 373px;
}

.min-height-374 {
  min-height: 374px;
}

.min-height-375 {
  min-height: 375px;
}

.min-height-376 {
  min-height: 376px;
}

.min-height-377 {
  min-height: 377px;
}

.min-height-378 {
  min-height: 378px;
}

.min-height-379 {
  min-height: 379px;
}

.min-height-380 {
  min-height: 380px;
}

.min-height-381 {
  min-height: 381px;
}

.min-height-382 {
  min-height: 382px;
}

.min-height-383 {
  min-height: 383px;
}

.min-height-384 {
  min-height: 384px;
}

.min-height-385 {
  min-height: 385px;
}

.min-height-386 {
  min-height: 386px;
}

.min-height-387 {
  min-height: 387px;
}

.min-height-388 {
  min-height: 388px;
}

.min-height-389 {
  min-height: 389px;
}

.min-height-390 {
  min-height: 390px;
}

.min-height-391 {
  min-height: 391px;
}

.min-height-392 {
  min-height: 392px;
}

.min-height-393 {
  min-height: 393px;
}

.min-height-394 {
  min-height: 394px;
}

.min-height-395 {
  min-height: 395px;
}

.min-height-396 {
  min-height: 396px;
}

.min-height-397 {
  min-height: 397px;
}

.min-height-398 {
  min-height: 398px;
}

.min-height-399 {
  min-height: 399px;
}

.min-height-400 {
  min-height: 400px;
}

.min-height-401 {
  min-height: 401px;
}

.min-height-402 {
  min-height: 402px;
}

.min-height-403 {
  min-height: 403px;
}

.min-height-404 {
  min-height: 404px;
}

.min-height-405 {
  min-height: 405px;
}

.min-height-406 {
  min-height: 406px;
}

.min-height-407 {
  min-height: 407px;
}

.min-height-408 {
  min-height: 408px;
}

.min-height-409 {
  min-height: 409px;
}

.min-height-410 {
  min-height: 410px;
}

.min-height-411 {
  min-height: 411px;
}

.min-height-412 {
  min-height: 412px;
}

.min-height-413 {
  min-height: 413px;
}

.min-height-414 {
  min-height: 414px;
}

.min-height-415 {
  min-height: 415px;
}

.min-height-416 {
  min-height: 416px;
}

.min-height-417 {
  min-height: 417px;
}

.min-height-418 {
  min-height: 418px;
}

.min-height-419 {
  min-height: 419px;
}

.min-height-420 {
  min-height: 420px;
}

.min-height-421 {
  min-height: 421px;
}

.min-height-422 {
  min-height: 422px;
}

.min-height-423 {
  min-height: 423px;
}

.min-height-424 {
  min-height: 424px;
}

.min-height-425 {
  min-height: 425px;
}

.min-height-426 {
  min-height: 426px;
}

.min-height-427 {
  min-height: 427px;
}

.min-height-428 {
  min-height: 428px;
}

.min-height-429 {
  min-height: 429px;
}

.min-height-430 {
  min-height: 430px;
}

.min-height-431 {
  min-height: 431px;
}

.min-height-432 {
  min-height: 432px;
}

.min-height-433 {
  min-height: 433px;
}

.min-height-434 {
  min-height: 434px;
}

.min-height-435 {
  min-height: 435px;
}

.min-height-436 {
  min-height: 436px;
}

.min-height-437 {
  min-height: 437px;
}

.min-height-438 {
  min-height: 438px;
}

.min-height-439 {
  min-height: 439px;
}

.min-height-440 {
  min-height: 440px;
}

.min-height-441 {
  min-height: 441px;
}

.min-height-442 {
  min-height: 442px;
}

.min-height-443 {
  min-height: 443px;
}

.min-height-444 {
  min-height: 444px;
}

.min-height-445 {
  min-height: 445px;
}

.min-height-446 {
  min-height: 446px;
}

.min-height-447 {
  min-height: 447px;
}

.min-height-448 {
  min-height: 448px;
}

.min-height-449 {
  min-height: 449px;
}

.min-height-450 {
  min-height: 450px;
}

.min-height-451 {
  min-height: 451px;
}

.min-height-452 {
  min-height: 452px;
}

.min-height-453 {
  min-height: 453px;
}

.min-height-454 {
  min-height: 454px;
}

.min-height-455 {
  min-height: 455px;
}

.min-height-456 {
  min-height: 456px;
}

.min-height-457 {
  min-height: 457px;
}

.min-height-458 {
  min-height: 458px;
}

.min-height-459 {
  min-height: 459px;
}

.min-height-460 {
  min-height: 460px;
}

.min-height-461 {
  min-height: 461px;
}

.min-height-462 {
  min-height: 462px;
}

.min-height-463 {
  min-height: 463px;
}

.min-height-464 {
  min-height: 464px;
}

.min-height-465 {
  min-height: 465px;
}

.min-height-466 {
  min-height: 466px;
}

.min-height-467 {
  min-height: 467px;
}

.min-height-468 {
  min-height: 468px;
}

.min-height-469 {
  min-height: 469px;
}

.min-height-470 {
  min-height: 470px;
}

.min-height-471 {
  min-height: 471px;
}

.min-height-472 {
  min-height: 472px;
}

.min-height-473 {
  min-height: 473px;
}

.min-height-474 {
  min-height: 474px;
}

.min-height-475 {
  min-height: 475px;
}

.min-height-476 {
  min-height: 476px;
}

.min-height-477 {
  min-height: 477px;
}

.min-height-478 {
  min-height: 478px;
}

.min-height-479 {
  min-height: 479px;
}

.min-height-480 {
  min-height: 480px;
}

.min-height-481 {
  min-height: 481px;
}

.min-height-482 {
  min-height: 482px;
}

.min-height-483 {
  min-height: 483px;
}

.min-height-484 {
  min-height: 484px;
}

.min-height-485 {
  min-height: 485px;
}

.min-height-486 {
  min-height: 486px;
}

.min-height-487 {
  min-height: 487px;
}

.min-height-488 {
  min-height: 488px;
}

.min-height-489 {
  min-height: 489px;
}

.min-height-490 {
  min-height: 490px;
}

.min-height-491 {
  min-height: 491px;
}

.min-height-492 {
  min-height: 492px;
}

.min-height-493 {
  min-height: 493px;
}

.min-height-494 {
  min-height: 494px;
}

.min-height-495 {
  min-height: 495px;
}

.min-height-496 {
  min-height: 496px;
}

.min-height-497 {
  min-height: 497px;
}

.min-height-498 {
  min-height: 498px;
}

.min-height-499 {
  min-height: 499px;
}

.min-height-500 {
  min-height: 500px;
}

/*class for height dynamic*/
.height-1 {
  height: 1px;
}

.height-2 {
  height: 2px;
}

.height-3 {
  height: 3px;
}

.height-4 {
  height: 4px;
}

.height-5 {
  height: 5px;
}

.height-6 {
  height: 6px;
}

.height-7 {
  height: 7px;
}

.height-8 {
  height: 8px;
}

.height-9 {
  height: 9px;
}

.height-10 {
  height: 10px;
}

.height-11 {
  height: 11px;
}

.height-12 {
  height: 12px;
}

.height-13 {
  height: 13px;
}

.height-14 {
  height: 14px;
}

.height-15 {
  height: 15px;
}

.height-16 {
  height: 16px;
}

.height-17 {
  height: 17px;
}

.height-18 {
  height: 18px;
}

.height-19 {
  height: 19px;
}

.height-20 {
  height: 20px;
}

.height-21 {
  height: 21px;
}

.height-22 {
  height: 22px;
}

.height-23 {
  height: 23px;
}

.height-24 {
  height: 24px;
}

.height-25 {
  height: 25px;
}

.height-26 {
  height: 26px;
}

.height-27 {
  height: 27px;
}

.height-28 {
  height: 28px;
}

.height-29 {
  height: 29px;
}

.height-30 {
  height: 30px;
}

.height-31 {
  height: 31px;
}

.height-32 {
  height: 32px;
}

.height-33 {
  height: 33px;
}

.height-34 {
  height: 34px;
}

.height-35 {
  height: 35px;
}

.height-36 {
  height: 36px;
}

.height-37 {
  height: 37px;
}

.height-38 {
  height: 38px;
}

.height-39 {
  height: 39px;
}

.height-40 {
  height: 40px;
}

.height-41 {
  height: 41px;
}

.height-42 {
  height: 42px;
}

.height-43 {
  height: 43px;
}

.height-44 {
  height: 44px;
}

.height-45 {
  height: 45px;
}

.height-46 {
  height: 46px;
}

.height-47 {
  height: 47px;
}

.height-48 {
  height: 48px;
}

.height-49 {
  height: 49px;
}

.height-50 {
  height: 50px;
}

.height-51 {
  height: 51px;
}

.height-52 {
  height: 52px;
}

.height-53 {
  height: 53px;
}

.height-54 {
  height: 54px;
}

.height-55 {
  height: 55px;
}

.height-56 {
  height: 56px;
}

.height-57 {
  height: 57px;
}

.height-58 {
  height: 58px;
}

.height-59 {
  height: 59px;
}

.height-60 {
  height: 60px;
}

.height-61 {
  height: 61px;
}

.height-62 {
  height: 62px;
}

.height-63 {
  height: 63px;
}

.height-64 {
  height: 64px;
}

.height-65 {
  height: 65px;
}

.height-66 {
  height: 66px;
}

.height-67 {
  height: 67px;
}

.height-68 {
  height: 68px;
}

.height-69 {
  height: 69px;
}

.height-70 {
  height: 70px;
}

.height-71 {
  height: 71px;
}

.height-72 {
  height: 72px;
}

.height-73 {
  height: 73px;
}

.height-74 {
  height: 74px;
}

.height-75 {
  height: 75px;
}

.height-76 {
  height: 76px;
}

.height-77 {
  height: 77px;
}

.height-78 {
  height: 78px;
}

.height-79 {
  height: 79px;
}

.height-80 {
  height: 80px;
}

.height-81 {
  height: 81px;
}

.height-82 {
  height: 82px;
}

.height-83 {
  height: 83px;
}

.height-84 {
  height: 84px;
}

.height-85 {
  height: 85px;
}

.height-86 {
  height: 86px;
}

.height-87 {
  height: 87px;
}

.height-88 {
  height: 88px;
}

.height-89 {
  height: 89px;
}

.height-90 {
  height: 90px;
}

.height-91 {
  height: 91px;
}

.height-92 {
  height: 92px;
}

.height-93 {
  height: 93px;
}

.height-94 {
  height: 94px;
}

.height-95 {
  height: 95px;
}

.height-96 {
  height: 96px;
}

.height-97 {
  height: 97px;
}

.height-98 {
  height: 98px;
}

.height-99 {
  height: 99px;
}

.height-100 {
  height: 100px;
}

.height-101 {
  height: 101px;
}

.height-102 {
  height: 102px;
}

.height-103 {
  height: 103px;
}

.height-104 {
  height: 104px;
}

.height-105 {
  height: 105px;
}

.height-106 {
  height: 106px;
}

.height-107 {
  height: 107px;
}

.height-108 {
  height: 108px;
}

.height-109 {
  height: 109px;
}

.height-110 {
  height: 110px;
}

.height-111 {
  height: 111px;
}

.height-112 {
  height: 112px;
}

.height-113 {
  height: 113px;
}

.height-114 {
  height: 114px;
}

.height-115 {
  height: 115px;
}

.height-116 {
  height: 116px;
}

.height-117 {
  height: 117px;
}

.height-118 {
  height: 118px;
}

.height-119 {
  height: 119px;
}

.height-120 {
  height: 120px;
}

.height-121 {
  height: 121px;
}

.height-122 {
  height: 122px;
}

.height-123 {
  height: 123px;
}

.height-124 {
  height: 124px;
}

.height-125 {
  height: 125px;
}

.height-126 {
  height: 126px;
}

.height-127 {
  height: 127px;
}

.height-128 {
  height: 128px;
}

.height-129 {
  height: 129px;
}

.height-130 {
  height: 130px;
}

.height-131 {
  height: 131px;
}

.height-132 {
  height: 132px;
}

.height-133 {
  height: 133px;
}

.height-134 {
  height: 134px;
}

.height-135 {
  height: 135px;
}

.height-136 {
  height: 136px;
}

.height-137 {
  height: 137px;
}

.height-138 {
  height: 138px;
}

.height-139 {
  height: 139px;
}

.height-140 {
  height: 140px;
}

.height-141 {
  height: 141px;
}

.height-142 {
  height: 142px;
}

.height-143 {
  height: 143px;
}

.height-144 {
  height: 144px;
}

.height-145 {
  height: 145px;
}

.height-146 {
  height: 146px;
}

.height-147 {
  height: 147px;
}

.height-148 {
  height: 148px;
}

.height-149 {
  height: 149px;
}

.height-150 {
  height: 150px;
}

.height-151 {
  height: 151px;
}

.height-152 {
  height: 152px;
}

.height-153 {
  height: 153px;
}

.height-154 {
  height: 154px;
}

.height-155 {
  height: 155px;
}

.height-156 {
  height: 156px;
}

.height-157 {
  height: 157px;
}

.height-158 {
  height: 158px;
}

.height-159 {
  height: 159px;
}

.height-160 {
  height: 160px;
}

.height-161 {
  height: 161px;
}

.height-162 {
  height: 162px;
}

.height-163 {
  height: 163px;
}

.height-164 {
  height: 164px;
}

.height-165 {
  height: 165px;
}

.height-166 {
  height: 166px;
}

.height-167 {
  height: 167px;
}

.height-168 {
  height: 168px;
}

.height-169 {
  height: 169px;
}

.height-170 {
  height: 170px;
}

.height-171 {
  height: 171px;
}

.height-172 {
  height: 172px;
}

.height-173 {
  height: 173px;
}

.height-174 {
  height: 174px;
}

.height-175 {
  height: 175px;
}

.height-176 {
  height: 176px;
}

.height-177 {
  height: 177px;
}

.height-178 {
  height: 178px;
}

.height-179 {
  height: 179px;
}

.height-180 {
  height: 180px;
}

.height-181 {
  height: 181px;
}

.height-182 {
  height: 182px;
}

.height-183 {
  height: 183px;
}

.height-184 {
  height: 184px;
}

.height-185 {
  height: 185px;
}

.height-186 {
  height: 186px;
}

.height-187 {
  height: 187px;
}

.height-188 {
  height: 188px;
}

.height-189 {
  height: 189px;
}

.height-190 {
  height: 190px;
}

.height-191 {
  height: 191px;
}

.height-192 {
  height: 192px;
}

.height-193 {
  height: 193px;
}

.height-194 {
  height: 194px;
}

.height-195 {
  height: 195px;
}

.height-196 {
  height: 196px;
}

.height-197 {
  height: 197px;
}

.height-198 {
  height: 198px;
}

.height-199 {
  height: 199px;
}

.height-200 {
  height: 200px;
}

.height-201 {
  height: 201px;
}

.height-202 {
  height: 202px;
}

.height-203 {
  height: 203px;
}

.height-204 {
  height: 204px;
}

.height-205 {
  height: 205px;
}

.height-206 {
  height: 206px;
}

.height-207 {
  height: 207px;
}

.height-208 {
  height: 208px;
}

.height-209 {
  height: 209px;
}

.height-210 {
  height: 210px;
}

.height-211 {
  height: 211px;
}

.height-212 {
  height: 212px;
}

.height-213 {
  height: 213px;
}

.height-214 {
  height: 214px;
}

.height-215 {
  height: 215px;
}

.height-216 {
  height: 216px;
}

.height-217 {
  height: 217px;
}

.height-218 {
  height: 218px;
}

.height-219 {
  height: 219px;
}

.height-220 {
  height: 220px;
}

.height-221 {
  height: 221px;
}

.height-222 {
  height: 222px;
}

.height-223 {
  height: 223px;
}

.height-224 {
  height: 224px;
}

.height-225 {
  height: 225px;
}

.height-226 {
  height: 226px;
}

.height-227 {
  height: 227px;
}

.height-228 {
  height: 228px;
}

.height-229 {
  height: 229px;
}

.height-230 {
  height: 230px;
}

.height-231 {
  height: 231px;
}

.height-232 {
  height: 232px;
}

.height-233 {
  height: 233px;
}

.height-234 {
  height: 234px;
}

.height-235 {
  height: 235px;
}

.height-236 {
  height: 236px;
}

.height-237 {
  height: 237px;
}

.height-238 {
  height: 238px;
}

.height-239 {
  height: 239px;
}

.height-240 {
  height: 240px;
}

.height-241 {
  height: 241px;
}

.height-242 {
  height: 242px;
}

.height-243 {
  height: 243px;
}

.height-244 {
  height: 244px;
}

.height-245 {
  height: 245px;
}

.height-246 {
  height: 246px;
}

.height-247 {
  height: 247px;
}

.height-248 {
  height: 248px;
}

.height-249 {
  height: 249px;
}

.height-250 {
  height: 250px;
}

.height-251 {
  height: 251px;
}

.height-252 {
  height: 252px;
}

.height-253 {
  height: 253px;
}

.height-254 {
  height: 254px;
}

.height-255 {
  height: 255px;
}

.height-256 {
  height: 256px;
}

.height-257 {
  height: 257px;
}

.height-258 {
  height: 258px;
}

.height-259 {
  height: 259px;
}

.height-260 {
  height: 260px;
}

.height-261 {
  height: 261px;
}

.height-262 {
  height: 262px;
}

.height-263 {
  height: 263px;
}

.height-264 {
  height: 264px;
}

.height-265 {
  height: 265px;
}

.height-266 {
  height: 266px;
}

.height-267 {
  height: 267px;
}

.height-268 {
  height: 268px;
}

.height-269 {
  height: 269px;
}

.height-270 {
  height: 270px;
}

.height-271 {
  height: 271px;
}

.height-272 {
  height: 272px;
}

.height-273 {
  height: 273px;
}

.height-274 {
  height: 274px;
}

.height-275 {
  height: 275px;
}

.height-276 {
  height: 276px;
}

.height-277 {
  height: 277px;
}

.height-278 {
  height: 278px;
}

.height-279 {
  height: 279px;
}

.height-280 {
  height: 280px;
}

.height-281 {
  height: 281px;
}

.height-282 {
  height: 282px;
}

.height-283 {
  height: 283px;
}

.height-284 {
  height: 284px;
}

.height-285 {
  height: 285px;
}

.height-286 {
  height: 286px;
}

.height-287 {
  height: 287px;
}

.height-288 {
  height: 288px;
}

.height-289 {
  height: 289px;
}

.height-290 {
  height: 290px;
}

.height-291 {
  height: 291px;
}

.height-292 {
  height: 292px;
}

.height-293 {
  height: 293px;
}

.height-294 {
  height: 294px;
}

.height-295 {
  height: 295px;
}

.height-296 {
  height: 296px;
}

.height-297 {
  height: 297px;
}

.height-298 {
  height: 298px;
}

.height-299 {
  height: 299px;
}

.height-300 {
  height: 300px;
}

.height-301 {
  height: 301px;
}

.height-302 {
  height: 302px;
}

.height-303 {
  height: 303px;
}

.height-304 {
  height: 304px;
}

.height-305 {
  height: 305px;
}

.height-306 {
  height: 306px;
}

.height-307 {
  height: 307px;
}

.height-308 {
  height: 308px;
}

.height-309 {
  height: 309px;
}

.height-310 {
  height: 310px;
}

.height-311 {
  height: 311px;
}

.height-312 {
  height: 312px;
}

.height-313 {
  height: 313px;
}

.height-314 {
  height: 314px;
}

.height-315 {
  height: 315px;
}

.height-316 {
  height: 316px;
}

.height-317 {
  height: 317px;
}

.height-318 {
  height: 318px;
}

.height-319 {
  height: 319px;
}

.height-320 {
  height: 320px;
}

.height-321 {
  height: 321px;
}

.height-322 {
  height: 322px;
}

.height-323 {
  height: 323px;
}

.height-324 {
  height: 324px;
}

.height-325 {
  height: 325px;
}

.height-326 {
  height: 326px;
}

.height-327 {
  height: 327px;
}

.height-328 {
  height: 328px;
}

.height-329 {
  height: 329px;
}

.height-330 {
  height: 330px;
}

.height-331 {
  height: 331px;
}

.height-332 {
  height: 332px;
}

.height-333 {
  height: 333px;
}

.height-334 {
  height: 334px;
}

.height-335 {
  height: 335px;
}

.height-336 {
  height: 336px;
}

.height-337 {
  height: 337px;
}

.height-338 {
  height: 338px;
}

.height-339 {
  height: 339px;
}

.height-340 {
  height: 340px;
}

.height-341 {
  height: 341px;
}

.height-342 {
  height: 342px;
}

.height-343 {
  height: 343px;
}

.height-344 {
  height: 344px;
}

.height-345 {
  height: 345px;
}

.height-346 {
  height: 346px;
}

.height-347 {
  height: 347px;
}

.height-348 {
  height: 348px;
}

.height-349 {
  height: 349px;
}

.height-350 {
  height: 350px;
}

.height-351 {
  height: 351px;
}

.height-352 {
  height: 352px;
}

.height-353 {
  height: 353px;
}

.height-354 {
  height: 354px;
}

.height-355 {
  height: 355px;
}

.height-356 {
  height: 356px;
}

.height-357 {
  height: 357px;
}

.height-358 {
  height: 358px;
}

.height-359 {
  height: 359px;
}

.height-360 {
  height: 360px;
}

.height-361 {
  height: 361px;
}

.height-362 {
  height: 362px;
}

.height-363 {
  height: 363px;
}

.height-364 {
  height: 364px;
}

.height-365 {
  height: 365px;
}

.height-366 {
  height: 366px;
}

.height-367 {
  height: 367px;
}

.height-368 {
  height: 368px;
}

.height-369 {
  height: 369px;
}

.height-370 {
  height: 370px;
}

.height-371 {
  height: 371px;
}

.height-372 {
  height: 372px;
}

.height-373 {
  height: 373px;
}

.height-374 {
  height: 374px;
}

.height-375 {
  height: 375px;
}

.height-376 {
  height: 376px;
}

.height-377 {
  height: 377px;
}

.height-378 {
  height: 378px;
}

.height-379 {
  height: 379px;
}

.height-380 {
  height: 380px;
}

.height-381 {
  height: 381px;
}

.height-382 {
  height: 382px;
}

.height-383 {
  height: 383px;
}

.height-384 {
  height: 384px;
}

.height-385 {
  height: 385px;
}

.height-386 {
  height: 386px;
}

.height-387 {
  height: 387px;
}

.height-388 {
  height: 388px;
}

.height-389 {
  height: 389px;
}

.height-390 {
  height: 390px;
}

.height-391 {
  height: 391px;
}

.height-392 {
  height: 392px;
}

.height-393 {
  height: 393px;
}

.height-394 {
  height: 394px;
}

.height-395 {
  height: 395px;
}

.height-396 {
  height: 396px;
}

.height-397 {
  height: 397px;
}

.height-398 {
  height: 398px;
}

.height-399 {
  height: 399px;
}

.height-400 {
  height: 400px;
}

.height-401 {
  height: 401px;
}

.height-402 {
  height: 402px;
}

.height-403 {
  height: 403px;
}

.height-404 {
  height: 404px;
}

.height-405 {
  height: 405px;
}

.height-406 {
  height: 406px;
}

.height-407 {
  height: 407px;
}

.height-408 {
  height: 408px;
}

.height-409 {
  height: 409px;
}

.height-410 {
  height: 410px;
}

.height-411 {
  height: 411px;
}

.height-412 {
  height: 412px;
}

.height-413 {
  height: 413px;
}

.height-414 {
  height: 414px;
}

.height-415 {
  height: 415px;
}

.height-416 {
  height: 416px;
}

.height-417 {
  height: 417px;
}

.height-418 {
  height: 418px;
}

.height-419 {
  height: 419px;
}

.height-420 {
  height: 420px;
}

.height-421 {
  height: 421px;
}

.height-422 {
  height: 422px;
}

.height-423 {
  height: 423px;
}

.height-424 {
  height: 424px;
}

.height-425 {
  height: 425px;
}

.height-426 {
  height: 426px;
}

.height-427 {
  height: 427px;
}

.height-428 {
  height: 428px;
}

.height-429 {
  height: 429px;
}

.height-430 {
  height: 430px;
}

.height-431 {
  height: 431px;
}

.height-432 {
  height: 432px;
}

.height-433 {
  height: 433px;
}

.height-434 {
  height: 434px;
}

.height-435 {
  height: 435px;
}

.height-436 {
  height: 436px;
}

.height-437 {
  height: 437px;
}

.height-438 {
  height: 438px;
}

.height-439 {
  height: 439px;
}

.height-440 {
  height: 440px;
}

.height-441 {
  height: 441px;
}

.height-442 {
  height: 442px;
}

.height-443 {
  height: 443px;
}

.height-444 {
  height: 444px;
}

.height-445 {
  height: 445px;
}

.height-446 {
  height: 446px;
}

.height-447 {
  height: 447px;
}

.height-448 {
  height: 448px;
}

.height-449 {
  height: 449px;
}

.height-450 {
  height: 450px;
}

.height-451 {
  height: 451px;
}

.height-452 {
  height: 452px;
}

.height-453 {
  height: 453px;
}

.height-454 {
  height: 454px;
}

.height-455 {
  height: 455px;
}

.height-456 {
  height: 456px;
}

.height-457 {
  height: 457px;
}

.height-458 {
  height: 458px;
}

.height-459 {
  height: 459px;
}

.height-460 {
  height: 460px;
}

.height-461 {
  height: 461px;
}

.height-462 {
  height: 462px;
}

.height-463 {
  height: 463px;
}

.height-464 {
  height: 464px;
}

.height-465 {
  height: 465px;
}

.height-466 {
  height: 466px;
}

.height-467 {
  height: 467px;
}

.height-468 {
  height: 468px;
}

.height-469 {
  height: 469px;
}

.height-470 {
  height: 470px;
}

.height-471 {
  height: 471px;
}

.height-472 {
  height: 472px;
}

.height-473 {
  height: 473px;
}

.height-474 {
  height: 474px;
}

.height-475 {
  height: 475px;
}

.height-476 {
  height: 476px;
}

.height-477 {
  height: 477px;
}

.height-478 {
  height: 478px;
}

.height-479 {
  height: 479px;
}

.height-480 {
  height: 480px;
}

.height-481 {
  height: 481px;
}

.height-482 {
  height: 482px;
}

.height-483 {
  height: 483px;
}

.height-484 {
  height: 484px;
}

.height-485 {
  height: 485px;
}

.height-486 {
  height: 486px;
}

.height-487 {
  height: 487px;
}

.height-488 {
  height: 488px;
}

.height-489 {
  height: 489px;
}

.height-490 {
  height: 490px;
}

.height-491 {
  height: 491px;
}

.height-492 {
  height: 492px;
}

.height-493 {
  height: 493px;
}

.height-494 {
  height: 494px;
}

.height-495 {
  height: 495px;
}

.height-496 {
  height: 496px;
}

.height-497 {
  height: 497px;
}

.height-498 {
  height: 498px;
}

.height-499 {
  height: 499px;
}

.height-500 {
  height: 500px;
}

/*class for dynamic min height in vh*/
.min-height-vh-1 {
  min-height: 1vh;
}

.min-height-vh-2 {
  min-height: 2vh;
}

.min-height-vh-3 {
  min-height: 3vh;
}

.min-height-vh-4 {
  min-height: 4vh;
}

.min-height-vh-5 {
  min-height: 5vh;
}

.min-height-vh-6 {
  min-height: 6vh;
}

.min-height-vh-7 {
  min-height: 7vh;
}

.min-height-vh-8 {
  min-height: 8vh;
}

.min-height-vh-9 {
  min-height: 9vh;
}

.min-height-vh-10 {
  min-height: 10vh;
}

.min-height-vh-11 {
  min-height: 11vh;
}

.min-height-vh-12 {
  min-height: 12vh;
}

.min-height-vh-13 {
  min-height: 13vh;
}

.min-height-vh-14 {
  min-height: 14vh;
}

.min-height-vh-15 {
  min-height: 15vh;
}

.min-height-vh-16 {
  min-height: 16vh;
}

.min-height-vh-17 {
  min-height: 17vh;
}

.min-height-vh-18 {
  min-height: 18vh;
}

.min-height-vh-19 {
  min-height: 19vh;
}

.min-height-vh-20 {
  min-height: 20vh;
}

.min-height-vh-21 {
  min-height: 21vh;
}

.min-height-vh-22 {
  min-height: 22vh;
}

.min-height-vh-23 {
  min-height: 23vh;
}

.min-height-vh-24 {
  min-height: 24vh;
}

.min-height-vh-25 {
  min-height: 25vh;
}

.min-height-vh-26 {
  min-height: 26vh;
}

.min-height-vh-27 {
  min-height: 27vh;
}

.min-height-vh-28 {
  min-height: 28vh;
}

.min-height-vh-29 {
  min-height: 29vh;
}

.min-height-vh-30 {
  min-height: 30vh;
}

.min-height-vh-31 {
  min-height: 31vh;
}

.min-height-vh-32 {
  min-height: 32vh;
}

.min-height-vh-33 {
  min-height: 33vh;
}

.min-height-vh-34 {
  min-height: 34vh;
}

.min-height-vh-35 {
  min-height: 35vh;
}

.min-height-vh-36 {
  min-height: 36vh;
}

.min-height-vh-37 {
  min-height: 37vh;
}

.min-height-vh-38 {
  min-height: 38vh;
}

.min-height-vh-39 {
  min-height: 39vh;
}

.min-height-vh-40 {
  min-height: 40vh;
}

.min-height-vh-41 {
  min-height: 41vh;
}

.min-height-vh-42 {
  min-height: 42vh;
}

.min-height-vh-43 {
  min-height: 43vh;
}

.min-height-vh-44 {
  min-height: 44vh;
}

.min-height-vh-45 {
  min-height: 45vh;
}

.min-height-vh-46 {
  min-height: 46vh;
}

.min-height-vh-47 {
  min-height: 47vh;
}

.min-height-vh-48 {
  min-height: 48vh;
}

.min-height-vh-49 {
  min-height: 49vh;
}

.min-height-vh-50 {
  min-height: 50vh;
}

.min-height-vh-51 {
  min-height: 51vh;
}

.min-height-vh-52 {
  min-height: 52vh;
}

.min-height-vh-53 {
  min-height: 53vh;
}

.min-height-vh-54 {
  min-height: 54vh;
}

.min-height-vh-55 {
  min-height: 55vh;
}

.min-height-vh-56 {
  min-height: 56vh;
}

.min-height-vh-57 {
  min-height: 57vh;
}

.min-height-vh-58 {
  min-height: 58vh;
}

.min-height-vh-59 {
  min-height: 59vh;
}

.min-height-vh-60 {
  min-height: 60vh;
}

.min-height-vh-61 {
  min-height: 61vh;
}

.min-height-vh-62 {
  min-height: 62vh;
}

.min-height-vh-63 {
  min-height: 63vh;
}

.min-height-vh-64 {
  min-height: 64vh;
}

.min-height-vh-65 {
  min-height: 65vh;
}

.min-height-vh-66 {
  min-height: 66vh;
}

.min-height-vh-67 {
  min-height: 67vh;
}

.min-height-vh-68 {
  min-height: 68vh;
}

.min-height-vh-69 {
  min-height: 69vh;
}

.min-height-vh-70 {
  min-height: 70vh;
}

.min-height-vh-71 {
  min-height: 71vh;
}

.min-height-vh-72 {
  min-height: 72vh;
}

.min-height-vh-73 {
  min-height: 73vh;
}

.min-height-vh-74 {
  min-height: 74vh;
}

.min-height-vh-75 {
  min-height: 75vh;
}

.min-height-vh-76 {
  min-height: 76vh;
}

.min-height-vh-77 {
  min-height: 77vh;
}

.min-height-vh-78 {
  min-height: 78vh;
}

.min-height-vh-79 {
  min-height: 79vh;
}

.min-height-vh-80 {
  min-height: 80vh;
}

.min-height-vh-81 {
  min-height: 81vh;
}

.min-height-vh-82 {
  min-height: 82vh;
}

.min-height-vh-83 {
  min-height: 83vh;
}

.min-height-vh-84 {
  min-height: 84vh;
}

.min-height-vh-85 {
  min-height: 85vh;
}

.min-height-vh-86 {
  min-height: 86vh;
}

.min-height-vh-87 {
  min-height: 87vh;
}

.min-height-vh-88 {
  min-height: 88vh;
}

.min-height-vh-89 {
  min-height: 89vh;
}

.min-height-vh-90 {
  min-height: 90vh;
}

.min-height-vh-91 {
  min-height: 91vh;
}

.min-height-vh-92 {
  min-height: 92vh;
}

.min-height-vh-93 {
  min-height: 93vh;
}

.min-height-vh-94 {
  min-height: 94vh;
}

.min-height-vh-95 {
  min-height: 95vh;
}

.min-height-vh-96 {
  min-height: 96vh;
}

.min-height-vh-97 {
  min-height: 97vh;
}

.min-height-vh-98 {
  min-height: 98vh;
}

.min-height-vh-99 {
  min-height: 99vh;
}

.min-height-vh-100 {
  min-height: 100vh;
}

/*class for padding top dynamic*/
.p-top-1 {
  padding-top: 1px !important;
}

.p-top-2 {
  padding-top: 2px !important;
}

.p-top-3 {
  padding-top: 3px !important;
}

.p-top-4 {
  padding-top: 4px !important;
}

.p-top-5 {
  padding-top: 5px !important;
}

.p-top-6 {
  padding-top: 6px !important;
}

.p-top-7 {
  padding-top: 7px !important;
}

.p-top-8 {
  padding-top: 8px !important;
}

.p-top-9 {
  padding-top: 9px !important;
}

.p-top-10 {
  padding-top: 10px !important;
}

.p-top-11 {
  padding-top: 11px !important;
}

.p-top-12 {
  padding-top: 12px !important;
}

.p-top-13 {
  padding-top: 13px !important;
}

.p-top-14 {
  padding-top: 14px !important;
}

.p-top-15 {
  padding-top: 15px !important;
}

.p-top-16 {
  padding-top: 16px !important;
}

.p-top-17 {
  padding-top: 17px !important;
}

.p-top-18 {
  padding-top: 18px !important;
}

.p-top-19 {
  padding-top: 19px !important;
}

.p-top-20 {
  padding-top: 20px !important;
}

/*class for padding left dynamic*/
.padding-left-1 {
  padding-left: 1px !important;
}

.padding-left-2 {
  padding-left: 2px !important;
}

.padding-left-3 {
  padding-left: 3px !important;
}

.padding-left-4 {
  padding-left: 4px !important;
}

.padding-left-5 {
  padding-left: 5px !important;
}

.padding-left-6 {
  padding-left: 6px !important;
}

.padding-left-7 {
  padding-left: 7px !important;
}

.padding-left-8 {
  padding-left: 8px !important;
}

.padding-left-9 {
  padding-left: 9px !important;
}

.padding-left-10 {
  padding-left: 10px !important;
}

.padding-left-11 {
  padding-left: 11px !important;
}

.padding-left-12 {
  padding-left: 12px !important;
}

.padding-left-13 {
  padding-left: 13px !important;
}

.padding-left-14 {
  padding-left: 14px !important;
}

.padding-left-15 {
  padding-left: 15px !important;
}

.padding-left-16 {
  padding-left: 16px !important;
}

.padding-left-17 {
  padding-left: 17px !important;
}

.padding-left-18 {
  padding-left: 18px !important;
}

.padding-left-19 {
  padding-left: 19px !important;
}

.padding-left-20 {
  padding-left: 20px !important;
}

.padding-left-21 {
  padding-left: 21px !important;
}

.padding-left-22 {
  padding-left: 22px !important;
}

.padding-left-23 {
  padding-left: 23px !important;
}

.padding-left-24 {
  padding-left: 24px !important;
}

.padding-left-25 {
  padding-left: 25px !important;
}

.padding-left-26 {
  padding-left: 26px !important;
}

.padding-left-27 {
  padding-left: 27px !important;
}

.padding-left-28 {
  padding-left: 28px !important;
}

.padding-left-29 {
  padding-left: 29px !important;
}

.padding-left-30 {
  padding-left: 30px !important;
}

.padding-left-31 {
  padding-left: 31px !important;
}

.padding-left-32 {
  padding-left: 32px !important;
}

.padding-left-33 {
  padding-left: 33px !important;
}

.padding-left-34 {
  padding-left: 34px !important;
}

.padding-left-35 {
  padding-left: 35px !important;
}

.padding-left-36 {
  padding-left: 36px !important;
}

.padding-left-37 {
  padding-left: 37px !important;
}

.padding-left-38 {
  padding-left: 38px !important;
}

.padding-left-39 {
  padding-left: 39px !important;
}

.padding-left-40 {
  padding-left: 40px !important;
}

/*class for padding right dynamic*/
.padding-right-1 {
  padding-right: 1px !important;
}

.padding-right-2 {
  padding-right: 2px !important;
}

.padding-right-3 {
  padding-right: 3px !important;
}

.padding-right-4 {
  padding-right: 4px !important;
}

.padding-right-5 {
  padding-right: 5px !important;
}

.padding-right-6 {
  padding-right: 6px !important;
}

.padding-right-7 {
  padding-right: 7px !important;
}

.padding-right-8 {
  padding-right: 8px !important;
}

.padding-right-9 {
  padding-right: 9px !important;
}

.padding-right-10 {
  padding-right: 10px !important;
}

.padding-right-11 {
  padding-right: 11px !important;
}

.padding-right-12 {
  padding-right: 12px !important;
}

.padding-right-13 {
  padding-right: 13px !important;
}

.padding-right-14 {
  padding-right: 14px !important;
}

.padding-right-15 {
  padding-right: 15px !important;
}

.padding-right-16 {
  padding-right: 16px !important;
}

.padding-right-17 {
  padding-right: 17px !important;
}

.padding-right-18 {
  padding-right: 18px !important;
}

.padding-right-19 {
  padding-right: 19px !important;
}

.padding-right-20 {
  padding-right: 20px !important;
}

.padding-right-21 {
  padding-right: 21px !important;
}

.padding-right-22 {
  padding-right: 22px !important;
}

.padding-right-23 {
  padding-right: 23px !important;
}

.padding-right-24 {
  padding-right: 24px !important;
}

.padding-right-25 {
  padding-right: 25px !important;
}

.padding-right-26 {
  padding-right: 26px !important;
}

.padding-right-27 {
  padding-right: 27px !important;
}

.padding-right-28 {
  padding-right: 28px !important;
}

.padding-right-29 {
  padding-right: 29px !important;
}

.padding-right-30 {
  padding-right: 30px !important;
}

.padding-right-31 {
  padding-right: 31px !important;
}

.padding-right-32 {
  padding-right: 32px !important;
}

.padding-right-33 {
  padding-right: 33px !important;
}

.padding-right-34 {
  padding-right: 34px !important;
}

.padding-right-35 {
  padding-right: 35px !important;
}

.padding-right-36 {
  padding-right: 36px !important;
}

.padding-right-37 {
  padding-right: 37px !important;
}

.padding-right-38 {
  padding-right: 38px !important;
}

.padding-right-39 {
  padding-right: 39px !important;
}

.padding-right-40 {
  padding-right: 40px !important;
}

/*class for margin top dynamic*/
.mtop-1 {
  margin-top: 1px !important;
}

.mtop-2 {
  margin-top: 2px !important;
}

.mtop-3 {
  margin-top: 3px !important;
}

.mtop-4 {
  margin-top: 4px !important;
}

.mtop-5 {
  margin-top: 5px !important;
}

.mtop-6 {
  margin-top: 6px !important;
}

.mtop-7 {
  margin-top: 7px !important;
}

.mtop-8 {
  margin-top: 8px !important;
}

.mtop-9 {
  margin-top: 9px !important;
}

.mtop-10 {
  margin-top: 10px !important;
}

.mtop-11 {
  margin-top: 11px !important;
}

.mtop-12 {
  margin-top: 12px !important;
}

.mtop-13 {
  margin-top: 13px !important;
}

.mtop-14 {
  margin-top: 14px !important;
}

.mtop-15 {
  margin-top: 15px !important;
}

.mtop-16 {
  margin-top: 16px !important;
}

.mtop-17 {
  margin-top: 17px !important;
}

.mtop-18 {
  margin-top: 18px !important;
}

.mtop-19 {
  margin-top: 19px !important;
}

.mtop-20 {
  margin-top: 20px !important;
}

/*class for margin bottom dynamic*/
.mbotttom-1 {
  margin-bottom: 1px !important;
}

.mbotttom-2 {
  margin-bottom: 2px !important;
}

.mbotttom-3 {
  margin-bottom: 3px !important;
}

.mbotttom-4 {
  margin-bottom: 4px !important;
}

.mbotttom-5 {
  margin-bottom: 5px !important;
}

.mbotttom-6 {
  margin-bottom: 6px !important;
}

.mbotttom-7 {
  margin-bottom: 7px !important;
}

.mbotttom-8 {
  margin-bottom: 8px !important;
}

.mbotttom-9 {
  margin-bottom: 9px !important;
}

.mbotttom-10 {
  margin-bottom: 10px !important;
}

.mbotttom-11 {
  margin-bottom: 11px !important;
}

.mbotttom-12 {
  margin-bottom: 12px !important;
}

.mbotttom-13 {
  margin-bottom: 13px !important;
}

.mbotttom-14 {
  margin-bottom: 14px !important;
}

.mbotttom-15 {
  margin-bottom: 15px !important;
}

.mbotttom-16 {
  margin-bottom: 16px !important;
}

.mbotttom-17 {
  margin-bottom: 17px !important;
}

.mbotttom-18 {
  margin-bottom: 18px !important;
}

.mbotttom-19 {
  margin-bottom: 19px !important;
}

.mbotttom-20 {
  margin-bottom: 20px !important;
}

/*class for line height dynamic*/
.lnht-0 {
  line-height: 0px !important;
}

.lnht-1 {
  line-height: 1px !important;
}

.lnht-2 {
  line-height: 2px !important;
}

.lnht-3 {
  line-height: 3px !important;
}

.lnht-4 {
  line-height: 4px !important;
}

.lnht-5 {
  line-height: 5px !important;
}

.lnht-6 {
  line-height: 6px !important;
}

.lnht-7 {
  line-height: 7px !important;
}

.lnht-8 {
  line-height: 8px !important;
}

.lnht-9 {
  line-height: 9px !important;
}

.lnht-10 {
  line-height: 10px !important;
}

.lnht-11 {
  line-height: 11px !important;
}

.lnht-12 {
  line-height: 12px !important;
}

.lnht-13 {
  line-height: 13px !important;
}

.lnht-14 {
  line-height: 14px !important;
}

.lnht-15 {
  line-height: 15px !important;
}

.lnht-16 {
  line-height: 16px !important;
}

.lnht-17 {
  line-height: 17px !important;
}

.lnht-18 {
  line-height: 18px !important;
}

.lnht-19 {
  line-height: 19px !important;
}

.lnht-20 {
  line-height: 20px !important;
}

.lnht-21 {
  line-height: 21px !important;
}

.lnht-22 {
  line-height: 22px !important;
}

.lnht-23 {
  line-height: 23px !important;
}

.lnht-24 {
  line-height: 24px !important;
}

.lnht-25 {
  line-height: 25px !important;
}

.lnht-26 {
  line-height: 26px !important;
}

.lnht-27 {
  line-height: 27px !important;
}

.lnht-28 {
  line-height: 28px !important;
}

.lnht-29 {
  line-height: 29px !important;
}

.lnht-30 {
  line-height: 30px !important;
}

.lnht-31 {
  line-height: 31px !important;
}

.lnht-32 {
  line-height: 32px !important;
}

.lnht-33 {
  line-height: 33px !important;
}

.lnht-34 {
  line-height: 34px !important;
}

.lnht-35 {
  line-height: 35px !important;
}

.lnht-36 {
  line-height: 36px !important;
}

.lnht-37 {
  line-height: 37px !important;
}

.lnht-38 {
  line-height: 38px !important;
}

.lnht-39 {
  line-height: 39px !important;
}

.lnht-40 {
  line-height: 40px !important;
}

/*class for font-weight (Note: Font Weight should be in multiples )*/
.font-weight-100 {
  font-weight: 100;
}

.font-weight-101 {
  font-weight: 100;
}

.font-weight-102 {
  font-weight: 100;
}

.font-weight-103 {
  font-weight: 100;
}

.font-weight-104 {
  font-weight: 100;
}

.font-weight-105 {
  font-weight: 100;
}

.font-weight-106 {
  font-weight: 100;
}

.font-weight-107 {
  font-weight: 100;
}

.font-weight-108 {
  font-weight: 100;
}

.font-weight-109 {
  font-weight: 100;
}

.font-weight-110 {
  font-weight: 100;
}

.font-weight-111 {
  font-weight: 100;
}

.font-weight-112 {
  font-weight: 100;
}

.font-weight-113 {
  font-weight: 100;
}

.font-weight-114 {
  font-weight: 100;
}

.font-weight-115 {
  font-weight: 100;
}

.font-weight-116 {
  font-weight: 100;
}

.font-weight-117 {
  font-weight: 100;
}

.font-weight-118 {
  font-weight: 100;
}

.font-weight-119 {
  font-weight: 100;
}

.font-weight-120 {
  font-weight: 100;
}

.font-weight-121 {
  font-weight: 100;
}

.font-weight-122 {
  font-weight: 100;
}

.font-weight-123 {
  font-weight: 100;
}

.font-weight-124 {
  font-weight: 100;
}

.font-weight-125 {
  font-weight: 100;
}

.font-weight-126 {
  font-weight: 100;
}

.font-weight-127 {
  font-weight: 100;
}

.font-weight-128 {
  font-weight: 100;
}

.font-weight-129 {
  font-weight: 100;
}

.font-weight-130 {
  font-weight: 100;
}

.font-weight-131 {
  font-weight: 100;
}

.font-weight-132 {
  font-weight: 100;
}

.font-weight-133 {
  font-weight: 100;
}

.font-weight-134 {
  font-weight: 100;
}

.font-weight-135 {
  font-weight: 100;
}

.font-weight-136 {
  font-weight: 100;
}

.font-weight-137 {
  font-weight: 100;
}

.font-weight-138 {
  font-weight: 100;
}

.font-weight-139 {
  font-weight: 100;
}

.font-weight-140 {
  font-weight: 100;
}

.font-weight-141 {
  font-weight: 100;
}

.font-weight-142 {
  font-weight: 100;
}

.font-weight-143 {
  font-weight: 100;
}

.font-weight-144 {
  font-weight: 100;
}

.font-weight-145 {
  font-weight: 100;
}

.font-weight-146 {
  font-weight: 100;
}

.font-weight-147 {
  font-weight: 100;
}

.font-weight-148 {
  font-weight: 100;
}

.font-weight-149 {
  font-weight: 100;
}

.font-weight-150 {
  font-weight: 200;
}

.font-weight-151 {
  font-weight: 200;
}

.font-weight-152 {
  font-weight: 200;
}

.font-weight-153 {
  font-weight: 200;
}

.font-weight-154 {
  font-weight: 200;
}

.font-weight-155 {
  font-weight: 200;
}

.font-weight-156 {
  font-weight: 200;
}

.font-weight-157 {
  font-weight: 200;
}

.font-weight-158 {
  font-weight: 200;
}

.font-weight-159 {
  font-weight: 200;
}

.font-weight-160 {
  font-weight: 200;
}

.font-weight-161 {
  font-weight: 200;
}

.font-weight-162 {
  font-weight: 200;
}

.font-weight-163 {
  font-weight: 200;
}

.font-weight-164 {
  font-weight: 200;
}

.font-weight-165 {
  font-weight: 200;
}

.font-weight-166 {
  font-weight: 200;
}

.font-weight-167 {
  font-weight: 200;
}

.font-weight-168 {
  font-weight: 200;
}

.font-weight-169 {
  font-weight: 200;
}

.font-weight-170 {
  font-weight: 200;
}

.font-weight-171 {
  font-weight: 200;
}

.font-weight-172 {
  font-weight: 200;
}

.font-weight-173 {
  font-weight: 200;
}

.font-weight-174 {
  font-weight: 200;
}

.font-weight-175 {
  font-weight: 200;
}

.font-weight-176 {
  font-weight: 200;
}

.font-weight-177 {
  font-weight: 200;
}

.font-weight-178 {
  font-weight: 200;
}

.font-weight-179 {
  font-weight: 200;
}

.font-weight-180 {
  font-weight: 200;
}

.font-weight-181 {
  font-weight: 200;
}

.font-weight-182 {
  font-weight: 200;
}

.font-weight-183 {
  font-weight: 200;
}

.font-weight-184 {
  font-weight: 200;
}

.font-weight-185 {
  font-weight: 200;
}

.font-weight-186 {
  font-weight: 200;
}

.font-weight-187 {
  font-weight: 200;
}

.font-weight-188 {
  font-weight: 200;
}

.font-weight-189 {
  font-weight: 200;
}

.font-weight-190 {
  font-weight: 200;
}

.font-weight-191 {
  font-weight: 200;
}

.font-weight-192 {
  font-weight: 200;
}

.font-weight-193 {
  font-weight: 200;
}

.font-weight-194 {
  font-weight: 200;
}

.font-weight-195 {
  font-weight: 200;
}

.font-weight-196 {
  font-weight: 200;
}

.font-weight-197 {
  font-weight: 200;
}

.font-weight-198 {
  font-weight: 200;
}

.font-weight-199 {
  font-weight: 200;
}

.font-weight-200 {
  font-weight: 200;
}

.font-weight-201 {
  font-weight: 200;
}

.font-weight-202 {
  font-weight: 200;
}

.font-weight-203 {
  font-weight: 200;
}

.font-weight-204 {
  font-weight: 200;
}

.font-weight-205 {
  font-weight: 200;
}

.font-weight-206 {
  font-weight: 200;
}

.font-weight-207 {
  font-weight: 200;
}

.font-weight-208 {
  font-weight: 200;
}

.font-weight-209 {
  font-weight: 200;
}

.font-weight-210 {
  font-weight: 200;
}

.font-weight-211 {
  font-weight: 200;
}

.font-weight-212 {
  font-weight: 200;
}

.font-weight-213 {
  font-weight: 200;
}

.font-weight-214 {
  font-weight: 200;
}

.font-weight-215 {
  font-weight: 200;
}

.font-weight-216 {
  font-weight: 200;
}

.font-weight-217 {
  font-weight: 200;
}

.font-weight-218 {
  font-weight: 200;
}

.font-weight-219 {
  font-weight: 200;
}

.font-weight-220 {
  font-weight: 200;
}

.font-weight-221 {
  font-weight: 200;
}

.font-weight-222 {
  font-weight: 200;
}

.font-weight-223 {
  font-weight: 200;
}

.font-weight-224 {
  font-weight: 200;
}

.font-weight-225 {
  font-weight: 200;
}

.font-weight-226 {
  font-weight: 200;
}

.font-weight-227 {
  font-weight: 200;
}

.font-weight-228 {
  font-weight: 200;
}

.font-weight-229 {
  font-weight: 200;
}

.font-weight-230 {
  font-weight: 200;
}

.font-weight-231 {
  font-weight: 200;
}

.font-weight-232 {
  font-weight: 200;
}

.font-weight-233 {
  font-weight: 200;
}

.font-weight-234 {
  font-weight: 200;
}

.font-weight-235 {
  font-weight: 200;
}

.font-weight-236 {
  font-weight: 200;
}

.font-weight-237 {
  font-weight: 200;
}

.font-weight-238 {
  font-weight: 200;
}

.font-weight-239 {
  font-weight: 200;
}

.font-weight-240 {
  font-weight: 200;
}

.font-weight-241 {
  font-weight: 200;
}

.font-weight-242 {
  font-weight: 200;
}

.font-weight-243 {
  font-weight: 200;
}

.font-weight-244 {
  font-weight: 200;
}

.font-weight-245 {
  font-weight: 200;
}

.font-weight-246 {
  font-weight: 200;
}

.font-weight-247 {
  font-weight: 200;
}

.font-weight-248 {
  font-weight: 200;
}

.font-weight-249 {
  font-weight: 200;
}

.font-weight-250 {
  font-weight: 300;
}

.font-weight-251 {
  font-weight: 300;
}

.font-weight-252 {
  font-weight: 300;
}

.font-weight-253 {
  font-weight: 300;
}

.font-weight-254 {
  font-weight: 300;
}

.font-weight-255 {
  font-weight: 300;
}

.font-weight-256 {
  font-weight: 300;
}

.font-weight-257 {
  font-weight: 300;
}

.font-weight-258 {
  font-weight: 300;
}

.font-weight-259 {
  font-weight: 300;
}

.font-weight-260 {
  font-weight: 300;
}

.font-weight-261 {
  font-weight: 300;
}

.font-weight-262 {
  font-weight: 300;
}

.font-weight-263 {
  font-weight: 300;
}

.font-weight-264 {
  font-weight: 300;
}

.font-weight-265 {
  font-weight: 300;
}

.font-weight-266 {
  font-weight: 300;
}

.font-weight-267 {
  font-weight: 300;
}

.font-weight-268 {
  font-weight: 300;
}

.font-weight-269 {
  font-weight: 300;
}

.font-weight-270 {
  font-weight: 300;
}

.font-weight-271 {
  font-weight: 300;
}

.font-weight-272 {
  font-weight: 300;
}

.font-weight-273 {
  font-weight: 300;
}

.font-weight-274 {
  font-weight: 300;
}

.font-weight-275 {
  font-weight: 300;
}

.font-weight-276 {
  font-weight: 300;
}

.font-weight-277 {
  font-weight: 300;
}

.font-weight-278 {
  font-weight: 300;
}

.font-weight-279 {
  font-weight: 300;
}

.font-weight-280 {
  font-weight: 300;
}

.font-weight-281 {
  font-weight: 300;
}

.font-weight-282 {
  font-weight: 300;
}

.font-weight-283 {
  font-weight: 300;
}

.font-weight-284 {
  font-weight: 300;
}

.font-weight-285 {
  font-weight: 300;
}

.font-weight-286 {
  font-weight: 300;
}

.font-weight-287 {
  font-weight: 300;
}

.font-weight-288 {
  font-weight: 300;
}

.font-weight-289 {
  font-weight: 300;
}

.font-weight-290 {
  font-weight: 300;
}

.font-weight-291 {
  font-weight: 300;
}

.font-weight-292 {
  font-weight: 300;
}

.font-weight-293 {
  font-weight: 300;
}

.font-weight-294 {
  font-weight: 300;
}

.font-weight-295 {
  font-weight: 300;
}

.font-weight-296 {
  font-weight: 300;
}

.font-weight-297 {
  font-weight: 300;
}

.font-weight-298 {
  font-weight: 300;
}

.font-weight-299 {
  font-weight: 300;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-301 {
  font-weight: 300;
}

.font-weight-302 {
  font-weight: 300;
}

.font-weight-303 {
  font-weight: 300;
}

.font-weight-304 {
  font-weight: 300;
}

.font-weight-305 {
  font-weight: 300;
}

.font-weight-306 {
  font-weight: 300;
}

.font-weight-307 {
  font-weight: 300;
}

.font-weight-308 {
  font-weight: 300;
}

.font-weight-309 {
  font-weight: 300;
}

.font-weight-310 {
  font-weight: 300;
}

.font-weight-311 {
  font-weight: 300;
}

.font-weight-312 {
  font-weight: 300;
}

.font-weight-313 {
  font-weight: 300;
}

.font-weight-314 {
  font-weight: 300;
}

.font-weight-315 {
  font-weight: 300;
}

.font-weight-316 {
  font-weight: 300;
}

.font-weight-317 {
  font-weight: 300;
}

.font-weight-318 {
  font-weight: 300;
}

.font-weight-319 {
  font-weight: 300;
}

.font-weight-320 {
  font-weight: 300;
}

.font-weight-321 {
  font-weight: 300;
}

.font-weight-322 {
  font-weight: 300;
}

.font-weight-323 {
  font-weight: 300;
}

.font-weight-324 {
  font-weight: 300;
}

.font-weight-325 {
  font-weight: 300;
}

.font-weight-326 {
  font-weight: 300;
}

.font-weight-327 {
  font-weight: 300;
}

.font-weight-328 {
  font-weight: 300;
}

.font-weight-329 {
  font-weight: 300;
}

.font-weight-330 {
  font-weight: 300;
}

.font-weight-331 {
  font-weight: 300;
}

.font-weight-332 {
  font-weight: 300;
}

.font-weight-333 {
  font-weight: 300;
}

.font-weight-334 {
  font-weight: 300;
}

.font-weight-335 {
  font-weight: 300;
}

.font-weight-336 {
  font-weight: 300;
}

.font-weight-337 {
  font-weight: 300;
}

.font-weight-338 {
  font-weight: 300;
}

.font-weight-339 {
  font-weight: 300;
}

.font-weight-340 {
  font-weight: 300;
}

.font-weight-341 {
  font-weight: 300;
}

.font-weight-342 {
  font-weight: 300;
}

.font-weight-343 {
  font-weight: 300;
}

.font-weight-344 {
  font-weight: 300;
}

.font-weight-345 {
  font-weight: 300;
}

.font-weight-346 {
  font-weight: 300;
}

.font-weight-347 {
  font-weight: 300;
}

.font-weight-348 {
  font-weight: 300;
}

.font-weight-349 {
  font-weight: 300;
}

.font-weight-350 {
  font-weight: 400;
}

.font-weight-351 {
  font-weight: 400;
}

.font-weight-352 {
  font-weight: 400;
}

.font-weight-353 {
  font-weight: 400;
}

.font-weight-354 {
  font-weight: 400;
}

.font-weight-355 {
  font-weight: 400;
}

.font-weight-356 {
  font-weight: 400;
}

.font-weight-357 {
  font-weight: 400;
}

.font-weight-358 {
  font-weight: 400;
}

.font-weight-359 {
  font-weight: 400;
}

.font-weight-360 {
  font-weight: 400;
}

.font-weight-361 {
  font-weight: 400;
}

.font-weight-362 {
  font-weight: 400;
}

.font-weight-363 {
  font-weight: 400;
}

.font-weight-364 {
  font-weight: 400;
}

.font-weight-365 {
  font-weight: 400;
}

.font-weight-366 {
  font-weight: 400;
}

.font-weight-367 {
  font-weight: 400;
}

.font-weight-368 {
  font-weight: 400;
}

.font-weight-369 {
  font-weight: 400;
}

.font-weight-370 {
  font-weight: 400;
}

.font-weight-371 {
  font-weight: 400;
}

.font-weight-372 {
  font-weight: 400;
}

.font-weight-373 {
  font-weight: 400;
}

.font-weight-374 {
  font-weight: 400;
}

.font-weight-375 {
  font-weight: 400;
}

.font-weight-376 {
  font-weight: 400;
}

.font-weight-377 {
  font-weight: 400;
}

.font-weight-378 {
  font-weight: 400;
}

.font-weight-379 {
  font-weight: 400;
}

.font-weight-380 {
  font-weight: 400;
}

.font-weight-381 {
  font-weight: 400;
}

.font-weight-382 {
  font-weight: 400;
}

.font-weight-383 {
  font-weight: 400;
}

.font-weight-384 {
  font-weight: 400;
}

.font-weight-385 {
  font-weight: 400;
}

.font-weight-386 {
  font-weight: 400;
}

.font-weight-387 {
  font-weight: 400;
}

.font-weight-388 {
  font-weight: 400;
}

.font-weight-389 {
  font-weight: 400;
}

.font-weight-390 {
  font-weight: 400;
}

.font-weight-391 {
  font-weight: 400;
}

.font-weight-392 {
  font-weight: 400;
}

.font-weight-393 {
  font-weight: 400;
}

.font-weight-394 {
  font-weight: 400;
}

.font-weight-395 {
  font-weight: 400;
}

.font-weight-396 {
  font-weight: 400;
}

.font-weight-397 {
  font-weight: 400;
}

.font-weight-398 {
  font-weight: 400;
}

.font-weight-399 {
  font-weight: 400;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-401 {
  font-weight: 400;
}

.font-weight-402 {
  font-weight: 400;
}

.font-weight-403 {
  font-weight: 400;
}

.font-weight-404 {
  font-weight: 400;
}

.font-weight-405 {
  font-weight: 400;
}

.font-weight-406 {
  font-weight: 400;
}

.font-weight-407 {
  font-weight: 400;
}

.font-weight-408 {
  font-weight: 400;
}

.font-weight-409 {
  font-weight: 400;
}

.font-weight-410 {
  font-weight: 400;
}

.font-weight-411 {
  font-weight: 400;
}

.font-weight-412 {
  font-weight: 400;
}

.font-weight-413 {
  font-weight: 400;
}

.font-weight-414 {
  font-weight: 400;
}

.font-weight-415 {
  font-weight: 400;
}

.font-weight-416 {
  font-weight: 400;
}

.font-weight-417 {
  font-weight: 400;
}

.font-weight-418 {
  font-weight: 400;
}

.font-weight-419 {
  font-weight: 400;
}

.font-weight-420 {
  font-weight: 400;
}

.font-weight-421 {
  font-weight: 400;
}

.font-weight-422 {
  font-weight: 400;
}

.font-weight-423 {
  font-weight: 400;
}

.font-weight-424 {
  font-weight: 400;
}

.font-weight-425 {
  font-weight: 400;
}

.font-weight-426 {
  font-weight: 400;
}

.font-weight-427 {
  font-weight: 400;
}

.font-weight-428 {
  font-weight: 400;
}

.font-weight-429 {
  font-weight: 400;
}

.font-weight-430 {
  font-weight: 400;
}

.font-weight-431 {
  font-weight: 400;
}

.font-weight-432 {
  font-weight: 400;
}

.font-weight-433 {
  font-weight: 400;
}

.font-weight-434 {
  font-weight: 400;
}

.font-weight-435 {
  font-weight: 400;
}

.font-weight-436 {
  font-weight: 400;
}

.font-weight-437 {
  font-weight: 400;
}

.font-weight-438 {
  font-weight: 400;
}

.font-weight-439 {
  font-weight: 400;
}

.font-weight-440 {
  font-weight: 400;
}

.font-weight-441 {
  font-weight: 400;
}

.font-weight-442 {
  font-weight: 400;
}

.font-weight-443 {
  font-weight: 400;
}

.font-weight-444 {
  font-weight: 400;
}

.font-weight-445 {
  font-weight: 400;
}

.font-weight-446 {
  font-weight: 400;
}

.font-weight-447 {
  font-weight: 400;
}

.font-weight-448 {
  font-weight: 400;
}

.font-weight-449 {
  font-weight: 400;
}

.font-weight-450 {
  font-weight: 500;
}

.font-weight-451 {
  font-weight: 500;
}

.font-weight-452 {
  font-weight: 500;
}

.font-weight-453 {
  font-weight: 500;
}

.font-weight-454 {
  font-weight: 500;
}

.font-weight-455 {
  font-weight: 500;
}

.font-weight-456 {
  font-weight: 500;
}

.font-weight-457 {
  font-weight: 500;
}

.font-weight-458 {
  font-weight: 500;
}

.font-weight-459 {
  font-weight: 500;
}

.font-weight-460 {
  font-weight: 500;
}

.font-weight-461 {
  font-weight: 500;
}

.font-weight-462 {
  font-weight: 500;
}

.font-weight-463 {
  font-weight: 500;
}

.font-weight-464 {
  font-weight: 500;
}

.font-weight-465 {
  font-weight: 500;
}

.font-weight-466 {
  font-weight: 500;
}

.font-weight-467 {
  font-weight: 500;
}

.font-weight-468 {
  font-weight: 500;
}

.font-weight-469 {
  font-weight: 500;
}

.font-weight-470 {
  font-weight: 500;
}

.font-weight-471 {
  font-weight: 500;
}

.font-weight-472 {
  font-weight: 500;
}

.font-weight-473 {
  font-weight: 500;
}

.font-weight-474 {
  font-weight: 500;
}

.font-weight-475 {
  font-weight: 500;
}

.font-weight-476 {
  font-weight: 500;
}

.font-weight-477 {
  font-weight: 500;
}

.font-weight-478 {
  font-weight: 500;
}

.font-weight-479 {
  font-weight: 500;
}

.font-weight-480 {
  font-weight: 500;
}

.font-weight-481 {
  font-weight: 500;
}

.font-weight-482 {
  font-weight: 500;
}

.font-weight-483 {
  font-weight: 500;
}

.font-weight-484 {
  font-weight: 500;
}

.font-weight-485 {
  font-weight: 500;
}

.font-weight-486 {
  font-weight: 500;
}

.font-weight-487 {
  font-weight: 500;
}

.font-weight-488 {
  font-weight: 500;
}

.font-weight-489 {
  font-weight: 500;
}

.font-weight-490 {
  font-weight: 500;
}

.font-weight-491 {
  font-weight: 500;
}

.font-weight-492 {
  font-weight: 500;
}

.font-weight-493 {
  font-weight: 500;
}

.font-weight-494 {
  font-weight: 500;
}

.font-weight-495 {
  font-weight: 500;
}

.font-weight-496 {
  font-weight: 500;
}

.font-weight-497 {
  font-weight: 500;
}

.font-weight-498 {
  font-weight: 500;
}

.font-weight-499 {
  font-weight: 500;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-501 {
  font-weight: 500;
}

.font-weight-502 {
  font-weight: 500;
}

.font-weight-503 {
  font-weight: 500;
}

.font-weight-504 {
  font-weight: 500;
}

.font-weight-505 {
  font-weight: 500;
}

.font-weight-506 {
  font-weight: 500;
}

.font-weight-507 {
  font-weight: 500;
}

.font-weight-508 {
  font-weight: 500;
}

.font-weight-509 {
  font-weight: 500;
}

.font-weight-510 {
  font-weight: 500;
}

.font-weight-511 {
  font-weight: 500;
}

.font-weight-512 {
  font-weight: 500;
}

.font-weight-513 {
  font-weight: 500;
}

.font-weight-514 {
  font-weight: 500;
}

.font-weight-515 {
  font-weight: 500;
}

.font-weight-516 {
  font-weight: 500;
}

.font-weight-517 {
  font-weight: 500;
}

.font-weight-518 {
  font-weight: 500;
}

.font-weight-519 {
  font-weight: 500;
}

.font-weight-520 {
  font-weight: 500;
}

.font-weight-521 {
  font-weight: 500;
}

.font-weight-522 {
  font-weight: 500;
}

.font-weight-523 {
  font-weight: 500;
}

.font-weight-524 {
  font-weight: 500;
}

.font-weight-525 {
  font-weight: 500;
}

.font-weight-526 {
  font-weight: 500;
}

.font-weight-527 {
  font-weight: 500;
}

.font-weight-528 {
  font-weight: 500;
}

.font-weight-529 {
  font-weight: 500;
}

.font-weight-530 {
  font-weight: 500;
}

.font-weight-531 {
  font-weight: 500;
}

.font-weight-532 {
  font-weight: 500;
}

.font-weight-533 {
  font-weight: 500;
}

.font-weight-534 {
  font-weight: 500;
}

.font-weight-535 {
  font-weight: 500;
}

.font-weight-536 {
  font-weight: 500;
}

.font-weight-537 {
  font-weight: 500;
}

.font-weight-538 {
  font-weight: 500;
}

.font-weight-539 {
  font-weight: 500;
}

.font-weight-540 {
  font-weight: 500;
}

.font-weight-541 {
  font-weight: 500;
}

.font-weight-542 {
  font-weight: 500;
}

.font-weight-543 {
  font-weight: 500;
}

.font-weight-544 {
  font-weight: 500;
}

.font-weight-545 {
  font-weight: 500;
}

.font-weight-546 {
  font-weight: 500;
}

.font-weight-547 {
  font-weight: 500;
}

.font-weight-548 {
  font-weight: 500;
}

.font-weight-549 {
  font-weight: 500;
}

.font-weight-550 {
  font-weight: 600;
}

.font-weight-551 {
  font-weight: 600;
}

.font-weight-552 {
  font-weight: 600;
}

.font-weight-553 {
  font-weight: 600;
}

.font-weight-554 {
  font-weight: 600;
}

.font-weight-555 {
  font-weight: 600;
}

.font-weight-556 {
  font-weight: 600;
}

.font-weight-557 {
  font-weight: 600;
}

.font-weight-558 {
  font-weight: 600;
}

.font-weight-559 {
  font-weight: 600;
}

.font-weight-560 {
  font-weight: 600;
}

.font-weight-561 {
  font-weight: 600;
}

.font-weight-562 {
  font-weight: 600;
}

.font-weight-563 {
  font-weight: 600;
}

.font-weight-564 {
  font-weight: 600;
}

.font-weight-565 {
  font-weight: 600;
}

.font-weight-566 {
  font-weight: 600;
}

.font-weight-567 {
  font-weight: 600;
}

.font-weight-568 {
  font-weight: 600;
}

.font-weight-569 {
  font-weight: 600;
}

.font-weight-570 {
  font-weight: 600;
}

.font-weight-571 {
  font-weight: 600;
}

.font-weight-572 {
  font-weight: 600;
}

.font-weight-573 {
  font-weight: 600;
}

.font-weight-574 {
  font-weight: 600;
}

.font-weight-575 {
  font-weight: 600;
}

.font-weight-576 {
  font-weight: 600;
}

.font-weight-577 {
  font-weight: 600;
}

.font-weight-578 {
  font-weight: 600;
}

.font-weight-579 {
  font-weight: 600;
}

.font-weight-580 {
  font-weight: 600;
}

.font-weight-581 {
  font-weight: 600;
}

.font-weight-582 {
  font-weight: 600;
}

.font-weight-583 {
  font-weight: 600;
}

.font-weight-584 {
  font-weight: 600;
}

.font-weight-585 {
  font-weight: 600;
}

.font-weight-586 {
  font-weight: 600;
}

.font-weight-587 {
  font-weight: 600;
}

.font-weight-588 {
  font-weight: 600;
}

.font-weight-589 {
  font-weight: 600;
}

.font-weight-590 {
  font-weight: 600;
}

.font-weight-591 {
  font-weight: 600;
}

.font-weight-592 {
  font-weight: 600;
}

.font-weight-593 {
  font-weight: 600;
}

.font-weight-594 {
  font-weight: 600;
}

.font-weight-595 {
  font-weight: 600;
}

.font-weight-596 {
  font-weight: 600;
}

.font-weight-597 {
  font-weight: 600;
}

.font-weight-598 {
  font-weight: 600;
}

.font-weight-599 {
  font-weight: 600;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-601 {
  font-weight: 600;
}

.font-weight-602 {
  font-weight: 600;
}

.font-weight-603 {
  font-weight: 600;
}

.font-weight-604 {
  font-weight: 600;
}

.font-weight-605 {
  font-weight: 600;
}

.font-weight-606 {
  font-weight: 600;
}

.font-weight-607 {
  font-weight: 600;
}

.font-weight-608 {
  font-weight: 600;
}

.font-weight-609 {
  font-weight: 600;
}

.font-weight-610 {
  font-weight: 600;
}

.font-weight-611 {
  font-weight: 600;
}

.font-weight-612 {
  font-weight: 600;
}

.font-weight-613 {
  font-weight: 600;
}

.font-weight-614 {
  font-weight: 600;
}

.font-weight-615 {
  font-weight: 600;
}

.font-weight-616 {
  font-weight: 600;
}

.font-weight-617 {
  font-weight: 600;
}

.font-weight-618 {
  font-weight: 600;
}

.font-weight-619 {
  font-weight: 600;
}

.font-weight-620 {
  font-weight: 600;
}

.font-weight-621 {
  font-weight: 600;
}

.font-weight-622 {
  font-weight: 600;
}

.font-weight-623 {
  font-weight: 600;
}

.font-weight-624 {
  font-weight: 600;
}

.font-weight-625 {
  font-weight: 600;
}

.font-weight-626 {
  font-weight: 600;
}

.font-weight-627 {
  font-weight: 600;
}

.font-weight-628 {
  font-weight: 600;
}

.font-weight-629 {
  font-weight: 600;
}

.font-weight-630 {
  font-weight: 600;
}

.font-weight-631 {
  font-weight: 600;
}

.font-weight-632 {
  font-weight: 600;
}

.font-weight-633 {
  font-weight: 600;
}

.font-weight-634 {
  font-weight: 600;
}

.font-weight-635 {
  font-weight: 600;
}

.font-weight-636 {
  font-weight: 600;
}

.font-weight-637 {
  font-weight: 600;
}

.font-weight-638 {
  font-weight: 600;
}

.font-weight-639 {
  font-weight: 600;
}

.font-weight-640 {
  font-weight: 600;
}

.font-weight-641 {
  font-weight: 600;
}

.font-weight-642 {
  font-weight: 600;
}

.font-weight-643 {
  font-weight: 600;
}

.font-weight-644 {
  font-weight: 600;
}

.font-weight-645 {
  font-weight: 600;
}

.font-weight-646 {
  font-weight: 600;
}

.font-weight-647 {
  font-weight: 600;
}

.font-weight-648 {
  font-weight: 600;
}

.font-weight-649 {
  font-weight: 600;
}

.font-weight-650 {
  font-weight: 700;
}

.font-weight-651 {
  font-weight: 700;
}

.font-weight-652 {
  font-weight: 700;
}

.font-weight-653 {
  font-weight: 700;
}

.font-weight-654 {
  font-weight: 700;
}

.font-weight-655 {
  font-weight: 700;
}

.font-weight-656 {
  font-weight: 700;
}

.font-weight-657 {
  font-weight: 700;
}

.font-weight-658 {
  font-weight: 700;
}

.font-weight-659 {
  font-weight: 700;
}

.font-weight-660 {
  font-weight: 700;
}

.font-weight-661 {
  font-weight: 700;
}

.font-weight-662 {
  font-weight: 700;
}

.font-weight-663 {
  font-weight: 700;
}

.font-weight-664 {
  font-weight: 700;
}

.font-weight-665 {
  font-weight: 700;
}

.font-weight-666 {
  font-weight: 700;
}

.font-weight-667 {
  font-weight: 700;
}

.font-weight-668 {
  font-weight: 700;
}

.font-weight-669 {
  font-weight: 700;
}

.font-weight-670 {
  font-weight: 700;
}

.font-weight-671 {
  font-weight: 700;
}

.font-weight-672 {
  font-weight: 700;
}

.font-weight-673 {
  font-weight: 700;
}

.font-weight-674 {
  font-weight: 700;
}

.font-weight-675 {
  font-weight: 700;
}

.font-weight-676 {
  font-weight: 700;
}

.font-weight-677 {
  font-weight: 700;
}

.font-weight-678 {
  font-weight: 700;
}

.font-weight-679 {
  font-weight: 700;
}

.font-weight-680 {
  font-weight: 700;
}

.font-weight-681 {
  font-weight: 700;
}

.font-weight-682 {
  font-weight: 700;
}

.font-weight-683 {
  font-weight: 700;
}

.font-weight-684 {
  font-weight: 700;
}

.font-weight-685 {
  font-weight: 700;
}

.font-weight-686 {
  font-weight: 700;
}

.font-weight-687 {
  font-weight: 700;
}

.font-weight-688 {
  font-weight: 700;
}

.font-weight-689 {
  font-weight: 700;
}

.font-weight-690 {
  font-weight: 700;
}

.font-weight-691 {
  font-weight: 700;
}

.font-weight-692 {
  font-weight: 700;
}

.font-weight-693 {
  font-weight: 700;
}

.font-weight-694 {
  font-weight: 700;
}

.font-weight-695 {
  font-weight: 700;
}

.font-weight-696 {
  font-weight: 700;
}

.font-weight-697 {
  font-weight: 700;
}

.font-weight-698 {
  font-weight: 700;
}

.font-weight-699 {
  font-weight: 700;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-701 {
  font-weight: 700;
}

.font-weight-702 {
  font-weight: 700;
}

.font-weight-703 {
  font-weight: 700;
}

.font-weight-704 {
  font-weight: 700;
}

.font-weight-705 {
  font-weight: 700;
}

.font-weight-706 {
  font-weight: 700;
}

.font-weight-707 {
  font-weight: 700;
}

.font-weight-708 {
  font-weight: 700;
}

.font-weight-709 {
  font-weight: 700;
}

.font-weight-710 {
  font-weight: 700;
}

.font-weight-711 {
  font-weight: 700;
}

.font-weight-712 {
  font-weight: 700;
}

.font-weight-713 {
  font-weight: 700;
}

.font-weight-714 {
  font-weight: 700;
}

.font-weight-715 {
  font-weight: 700;
}

.font-weight-716 {
  font-weight: 700;
}

.font-weight-717 {
  font-weight: 700;
}

.font-weight-718 {
  font-weight: 700;
}

.font-weight-719 {
  font-weight: 700;
}

.font-weight-720 {
  font-weight: 700;
}

.font-weight-721 {
  font-weight: 700;
}

.font-weight-722 {
  font-weight: 700;
}

.font-weight-723 {
  font-weight: 700;
}

.font-weight-724 {
  font-weight: 700;
}

.font-weight-725 {
  font-weight: 700;
}

.font-weight-726 {
  font-weight: 700;
}

.font-weight-727 {
  font-weight: 700;
}

.font-weight-728 {
  font-weight: 700;
}

.font-weight-729 {
  font-weight: 700;
}

.font-weight-730 {
  font-weight: 700;
}

.font-weight-731 {
  font-weight: 700;
}

.font-weight-732 {
  font-weight: 700;
}

.font-weight-733 {
  font-weight: 700;
}

.font-weight-734 {
  font-weight: 700;
}

.font-weight-735 {
  font-weight: 700;
}

.font-weight-736 {
  font-weight: 700;
}

.font-weight-737 {
  font-weight: 700;
}

.font-weight-738 {
  font-weight: 700;
}

.font-weight-739 {
  font-weight: 700;
}

.font-weight-740 {
  font-weight: 700;
}

.font-weight-741 {
  font-weight: 700;
}

.font-weight-742 {
  font-weight: 700;
}

.font-weight-743 {
  font-weight: 700;
}

.font-weight-744 {
  font-weight: 700;
}

.font-weight-745 {
  font-weight: 700;
}

.font-weight-746 {
  font-weight: 700;
}

.font-weight-747 {
  font-weight: 700;
}

.font-weight-748 {
  font-weight: 700;
}

.font-weight-749 {
  font-weight: 700;
}

.font-weight-750 {
  font-weight: 800;
}

.font-weight-751 {
  font-weight: 800;
}

.font-weight-752 {
  font-weight: 800;
}

.font-weight-753 {
  font-weight: 800;
}

.font-weight-754 {
  font-weight: 800;
}

.font-weight-755 {
  font-weight: 800;
}

.font-weight-756 {
  font-weight: 800;
}

.font-weight-757 {
  font-weight: 800;
}

.font-weight-758 {
  font-weight: 800;
}

.font-weight-759 {
  font-weight: 800;
}

.font-weight-760 {
  font-weight: 800;
}

.font-weight-761 {
  font-weight: 800;
}

.font-weight-762 {
  font-weight: 800;
}

.font-weight-763 {
  font-weight: 800;
}

.font-weight-764 {
  font-weight: 800;
}

.font-weight-765 {
  font-weight: 800;
}

.font-weight-766 {
  font-weight: 800;
}

.font-weight-767 {
  font-weight: 800;
}

.font-weight-768 {
  font-weight: 800;
}

.font-weight-769 {
  font-weight: 800;
}

.font-weight-770 {
  font-weight: 800;
}

.font-weight-771 {
  font-weight: 800;
}

.font-weight-772 {
  font-weight: 800;
}

.font-weight-773 {
  font-weight: 800;
}

.font-weight-774 {
  font-weight: 800;
}

.font-weight-775 {
  font-weight: 800;
}

.font-weight-776 {
  font-weight: 800;
}

.font-weight-777 {
  font-weight: 800;
}

.font-weight-778 {
  font-weight: 800;
}

.font-weight-779 {
  font-weight: 800;
}

.font-weight-780 {
  font-weight: 800;
}

.font-weight-781 {
  font-weight: 800;
}

.font-weight-782 {
  font-weight: 800;
}

.font-weight-783 {
  font-weight: 800;
}

.font-weight-784 {
  font-weight: 800;
}

.font-weight-785 {
  font-weight: 800;
}

.font-weight-786 {
  font-weight: 800;
}

.font-weight-787 {
  font-weight: 800;
}

.font-weight-788 {
  font-weight: 800;
}

.font-weight-789 {
  font-weight: 800;
}

.font-weight-790 {
  font-weight: 800;
}

.font-weight-791 {
  font-weight: 800;
}

.font-weight-792 {
  font-weight: 800;
}

.font-weight-793 {
  font-weight: 800;
}

.font-weight-794 {
  font-weight: 800;
}

.font-weight-795 {
  font-weight: 800;
}

.font-weight-796 {
  font-weight: 800;
}

.font-weight-797 {
  font-weight: 800;
}

.font-weight-798 {
  font-weight: 800;
}

.font-weight-799 {
  font-weight: 800;
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-801 {
  font-weight: 800;
}

.font-weight-802 {
  font-weight: 800;
}

.font-weight-803 {
  font-weight: 800;
}

.font-weight-804 {
  font-weight: 800;
}

.font-weight-805 {
  font-weight: 800;
}

.font-weight-806 {
  font-weight: 800;
}

.font-weight-807 {
  font-weight: 800;
}

.font-weight-808 {
  font-weight: 800;
}

.font-weight-809 {
  font-weight: 800;
}

.font-weight-810 {
  font-weight: 800;
}

.font-weight-811 {
  font-weight: 800;
}

.font-weight-812 {
  font-weight: 800;
}

.font-weight-813 {
  font-weight: 800;
}

.font-weight-814 {
  font-weight: 800;
}

.font-weight-815 {
  font-weight: 800;
}

.font-weight-816 {
  font-weight: 800;
}

.font-weight-817 {
  font-weight: 800;
}

.font-weight-818 {
  font-weight: 800;
}

.font-weight-819 {
  font-weight: 800;
}

.font-weight-820 {
  font-weight: 800;
}

.font-weight-821 {
  font-weight: 800;
}

.font-weight-822 {
  font-weight: 800;
}

.font-weight-823 {
  font-weight: 800;
}

.font-weight-824 {
  font-weight: 800;
}

.font-weight-825 {
  font-weight: 800;
}

.font-weight-826 {
  font-weight: 800;
}

.font-weight-827 {
  font-weight: 800;
}

.font-weight-828 {
  font-weight: 800;
}

.font-weight-829 {
  font-weight: 800;
}

.font-weight-830 {
  font-weight: 800;
}

.font-weight-831 {
  font-weight: 800;
}

.font-weight-832 {
  font-weight: 800;
}

.font-weight-833 {
  font-weight: 800;
}

.font-weight-834 {
  font-weight: 800;
}

.font-weight-835 {
  font-weight: 800;
}

.font-weight-836 {
  font-weight: 800;
}

.font-weight-837 {
  font-weight: 800;
}

.font-weight-838 {
  font-weight: 800;
}

.font-weight-839 {
  font-weight: 800;
}

.font-weight-840 {
  font-weight: 800;
}

.font-weight-841 {
  font-weight: 800;
}

.font-weight-842 {
  font-weight: 800;
}

.font-weight-843 {
  font-weight: 800;
}

.font-weight-844 {
  font-weight: 800;
}

.font-weight-845 {
  font-weight: 800;
}

.font-weight-846 {
  font-weight: 800;
}

.font-weight-847 {
  font-weight: 800;
}

.font-weight-848 {
  font-weight: 800;
}

.font-weight-849 {
  font-weight: 800;
}

.font-weight-850 {
  font-weight: 900;
}

.font-weight-851 {
  font-weight: 900;
}

.font-weight-852 {
  font-weight: 900;
}

.font-weight-853 {
  font-weight: 900;
}

.font-weight-854 {
  font-weight: 900;
}

.font-weight-855 {
  font-weight: 900;
}

.font-weight-856 {
  font-weight: 900;
}

.font-weight-857 {
  font-weight: 900;
}

.font-weight-858 {
  font-weight: 900;
}

.font-weight-859 {
  font-weight: 900;
}

.font-weight-860 {
  font-weight: 900;
}

.font-weight-861 {
  font-weight: 900;
}

.font-weight-862 {
  font-weight: 900;
}

.font-weight-863 {
  font-weight: 900;
}

.font-weight-864 {
  font-weight: 900;
}

.font-weight-865 {
  font-weight: 900;
}

.font-weight-866 {
  font-weight: 900;
}

.font-weight-867 {
  font-weight: 900;
}

.font-weight-868 {
  font-weight: 900;
}

.font-weight-869 {
  font-weight: 900;
}

.font-weight-870 {
  font-weight: 900;
}

.font-weight-871 {
  font-weight: 900;
}

.font-weight-872 {
  font-weight: 900;
}

.font-weight-873 {
  font-weight: 900;
}

.font-weight-874 {
  font-weight: 900;
}

.font-weight-875 {
  font-weight: 900;
}

.font-weight-876 {
  font-weight: 900;
}

.font-weight-877 {
  font-weight: 900;
}

.font-weight-878 {
  font-weight: 900;
}

.font-weight-879 {
  font-weight: 900;
}

.font-weight-880 {
  font-weight: 900;
}

.font-weight-881 {
  font-weight: 900;
}

.font-weight-882 {
  font-weight: 900;
}

.font-weight-883 {
  font-weight: 900;
}

.font-weight-884 {
  font-weight: 900;
}

.font-weight-885 {
  font-weight: 900;
}

.font-weight-886 {
  font-weight: 900;
}

.font-weight-887 {
  font-weight: 900;
}

.font-weight-888 {
  font-weight: 900;
}

.font-weight-889 {
  font-weight: 900;
}

.font-weight-890 {
  font-weight: 900;
}

.font-weight-891 {
  font-weight: 900;
}

.font-weight-892 {
  font-weight: 900;
}

.font-weight-893 {
  font-weight: 900;
}

.font-weight-894 {
  font-weight: 900;
}

.font-weight-895 {
  font-weight: 900;
}

.font-weight-896 {
  font-weight: 900;
}

.font-weight-897 {
  font-weight: 900;
}

.font-weight-898 {
  font-weight: 900;
}

.font-weight-899 {
  font-weight: 900;
}

.font-weight-900 {
  font-weight: 900;
}

/*class for width Adjust in percent dynamic*/
.width-adjust-1 {
  max-width: 1% !important;
}

.width-adjust-2 {
  max-width: 2% !important;
}

.width-adjust-3 {
  max-width: 3% !important;
}

.width-adjust-4 {
  max-width: 4% !important;
}

.width-adjust-5 {
  max-width: 5% !important;
}

.width-adjust-6 {
  max-width: 6% !important;
}

.width-adjust-7 {
  max-width: 7% !important;
}

.width-adjust-8 {
  max-width: 8% !important;
}

.width-adjust-9 {
  max-width: 9% !important;
}

.width-adjust-10 {
  max-width: 10% !important;
}

.width-adjust-11 {
  max-width: 11% !important;
}

.width-adjust-12 {
  max-width: 12% !important;
}

.width-adjust-13 {
  max-width: 13% !important;
}

.width-adjust-14 {
  max-width: 14% !important;
}

.width-adjust-15 {
  max-width: 15% !important;
}

.width-adjust-16 {
  max-width: 16% !important;
}

.width-adjust-17 {
  max-width: 17% !important;
}

.width-adjust-18 {
  max-width: 18% !important;
}

.width-adjust-19 {
  max-width: 19% !important;
}

.width-adjust-20 {
  max-width: 20% !important;
}

.width-adjust-21 {
  max-width: 21% !important;
}

.width-adjust-22 {
  max-width: 22% !important;
}

.width-adjust-23 {
  max-width: 23% !important;
}

.width-adjust-24 {
  max-width: 24% !important;
}

.width-adjust-25 {
  max-width: 25% !important;
}

.width-adjust-26 {
  max-width: 26% !important;
}

.width-adjust-27 {
  max-width: 27% !important;
}

.width-adjust-28 {
  max-width: 28% !important;
}

.width-adjust-29 {
  max-width: 29% !important;
}

.width-adjust-30 {
  max-width: 30% !important;
}

.width-adjust-31 {
  max-width: 31% !important;
}

.width-adjust-32 {
  max-width: 32% !important;
}

.width-adjust-33 {
  max-width: 33% !important;
}

.width-adjust-34 {
  max-width: 34% !important;
}

.width-adjust-35 {
  max-width: 35% !important;
}

.width-adjust-36 {
  max-width: 36% !important;
}

.width-adjust-37 {
  max-width: 37% !important;
}

.width-adjust-38 {
  max-width: 38% !important;
}

.width-adjust-39 {
  max-width: 39% !important;
}

.width-adjust-40 {
  max-width: 40% !important;
}

.width-adjust-41 {
  max-width: 41% !important;
}

.width-adjust-42 {
  max-width: 42% !important;
}

.width-adjust-43 {
  max-width: 43% !important;
}

.width-adjust-44 {
  max-width: 44% !important;
}

.width-adjust-45 {
  max-width: 45% !important;
}

.width-adjust-46 {
  max-width: 46% !important;
}

.width-adjust-47 {
  max-width: 47% !important;
}

.width-adjust-48 {
  max-width: 48% !important;
}

.width-adjust-49 {
  max-width: 49% !important;
}

.width-adjust-50 {
  max-width: 50% !important;
}

.width-adjust-51 {
  max-width: 51% !important;
}

.width-adjust-52 {
  max-width: 52% !important;
}

.width-adjust-53 {
  max-width: 53% !important;
}

.width-adjust-54 {
  max-width: 54% !important;
}

.width-adjust-55 {
  max-width: 55% !important;
}

.width-adjust-56 {
  max-width: 56% !important;
}

.width-adjust-57 {
  max-width: 57% !important;
}

.width-adjust-58 {
  max-width: 58% !important;
}

.width-adjust-59 {
  max-width: 59% !important;
}

.width-adjust-60 {
  max-width: 60% !important;
}

.width-adjust-61 {
  max-width: 61% !important;
}

.width-adjust-62 {
  max-width: 62% !important;
}

.width-adjust-63 {
  max-width: 63% !important;
}

.width-adjust-64 {
  max-width: 64% !important;
}

.width-adjust-65 {
  max-width: 65% !important;
}

.width-adjust-66 {
  max-width: 66% !important;
}

.width-adjust-67 {
  max-width: 67% !important;
}

.width-adjust-68 {
  max-width: 68% !important;
}

.width-adjust-69 {
  max-width: 69% !important;
}

.width-adjust-70 {
  max-width: 70% !important;
}

.width-adjust-71 {
  max-width: 71% !important;
}

.width-adjust-72 {
  max-width: 72% !important;
}

.width-adjust-73 {
  max-width: 73% !important;
}

.width-adjust-74 {
  max-width: 74% !important;
}

.width-adjust-75 {
  max-width: 75% !important;
}

.width-adjust-76 {
  max-width: 76% !important;
}

.width-adjust-77 {
  max-width: 77% !important;
}

.width-adjust-78 {
  max-width: 78% !important;
}

.width-adjust-79 {
  max-width: 79% !important;
}

.width-adjust-80 {
  max-width: 80% !important;
}

.width-adjust-81 {
  max-width: 81% !important;
}

.width-adjust-82 {
  max-width: 82% !important;
}

.width-adjust-83 {
  max-width: 83% !important;
}

.width-adjust-84 {
  max-width: 84% !important;
}

.width-adjust-85 {
  max-width: 85% !important;
}

.width-adjust-86 {
  max-width: 86% !important;
}

.width-adjust-87 {
  max-width: 87% !important;
}

.width-adjust-88 {
  max-width: 88% !important;
}

.width-adjust-89 {
  max-width: 89% !important;
}

.width-adjust-90 {
  max-width: 90% !important;
}

.width-adjust-91 {
  max-width: 91% !important;
}

.width-adjust-92 {
  max-width: 92% !important;
}

.width-adjust-93 {
  max-width: 93% !important;
}

.width-adjust-94 {
  max-width: 94% !important;
}

.width-adjust-95 {
  max-width: 95% !important;
}

.width-adjust-96 {
  max-width: 96% !important;
}

.width-adjust-97 {
  max-width: 97% !important;
}

.width-adjust-98 {
  max-width: 98% !important;
}

.width-adjust-99 {
  max-width: 99% !important;
}

.width-adjust-100 {
  max-width: 100% !important;
}

.width-adjust-101 {
  max-width: 101% !important;
}

.width-adjust-102 {
  max-width: 102% !important;
}

.width-adjust-103 {
  max-width: 103% !important;
}

.width-adjust-104 {
  max-width: 104% !important;
}

.width-adjust-105 {
  max-width: 105% !important;
}

.width-adjust-106 {
  max-width: 106% !important;
}

.width-adjust-107 {
  max-width: 107% !important;
}

.width-adjust-108 {
  max-width: 108% !important;
}

.width-adjust-109 {
  max-width: 109% !important;
}

.width-adjust-110 {
  max-width: 110% !important;
}

.width-adjust-111 {
  max-width: 111% !important;
}

.width-adjust-112 {
  max-width: 112% !important;
}

.width-adjust-113 {
  max-width: 113% !important;
}

.width-adjust-114 {
  max-width: 114% !important;
}

.width-adjust-115 {
  max-width: 115% !important;
}

.width-adjust-116 {
  max-width: 116% !important;
}

.width-adjust-117 {
  max-width: 117% !important;
}

.width-adjust-118 {
  max-width: 118% !important;
}

.width-adjust-119 {
  max-width: 119% !important;
}

.width-adjust-120 {
  max-width: 120% !important;
}

.width-adjust-121 {
  max-width: 121% !important;
}

.width-adjust-122 {
  max-width: 122% !important;
}

.width-adjust-123 {
  max-width: 123% !important;
}

.width-adjust-124 {
  max-width: 124% !important;
}

.width-adjust-125 {
  max-width: 125% !important;
}

.width-adjust-126 {
  max-width: 126% !important;
}

.width-adjust-127 {
  max-width: 127% !important;
}

.width-adjust-128 {
  max-width: 128% !important;
}

.width-adjust-129 {
  max-width: 129% !important;
}

.width-adjust-130 {
  max-width: 130% !important;
}

.width-adjust-131 {
  max-width: 131% !important;
}

.width-adjust-132 {
  max-width: 132% !important;
}

.width-adjust-133 {
  max-width: 133% !important;
}

.width-adjust-134 {
  max-width: 134% !important;
}

.width-adjust-135 {
  max-width: 135% !important;
}

.width-adjust-136 {
  max-width: 136% !important;
}

.width-adjust-137 {
  max-width: 137% !important;
}

.width-adjust-138 {
  max-width: 138% !important;
}

.width-adjust-139 {
  max-width: 139% !important;
}

.width-adjust-140 {
  max-width: 140% !important;
}

.width-adjust-141 {
  max-width: 141% !important;
}

.width-adjust-142 {
  max-width: 142% !important;
}

.width-adjust-143 {
  max-width: 143% !important;
}

.width-adjust-144 {
  max-width: 144% !important;
}

.width-adjust-145 {
  max-width: 145% !important;
}

.width-adjust-146 {
  max-width: 146% !important;
}

.width-adjust-147 {
  max-width: 147% !important;
}

.width-adjust-148 {
  max-width: 148% !important;
}

.width-adjust-149 {
  max-width: 149% !important;
}

.width-adjust-150 {
  max-width: 150% !important;
}

.width-adjust-151 {
  max-width: 151% !important;
}

.width-adjust-152 {
  max-width: 152% !important;
}

.width-adjust-153 {
  max-width: 153% !important;
}

.width-adjust-154 {
  max-width: 154% !important;
}

.width-adjust-155 {
  max-width: 155% !important;
}

.width-adjust-156 {
  max-width: 156% !important;
}

.width-adjust-157 {
  max-width: 157% !important;
}

.width-adjust-158 {
  max-width: 158% !important;
}

.width-adjust-159 {
  max-width: 159% !important;
}

.width-adjust-160 {
  max-width: 160% !important;
}

.width-adjust-161 {
  max-width: 161% !important;
}

.width-adjust-162 {
  max-width: 162% !important;
}

.width-adjust-163 {
  max-width: 163% !important;
}

.width-adjust-164 {
  max-width: 164% !important;
}

.width-adjust-165 {
  max-width: 165% !important;
}

.width-adjust-166 {
  max-width: 166% !important;
}

.width-adjust-167 {
  max-width: 167% !important;
}

.width-adjust-168 {
  max-width: 168% !important;
}

.width-adjust-169 {
  max-width: 169% !important;
}

.width-adjust-170 {
  max-width: 170% !important;
}

.width-adjust-171 {
  max-width: 171% !important;
}

.width-adjust-172 {
  max-width: 172% !important;
}

.width-adjust-173 {
  max-width: 173% !important;
}

.width-adjust-174 {
  max-width: 174% !important;
}

.width-adjust-175 {
  max-width: 175% !important;
}

.width-adjust-176 {
  max-width: 176% !important;
}

.width-adjust-177 {
  max-width: 177% !important;
}

.width-adjust-178 {
  max-width: 178% !important;
}

.width-adjust-179 {
  max-width: 179% !important;
}

.width-adjust-180 {
  max-width: 180% !important;
}

.width-adjust-181 {
  max-width: 181% !important;
}

.width-adjust-182 {
  max-width: 182% !important;
}

.width-adjust-183 {
  max-width: 183% !important;
}

.width-adjust-184 {
  max-width: 184% !important;
}

.width-adjust-185 {
  max-width: 185% !important;
}

.width-adjust-186 {
  max-width: 186% !important;
}

.width-adjust-187 {
  max-width: 187% !important;
}

.width-adjust-188 {
  max-width: 188% !important;
}

.width-adjust-189 {
  max-width: 189% !important;
}

.width-adjust-190 {
  max-width: 190% !important;
}

.width-adjust-191 {
  max-width: 191% !important;
}

.width-adjust-192 {
  max-width: 192% !important;
}

.width-adjust-193 {
  max-width: 193% !important;
}

.width-adjust-194 {
  max-width: 194% !important;
}

.width-adjust-195 {
  max-width: 195% !important;
}

.width-adjust-196 {
  max-width: 196% !important;
}

.width-adjust-197 {
  max-width: 197% !important;
}

.width-adjust-198 {
  max-width: 198% !important;
}

.width-adjust-199 {
  max-width: 199% !important;
}

.width-adjust-200 {
  max-width: 200% !important;
}

.width-adjust-201 {
  max-width: 201% !important;
}

.width-adjust-202 {
  max-width: 202% !important;
}

.width-adjust-203 {
  max-width: 203% !important;
}

.width-adjust-204 {
  max-width: 204% !important;
}

.width-adjust-205 {
  max-width: 205% !important;
}

.width-adjust-206 {
  max-width: 206% !important;
}

.width-adjust-207 {
  max-width: 207% !important;
}

.width-adjust-208 {
  max-width: 208% !important;
}

.width-adjust-209 {
  max-width: 209% !important;
}

.width-adjust-210 {
  max-width: 210% !important;
}

.width-adjust-211 {
  max-width: 211% !important;
}

.width-adjust-212 {
  max-width: 212% !important;
}

.width-adjust-213 {
  max-width: 213% !important;
}

.width-adjust-214 {
  max-width: 214% !important;
}

.width-adjust-215 {
  max-width: 215% !important;
}

.width-adjust-216 {
  max-width: 216% !important;
}

.width-adjust-217 {
  max-width: 217% !important;
}

.width-adjust-218 {
  max-width: 218% !important;
}

.width-adjust-219 {
  max-width: 219% !important;
}

.width-adjust-220 {
  max-width: 220% !important;
}

.width-adjust-221 {
  max-width: 221% !important;
}

.width-adjust-222 {
  max-width: 222% !important;
}

.width-adjust-223 {
  max-width: 223% !important;
}

.width-adjust-224 {
  max-width: 224% !important;
}

.width-adjust-225 {
  max-width: 225% !important;
}

.width-adjust-226 {
  max-width: 226% !important;
}

.width-adjust-227 {
  max-width: 227% !important;
}

.width-adjust-228 {
  max-width: 228% !important;
}

.width-adjust-229 {
  max-width: 229% !important;
}

.width-adjust-230 {
  max-width: 230% !important;
}

.width-adjust-231 {
  max-width: 231% !important;
}

.width-adjust-232 {
  max-width: 232% !important;
}

.width-adjust-233 {
  max-width: 233% !important;
}

.width-adjust-234 {
  max-width: 234% !important;
}

.width-adjust-235 {
  max-width: 235% !important;
}

.width-adjust-236 {
  max-width: 236% !important;
}

.width-adjust-237 {
  max-width: 237% !important;
}

.width-adjust-238 {
  max-width: 238% !important;
}

.width-adjust-239 {
  max-width: 239% !important;
}

.width-adjust-240 {
  max-width: 240% !important;
}

.width-adjust-241 {
  max-width: 241% !important;
}

.width-adjust-242 {
  max-width: 242% !important;
}

.width-adjust-243 {
  max-width: 243% !important;
}

.width-adjust-244 {
  max-width: 244% !important;
}

.width-adjust-245 {
  max-width: 245% !important;
}

.width-adjust-246 {
  max-width: 246% !important;
}

.width-adjust-247 {
  max-width: 247% !important;
}

.width-adjust-248 {
  max-width: 248% !important;
}

.width-adjust-249 {
  max-width: 249% !important;
}

.width-adjust-250 {
  max-width: 250% !important;
}

.width-adjust-251 {
  max-width: 251% !important;
}

.width-adjust-252 {
  max-width: 252% !important;
}

.width-adjust-253 {
  max-width: 253% !important;
}

.width-adjust-254 {
  max-width: 254% !important;
}

.width-adjust-255 {
  max-width: 255% !important;
}

.width-adjust-256 {
  max-width: 256% !important;
}

.width-adjust-257 {
  max-width: 257% !important;
}

.width-adjust-258 {
  max-width: 258% !important;
}

.width-adjust-259 {
  max-width: 259% !important;
}

.width-adjust-260 {
  max-width: 260% !important;
}

.width-adjust-261 {
  max-width: 261% !important;
}

.width-adjust-262 {
  max-width: 262% !important;
}

.width-adjust-263 {
  max-width: 263% !important;
}

.width-adjust-264 {
  max-width: 264% !important;
}

.width-adjust-265 {
  max-width: 265% !important;
}

.width-adjust-266 {
  max-width: 266% !important;
}

.width-adjust-267 {
  max-width: 267% !important;
}

.width-adjust-268 {
  max-width: 268% !important;
}

.width-adjust-269 {
  max-width: 269% !important;
}

.width-adjust-270 {
  max-width: 270% !important;
}

.width-adjust-271 {
  max-width: 271% !important;
}

.width-adjust-272 {
  max-width: 272% !important;
}

.width-adjust-273 {
  max-width: 273% !important;
}

.width-adjust-274 {
  max-width: 274% !important;
}

.width-adjust-275 {
  max-width: 275% !important;
}

.width-adjust-276 {
  max-width: 276% !important;
}

.width-adjust-277 {
  max-width: 277% !important;
}

.width-adjust-278 {
  max-width: 278% !important;
}

.width-adjust-279 {
  max-width: 279% !important;
}

.width-adjust-280 {
  max-width: 280% !important;
}

.width-adjust-281 {
  max-width: 281% !important;
}

.width-adjust-282 {
  max-width: 282% !important;
}

.width-adjust-283 {
  max-width: 283% !important;
}

.width-adjust-284 {
  max-width: 284% !important;
}

.width-adjust-285 {
  max-width: 285% !important;
}

.width-adjust-286 {
  max-width: 286% !important;
}

.width-adjust-287 {
  max-width: 287% !important;
}

.width-adjust-288 {
  max-width: 288% !important;
}

.width-adjust-289 {
  max-width: 289% !important;
}

.width-adjust-290 {
  max-width: 290% !important;
}

.width-adjust-291 {
  max-width: 291% !important;
}

.width-adjust-292 {
  max-width: 292% !important;
}

.width-adjust-293 {
  max-width: 293% !important;
}

.width-adjust-294 {
  max-width: 294% !important;
}

.width-adjust-295 {
  max-width: 295% !important;
}

.width-adjust-296 {
  max-width: 296% !important;
}

.width-adjust-297 {
  max-width: 297% !important;
}

.width-adjust-298 {
  max-width: 298% !important;
}

.width-adjust-299 {
  max-width: 299% !important;
}

.width-adjust-300 {
  max-width: 300% !important;
}

.width-adjust-301 {
  max-width: 301% !important;
}

.width-adjust-302 {
  max-width: 302% !important;
}

.width-adjust-303 {
  max-width: 303% !important;
}

.width-adjust-304 {
  max-width: 304% !important;
}

.width-adjust-305 {
  max-width: 305% !important;
}

.width-adjust-306 {
  max-width: 306% !important;
}

.width-adjust-307 {
  max-width: 307% !important;
}

.width-adjust-308 {
  max-width: 308% !important;
}

.width-adjust-309 {
  max-width: 309% !important;
}

.width-adjust-310 {
  max-width: 310% !important;
}

.width-adjust-311 {
  max-width: 311% !important;
}

.width-adjust-312 {
  max-width: 312% !important;
}

.width-adjust-313 {
  max-width: 313% !important;
}

.width-adjust-314 {
  max-width: 314% !important;
}

.width-adjust-315 {
  max-width: 315% !important;
}

.width-adjust-316 {
  max-width: 316% !important;
}

.width-adjust-317 {
  max-width: 317% !important;
}

.width-adjust-318 {
  max-width: 318% !important;
}

.width-adjust-319 {
  max-width: 319% !important;
}

.width-adjust-320 {
  max-width: 320% !important;
}

.width-adjust-321 {
  max-width: 321% !important;
}

.width-adjust-322 {
  max-width: 322% !important;
}

.width-adjust-323 {
  max-width: 323% !important;
}

.width-adjust-324 {
  max-width: 324% !important;
}

.width-adjust-325 {
  max-width: 325% !important;
}

.width-adjust-326 {
  max-width: 326% !important;
}

.width-adjust-327 {
  max-width: 327% !important;
}

.width-adjust-328 {
  max-width: 328% !important;
}

.width-adjust-329 {
  max-width: 329% !important;
}

.width-adjust-330 {
  max-width: 330% !important;
}

.width-adjust-331 {
  max-width: 331% !important;
}

.width-adjust-332 {
  max-width: 332% !important;
}

.width-adjust-333 {
  max-width: 333% !important;
}

.width-adjust-334 {
  max-width: 334% !important;
}

.width-adjust-335 {
  max-width: 335% !important;
}

.width-adjust-336 {
  max-width: 336% !important;
}

.width-adjust-337 {
  max-width: 337% !important;
}

.width-adjust-338 {
  max-width: 338% !important;
}

.width-adjust-339 {
  max-width: 339% !important;
}

.width-adjust-340 {
  max-width: 340% !important;
}

.width-adjust-341 {
  max-width: 341% !important;
}

.width-adjust-342 {
  max-width: 342% !important;
}

.width-adjust-343 {
  max-width: 343% !important;
}

.width-adjust-344 {
  max-width: 344% !important;
}

.width-adjust-345 {
  max-width: 345% !important;
}

.width-adjust-346 {
  max-width: 346% !important;
}

.width-adjust-347 {
  max-width: 347% !important;
}

.width-adjust-348 {
  max-width: 348% !important;
}

.width-adjust-349 {
  max-width: 349% !important;
}

.width-adjust-350 {
  max-width: 350% !important;
}

.width-adjust-351 {
  max-width: 351% !important;
}

.width-adjust-352 {
  max-width: 352% !important;
}

.width-adjust-353 {
  max-width: 353% !important;
}

.width-adjust-354 {
  max-width: 354% !important;
}

.width-adjust-355 {
  max-width: 355% !important;
}

.width-adjust-356 {
  max-width: 356% !important;
}

.width-adjust-357 {
  max-width: 357% !important;
}

.width-adjust-358 {
  max-width: 358% !important;
}

.width-adjust-359 {
  max-width: 359% !important;
}

.width-adjust-360 {
  max-width: 360% !important;
}

.width-adjust-361 {
  max-width: 361% !important;
}

.width-adjust-362 {
  max-width: 362% !important;
}

.width-adjust-363 {
  max-width: 363% !important;
}

.width-adjust-364 {
  max-width: 364% !important;
}

.width-adjust-365 {
  max-width: 365% !important;
}

.width-adjust-366 {
  max-width: 366% !important;
}

.width-adjust-367 {
  max-width: 367% !important;
}

.width-adjust-368 {
  max-width: 368% !important;
}

.width-adjust-369 {
  max-width: 369% !important;
}

.width-adjust-370 {
  max-width: 370% !important;
}

.width-adjust-371 {
  max-width: 371% !important;
}

.width-adjust-372 {
  max-width: 372% !important;
}

.width-adjust-373 {
  max-width: 373% !important;
}

.width-adjust-374 {
  max-width: 374% !important;
}

.width-adjust-375 {
  max-width: 375% !important;
}

.width-adjust-376 {
  max-width: 376% !important;
}

.width-adjust-377 {
  max-width: 377% !important;
}

.width-adjust-378 {
  max-width: 378% !important;
}

.width-adjust-379 {
  max-width: 379% !important;
}

.width-adjust-380 {
  max-width: 380% !important;
}

.width-adjust-381 {
  max-width: 381% !important;
}

.width-adjust-382 {
  max-width: 382% !important;
}

.width-adjust-383 {
  max-width: 383% !important;
}

.width-adjust-384 {
  max-width: 384% !important;
}

.width-adjust-385 {
  max-width: 385% !important;
}

.width-adjust-386 {
  max-width: 386% !important;
}

.width-adjust-387 {
  max-width: 387% !important;
}

.width-adjust-388 {
  max-width: 388% !important;
}

.width-adjust-389 {
  max-width: 389% !important;
}

.width-adjust-390 {
  max-width: 390% !important;
}

.width-adjust-391 {
  max-width: 391% !important;
}

.width-adjust-392 {
  max-width: 392% !important;
}

.width-adjust-393 {
  max-width: 393% !important;
}

.width-adjust-394 {
  max-width: 394% !important;
}

.width-adjust-395 {
  max-width: 395% !important;
}

.width-adjust-396 {
  max-width: 396% !important;
}

.width-adjust-397 {
  max-width: 397% !important;
}

.width-adjust-398 {
  max-width: 398% !important;
}

.width-adjust-399 {
  max-width: 399% !important;
}

.width-adjust-400 {
  max-width: 400% !important;
}

.width-adjust-401 {
  max-width: 401% !important;
}

.width-adjust-402 {
  max-width: 402% !important;
}

.width-adjust-403 {
  max-width: 403% !important;
}

.width-adjust-404 {
  max-width: 404% !important;
}

.width-adjust-405 {
  max-width: 405% !important;
}

.width-adjust-406 {
  max-width: 406% !important;
}

.width-adjust-407 {
  max-width: 407% !important;
}

.width-adjust-408 {
  max-width: 408% !important;
}

.width-adjust-409 {
  max-width: 409% !important;
}

.width-adjust-410 {
  max-width: 410% !important;
}

.width-adjust-411 {
  max-width: 411% !important;
}

.width-adjust-412 {
  max-width: 412% !important;
}

.width-adjust-413 {
  max-width: 413% !important;
}

.width-adjust-414 {
  max-width: 414% !important;
}

.width-adjust-415 {
  max-width: 415% !important;
}

.width-adjust-416 {
  max-width: 416% !important;
}

.width-adjust-417 {
  max-width: 417% !important;
}

.width-adjust-418 {
  max-width: 418% !important;
}

.width-adjust-419 {
  max-width: 419% !important;
}

.width-adjust-420 {
  max-width: 420% !important;
}

.width-adjust-421 {
  max-width: 421% !important;
}

.width-adjust-422 {
  max-width: 422% !important;
}

.width-adjust-423 {
  max-width: 423% !important;
}

.width-adjust-424 {
  max-width: 424% !important;
}

.width-adjust-425 {
  max-width: 425% !important;
}

.width-adjust-426 {
  max-width: 426% !important;
}

.width-adjust-427 {
  max-width: 427% !important;
}

.width-adjust-428 {
  max-width: 428% !important;
}

.width-adjust-429 {
  max-width: 429% !important;
}

.width-adjust-430 {
  max-width: 430% !important;
}

.width-adjust-431 {
  max-width: 431% !important;
}

.width-adjust-432 {
  max-width: 432% !important;
}

.width-adjust-433 {
  max-width: 433% !important;
}

.width-adjust-434 {
  max-width: 434% !important;
}

.width-adjust-435 {
  max-width: 435% !important;
}

.width-adjust-436 {
  max-width: 436% !important;
}

.width-adjust-437 {
  max-width: 437% !important;
}

.width-adjust-438 {
  max-width: 438% !important;
}

.width-adjust-439 {
  max-width: 439% !important;
}

.width-adjust-440 {
  max-width: 440% !important;
}

.width-adjust-441 {
  max-width: 441% !important;
}

.width-adjust-442 {
  max-width: 442% !important;
}

.width-adjust-443 {
  max-width: 443% !important;
}

.width-adjust-444 {
  max-width: 444% !important;
}

.width-adjust-445 {
  max-width: 445% !important;
}

.width-adjust-446 {
  max-width: 446% !important;
}

.width-adjust-447 {
  max-width: 447% !important;
}

.width-adjust-448 {
  max-width: 448% !important;
}

.width-adjust-449 {
  max-width: 449% !important;
}

.width-adjust-450 {
  max-width: 450% !important;
}

.width-adjust-451 {
  max-width: 451% !important;
}

.width-adjust-452 {
  max-width: 452% !important;
}

.width-adjust-453 {
  max-width: 453% !important;
}

.width-adjust-454 {
  max-width: 454% !important;
}

.width-adjust-455 {
  max-width: 455% !important;
}

.width-adjust-456 {
  max-width: 456% !important;
}

.width-adjust-457 {
  max-width: 457% !important;
}

.width-adjust-458 {
  max-width: 458% !important;
}

.width-adjust-459 {
  max-width: 459% !important;
}

.width-adjust-460 {
  max-width: 460% !important;
}

.width-adjust-461 {
  max-width: 461% !important;
}

.width-adjust-462 {
  max-width: 462% !important;
}

.width-adjust-463 {
  max-width: 463% !important;
}

.width-adjust-464 {
  max-width: 464% !important;
}

.width-adjust-465 {
  max-width: 465% !important;
}

.width-adjust-466 {
  max-width: 466% !important;
}

.width-adjust-467 {
  max-width: 467% !important;
}

.width-adjust-468 {
  max-width: 468% !important;
}

.width-adjust-469 {
  max-width: 469% !important;
}

.width-adjust-470 {
  max-width: 470% !important;
}

.width-adjust-471 {
  max-width: 471% !important;
}

.width-adjust-472 {
  max-width: 472% !important;
}

.width-adjust-473 {
  max-width: 473% !important;
}

.width-adjust-474 {
  max-width: 474% !important;
}

.width-adjust-475 {
  max-width: 475% !important;
}

.width-adjust-476 {
  max-width: 476% !important;
}

.width-adjust-477 {
  max-width: 477% !important;
}

.width-adjust-478 {
  max-width: 478% !important;
}

.width-adjust-479 {
  max-width: 479% !important;
}

.width-adjust-480 {
  max-width: 480% !important;
}

.width-adjust-481 {
  max-width: 481% !important;
}

.width-adjust-482 {
  max-width: 482% !important;
}

.width-adjust-483 {
  max-width: 483% !important;
}

.width-adjust-484 {
  max-width: 484% !important;
}

.width-adjust-485 {
  max-width: 485% !important;
}

.width-adjust-486 {
  max-width: 486% !important;
}

.width-adjust-487 {
  max-width: 487% !important;
}

.width-adjust-488 {
  max-width: 488% !important;
}

.width-adjust-489 {
  max-width: 489% !important;
}

.width-adjust-490 {
  max-width: 490% !important;
}

.width-adjust-491 {
  max-width: 491% !important;
}

.width-adjust-492 {
  max-width: 492% !important;
}

.width-adjust-493 {
  max-width: 493% !important;
}

.width-adjust-494 {
  max-width: 494% !important;
}

.width-adjust-495 {
  max-width: 495% !important;
}

.width-adjust-496 {
  max-width: 496% !important;
}

.width-adjust-497 {
  max-width: 497% !important;
}

.width-adjust-498 {
  max-width: 498% !important;
}

.width-adjust-499 {
  max-width: 499% !important;
}

.width-adjust-500 {
  max-width: 500% !important;
}

.width-adjust-501 {
  max-width: 501% !important;
}

.width-adjust-502 {
  max-width: 502% !important;
}

.width-adjust-503 {
  max-width: 503% !important;
}

.width-adjust-504 {
  max-width: 504% !important;
}

.width-adjust-505 {
  max-width: 505% !important;
}

.width-adjust-506 {
  max-width: 506% !important;
}

.width-adjust-507 {
  max-width: 507% !important;
}

.width-adjust-508 {
  max-width: 508% !important;
}

.width-adjust-509 {
  max-width: 509% !important;
}

.width-adjust-510 {
  max-width: 510% !important;
}

.width-adjust-511 {
  max-width: 511% !important;
}

.width-adjust-512 {
  max-width: 512% !important;
}

.width-adjust-513 {
  max-width: 513% !important;
}

.width-adjust-514 {
  max-width: 514% !important;
}

.width-adjust-515 {
  max-width: 515% !important;
}

.width-adjust-516 {
  max-width: 516% !important;
}

.width-adjust-517 {
  max-width: 517% !important;
}

.width-adjust-518 {
  max-width: 518% !important;
}

.width-adjust-519 {
  max-width: 519% !important;
}

.width-adjust-520 {
  max-width: 520% !important;
}

.width-adjust-521 {
  max-width: 521% !important;
}

.width-adjust-522 {
  max-width: 522% !important;
}

.width-adjust-523 {
  max-width: 523% !important;
}

.width-adjust-524 {
  max-width: 524% !important;
}

.width-adjust-525 {
  max-width: 525% !important;
}

.width-adjust-526 {
  max-width: 526% !important;
}

.width-adjust-527 {
  max-width: 527% !important;
}

.width-adjust-528 {
  max-width: 528% !important;
}

.width-adjust-529 {
  max-width: 529% !important;
}

.width-adjust-530 {
  max-width: 530% !important;
}

.width-adjust-531 {
  max-width: 531% !important;
}

.width-adjust-532 {
  max-width: 532% !important;
}

.width-adjust-533 {
  max-width: 533% !important;
}

.width-adjust-534 {
  max-width: 534% !important;
}

.width-adjust-535 {
  max-width: 535% !important;
}

.width-adjust-536 {
  max-width: 536% !important;
}

.width-adjust-537 {
  max-width: 537% !important;
}

.width-adjust-538 {
  max-width: 538% !important;
}

.width-adjust-539 {
  max-width: 539% !important;
}

.width-adjust-540 {
  max-width: 540% !important;
}

.width-adjust-541 {
  max-width: 541% !important;
}

.width-adjust-542 {
  max-width: 542% !important;
}

.width-adjust-543 {
  max-width: 543% !important;
}

.width-adjust-544 {
  max-width: 544% !important;
}

.width-adjust-545 {
  max-width: 545% !important;
}

.width-adjust-546 {
  max-width: 546% !important;
}

.width-adjust-547 {
  max-width: 547% !important;
}

.width-adjust-548 {
  max-width: 548% !important;
}

.width-adjust-549 {
  max-width: 549% !important;
}

.width-adjust-550 {
  max-width: 550% !important;
}

.width-adjust-551 {
  max-width: 551% !important;
}

.width-adjust-552 {
  max-width: 552% !important;
}

.width-adjust-553 {
  max-width: 553% !important;
}

.width-adjust-554 {
  max-width: 554% !important;
}

.width-adjust-555 {
  max-width: 555% !important;
}

.width-adjust-556 {
  max-width: 556% !important;
}

.width-adjust-557 {
  max-width: 557% !important;
}

.width-adjust-558 {
  max-width: 558% !important;
}

.width-adjust-559 {
  max-width: 559% !important;
}

.width-adjust-560 {
  max-width: 560% !important;
}

.width-adjust-561 {
  max-width: 561% !important;
}

.width-adjust-562 {
  max-width: 562% !important;
}

.width-adjust-563 {
  max-width: 563% !important;
}

.width-adjust-564 {
  max-width: 564% !important;
}

.width-adjust-565 {
  max-width: 565% !important;
}

.width-adjust-566 {
  max-width: 566% !important;
}

.width-adjust-567 {
  max-width: 567% !important;
}

.width-adjust-568 {
  max-width: 568% !important;
}

.width-adjust-569 {
  max-width: 569% !important;
}

.width-adjust-570 {
  max-width: 570% !important;
}

.width-adjust-571 {
  max-width: 571% !important;
}

.width-adjust-572 {
  max-width: 572% !important;
}

.width-adjust-573 {
  max-width: 573% !important;
}

.width-adjust-574 {
  max-width: 574% !important;
}

.width-adjust-575 {
  max-width: 575% !important;
}

.width-adjust-576 {
  max-width: 576% !important;
}

.width-adjust-577 {
  max-width: 577% !important;
}

.width-adjust-578 {
  max-width: 578% !important;
}

.width-adjust-579 {
  max-width: 579% !important;
}

.width-adjust-580 {
  max-width: 580% !important;
}

.width-adjust-581 {
  max-width: 581% !important;
}

.width-adjust-582 {
  max-width: 582% !important;
}

.width-adjust-583 {
  max-width: 583% !important;
}

.width-adjust-584 {
  max-width: 584% !important;
}

.width-adjust-585 {
  max-width: 585% !important;
}

.width-adjust-586 {
  max-width: 586% !important;
}

.width-adjust-587 {
  max-width: 587% !important;
}

.width-adjust-588 {
  max-width: 588% !important;
}

.width-adjust-589 {
  max-width: 589% !important;
}

.width-adjust-590 {
  max-width: 590% !important;
}

.width-adjust-591 {
  max-width: 591% !important;
}

.width-adjust-592 {
  max-width: 592% !important;
}

.width-adjust-593 {
  max-width: 593% !important;
}

.width-adjust-594 {
  max-width: 594% !important;
}

.width-adjust-595 {
  max-width: 595% !important;
}

.width-adjust-596 {
  max-width: 596% !important;
}

.width-adjust-597 {
  max-width: 597% !important;
}

.width-adjust-598 {
  max-width: 598% !important;
}

.width-adjust-599 {
  max-width: 599% !important;
}

.width-adjust-600 {
  max-width: 600% !important;
}

.width-adjust-601 {
  max-width: 601% !important;
}

.width-adjust-602 {
  max-width: 602% !important;
}

.width-adjust-603 {
  max-width: 603% !important;
}

.width-adjust-604 {
  max-width: 604% !important;
}

.width-adjust-605 {
  max-width: 605% !important;
}

.width-adjust-606 {
  max-width: 606% !important;
}

.width-adjust-607 {
  max-width: 607% !important;
}

.width-adjust-608 {
  max-width: 608% !important;
}

.width-adjust-609 {
  max-width: 609% !important;
}

.width-adjust-610 {
  max-width: 610% !important;
}

.width-adjust-611 {
  max-width: 611% !important;
}

.width-adjust-612 {
  max-width: 612% !important;
}

.width-adjust-613 {
  max-width: 613% !important;
}

.width-adjust-614 {
  max-width: 614% !important;
}

.width-adjust-615 {
  max-width: 615% !important;
}

.width-adjust-616 {
  max-width: 616% !important;
}

.width-adjust-617 {
  max-width: 617% !important;
}

.width-adjust-618 {
  max-width: 618% !important;
}

.width-adjust-619 {
  max-width: 619% !important;
}

.width-adjust-620 {
  max-width: 620% !important;
}

.width-adjust-621 {
  max-width: 621% !important;
}

.width-adjust-622 {
  max-width: 622% !important;
}

.width-adjust-623 {
  max-width: 623% !important;
}

.width-adjust-624 {
  max-width: 624% !important;
}

.width-adjust-625 {
  max-width: 625% !important;
}

.width-adjust-626 {
  max-width: 626% !important;
}

.width-adjust-627 {
  max-width: 627% !important;
}

.width-adjust-628 {
  max-width: 628% !important;
}

.width-adjust-629 {
  max-width: 629% !important;
}

.width-adjust-630 {
  max-width: 630% !important;
}

.width-adjust-631 {
  max-width: 631% !important;
}

.width-adjust-632 {
  max-width: 632% !important;
}

.width-adjust-633 {
  max-width: 633% !important;
}

.width-adjust-634 {
  max-width: 634% !important;
}

.width-adjust-635 {
  max-width: 635% !important;
}

.width-adjust-636 {
  max-width: 636% !important;
}

.width-adjust-637 {
  max-width: 637% !important;
}

.width-adjust-638 {
  max-width: 638% !important;
}

.width-adjust-639 {
  max-width: 639% !important;
}

.width-adjust-640 {
  max-width: 640% !important;
}

.width-adjust-641 {
  max-width: 641% !important;
}

.width-adjust-642 {
  max-width: 642% !important;
}

.width-adjust-643 {
  max-width: 643% !important;
}

.width-adjust-644 {
  max-width: 644% !important;
}

.width-adjust-645 {
  max-width: 645% !important;
}

.width-adjust-646 {
  max-width: 646% !important;
}

.width-adjust-647 {
  max-width: 647% !important;
}

.width-adjust-648 {
  max-width: 648% !important;
}

.width-adjust-649 {
  max-width: 649% !important;
}

.width-adjust-650 {
  max-width: 650% !important;
}

.width-adjust-651 {
  max-width: 651% !important;
}

.width-adjust-652 {
  max-width: 652% !important;
}

.width-adjust-653 {
  max-width: 653% !important;
}

.width-adjust-654 {
  max-width: 654% !important;
}

.width-adjust-655 {
  max-width: 655% !important;
}

.width-adjust-656 {
  max-width: 656% !important;
}

.width-adjust-657 {
  max-width: 657% !important;
}

.width-adjust-658 {
  max-width: 658% !important;
}

.width-adjust-659 {
  max-width: 659% !important;
}

.width-adjust-660 {
  max-width: 660% !important;
}

.width-adjust-661 {
  max-width: 661% !important;
}

.width-adjust-662 {
  max-width: 662% !important;
}

.width-adjust-663 {
  max-width: 663% !important;
}

.width-adjust-664 {
  max-width: 664% !important;
}

.width-adjust-665 {
  max-width: 665% !important;
}

.width-adjust-666 {
  max-width: 666% !important;
}

.width-adjust-667 {
  max-width: 667% !important;
}

.width-adjust-668 {
  max-width: 668% !important;
}

.width-adjust-669 {
  max-width: 669% !important;
}

.width-adjust-670 {
  max-width: 670% !important;
}

.width-adjust-671 {
  max-width: 671% !important;
}

.width-adjust-672 {
  max-width: 672% !important;
}

.width-adjust-673 {
  max-width: 673% !important;
}

.width-adjust-674 {
  max-width: 674% !important;
}

.width-adjust-675 {
  max-width: 675% !important;
}

.width-adjust-676 {
  max-width: 676% !important;
}

.width-adjust-677 {
  max-width: 677% !important;
}

.width-adjust-678 {
  max-width: 678% !important;
}

.width-adjust-679 {
  max-width: 679% !important;
}

.width-adjust-680 {
  max-width: 680% !important;
}

.width-adjust-681 {
  max-width: 681% !important;
}

.width-adjust-682 {
  max-width: 682% !important;
}

.width-adjust-683 {
  max-width: 683% !important;
}

.width-adjust-684 {
  max-width: 684% !important;
}

.width-adjust-685 {
  max-width: 685% !important;
}

.width-adjust-686 {
  max-width: 686% !important;
}

.width-adjust-687 {
  max-width: 687% !important;
}

.width-adjust-688 {
  max-width: 688% !important;
}

.width-adjust-689 {
  max-width: 689% !important;
}

.width-adjust-690 {
  max-width: 690% !important;
}

.width-adjust-691 {
  max-width: 691% !important;
}

.width-adjust-692 {
  max-width: 692% !important;
}

.width-adjust-693 {
  max-width: 693% !important;
}

.width-adjust-694 {
  max-width: 694% !important;
}

.width-adjust-695 {
  max-width: 695% !important;
}

.width-adjust-696 {
  max-width: 696% !important;
}

.width-adjust-697 {
  max-width: 697% !important;
}

.width-adjust-698 {
  max-width: 698% !important;
}

.width-adjust-699 {
  max-width: 699% !important;
}

.width-adjust-700 {
  max-width: 700% !important;
}

.width-adjust-701 {
  max-width: 701% !important;
}

.width-adjust-702 {
  max-width: 702% !important;
}

.width-adjust-703 {
  max-width: 703% !important;
}

.width-adjust-704 {
  max-width: 704% !important;
}

.width-adjust-705 {
  max-width: 705% !important;
}

.width-adjust-706 {
  max-width: 706% !important;
}

.width-adjust-707 {
  max-width: 707% !important;
}

.width-adjust-708 {
  max-width: 708% !important;
}

.width-adjust-709 {
  max-width: 709% !important;
}

.width-adjust-710 {
  max-width: 710% !important;
}

.width-adjust-711 {
  max-width: 711% !important;
}

.width-adjust-712 {
  max-width: 712% !important;
}

.width-adjust-713 {
  max-width: 713% !important;
}

.width-adjust-714 {
  max-width: 714% !important;
}

.width-adjust-715 {
  max-width: 715% !important;
}

.width-adjust-716 {
  max-width: 716% !important;
}

.width-adjust-717 {
  max-width: 717% !important;
}

.width-adjust-718 {
  max-width: 718% !important;
}

.width-adjust-719 {
  max-width: 719% !important;
}

.width-adjust-720 {
  max-width: 720% !important;
}

.width-adjust-721 {
  max-width: 721% !important;
}

.width-adjust-722 {
  max-width: 722% !important;
}

.width-adjust-723 {
  max-width: 723% !important;
}

.width-adjust-724 {
  max-width: 724% !important;
}

.width-adjust-725 {
  max-width: 725% !important;
}

.width-adjust-726 {
  max-width: 726% !important;
}

.width-adjust-727 {
  max-width: 727% !important;
}

.width-adjust-728 {
  max-width: 728% !important;
}

.width-adjust-729 {
  max-width: 729% !important;
}

.width-adjust-730 {
  max-width: 730% !important;
}

.width-adjust-731 {
  max-width: 731% !important;
}

.width-adjust-732 {
  max-width: 732% !important;
}

.width-adjust-733 {
  max-width: 733% !important;
}

.width-adjust-734 {
  max-width: 734% !important;
}

.width-adjust-735 {
  max-width: 735% !important;
}

.width-adjust-736 {
  max-width: 736% !important;
}

.width-adjust-737 {
  max-width: 737% !important;
}

.width-adjust-738 {
  max-width: 738% !important;
}

.width-adjust-739 {
  max-width: 739% !important;
}

.width-adjust-740 {
  max-width: 740% !important;
}

.width-adjust-741 {
  max-width: 741% !important;
}

.width-adjust-742 {
  max-width: 742% !important;
}

.width-adjust-743 {
  max-width: 743% !important;
}

.width-adjust-744 {
  max-width: 744% !important;
}

.width-adjust-745 {
  max-width: 745% !important;
}

.width-adjust-746 {
  max-width: 746% !important;
}

.width-adjust-747 {
  max-width: 747% !important;
}

.width-adjust-748 {
  max-width: 748% !important;
}

.width-adjust-749 {
  max-width: 749% !important;
}

.width-adjust-750 {
  max-width: 750% !important;
}

.width-adjust-751 {
  max-width: 751% !important;
}

.width-adjust-752 {
  max-width: 752% !important;
}

.width-adjust-753 {
  max-width: 753% !important;
}

.width-adjust-754 {
  max-width: 754% !important;
}

.width-adjust-755 {
  max-width: 755% !important;
}

.width-adjust-756 {
  max-width: 756% !important;
}

.width-adjust-757 {
  max-width: 757% !important;
}

.width-adjust-758 {
  max-width: 758% !important;
}

.width-adjust-759 {
  max-width: 759% !important;
}

.width-adjust-760 {
  max-width: 760% !important;
}

.width-adjust-761 {
  max-width: 761% !important;
}

.width-adjust-762 {
  max-width: 762% !important;
}

.width-adjust-763 {
  max-width: 763% !important;
}

.width-adjust-764 {
  max-width: 764% !important;
}

.width-adjust-765 {
  max-width: 765% !important;
}

.width-adjust-766 {
  max-width: 766% !important;
}

.width-adjust-767 {
  max-width: 767% !important;
}

.width-adjust-768 {
  max-width: 768% !important;
}

.width-adjust-769 {
  max-width: 769% !important;
}

.width-adjust-770 {
  max-width: 770% !important;
}

.width-adjust-771 {
  max-width: 771% !important;
}

.width-adjust-772 {
  max-width: 772% !important;
}

.width-adjust-773 {
  max-width: 773% !important;
}

.width-adjust-774 {
  max-width: 774% !important;
}

.width-adjust-775 {
  max-width: 775% !important;
}

.width-adjust-776 {
  max-width: 776% !important;
}

.width-adjust-777 {
  max-width: 777% !important;
}

.width-adjust-778 {
  max-width: 778% !important;
}

.width-adjust-779 {
  max-width: 779% !important;
}

.width-adjust-780 {
  max-width: 780% !important;
}

.width-adjust-781 {
  max-width: 781% !important;
}

.width-adjust-782 {
  max-width: 782% !important;
}

.width-adjust-783 {
  max-width: 783% !important;
}

.width-adjust-784 {
  max-width: 784% !important;
}

.width-adjust-785 {
  max-width: 785% !important;
}

.width-adjust-786 {
  max-width: 786% !important;
}

.width-adjust-787 {
  max-width: 787% !important;
}

.width-adjust-788 {
  max-width: 788% !important;
}

.width-adjust-789 {
  max-width: 789% !important;
}

.width-adjust-790 {
  max-width: 790% !important;
}

.width-adjust-791 {
  max-width: 791% !important;
}

.width-adjust-792 {
  max-width: 792% !important;
}

.width-adjust-793 {
  max-width: 793% !important;
}

.width-adjust-794 {
  max-width: 794% !important;
}

.width-adjust-795 {
  max-width: 795% !important;
}

.width-adjust-796 {
  max-width: 796% !important;
}

.width-adjust-797 {
  max-width: 797% !important;
}

.width-adjust-798 {
  max-width: 798% !important;
}

.width-adjust-799 {
  max-width: 799% !important;
}

.width-adjust-800 {
  max-width: 800% !important;
}

.width-adjust-801 {
  max-width: 801% !important;
}

.width-adjust-802 {
  max-width: 802% !important;
}

.width-adjust-803 {
  max-width: 803% !important;
}

.width-adjust-804 {
  max-width: 804% !important;
}

.width-adjust-805 {
  max-width: 805% !important;
}

.width-adjust-806 {
  max-width: 806% !important;
}

.width-adjust-807 {
  max-width: 807% !important;
}

.width-adjust-808 {
  max-width: 808% !important;
}

.width-adjust-809 {
  max-width: 809% !important;
}

.width-adjust-810 {
  max-width: 810% !important;
}

.width-adjust-811 {
  max-width: 811% !important;
}

.width-adjust-812 {
  max-width: 812% !important;
}

.width-adjust-813 {
  max-width: 813% !important;
}

.width-adjust-814 {
  max-width: 814% !important;
}

.width-adjust-815 {
  max-width: 815% !important;
}

.width-adjust-816 {
  max-width: 816% !important;
}

.width-adjust-817 {
  max-width: 817% !important;
}

.width-adjust-818 {
  max-width: 818% !important;
}

.width-adjust-819 {
  max-width: 819% !important;
}

.width-adjust-820 {
  max-width: 820% !important;
}

.width-adjust-821 {
  max-width: 821% !important;
}

.width-adjust-822 {
  max-width: 822% !important;
}

.width-adjust-823 {
  max-width: 823% !important;
}

.width-adjust-824 {
  max-width: 824% !important;
}

.width-adjust-825 {
  max-width: 825% !important;
}

.width-adjust-826 {
  max-width: 826% !important;
}

.width-adjust-827 {
  max-width: 827% !important;
}

.width-adjust-828 {
  max-width: 828% !important;
}

.width-adjust-829 {
  max-width: 829% !important;
}

.width-adjust-830 {
  max-width: 830% !important;
}

.width-adjust-831 {
  max-width: 831% !important;
}

.width-adjust-832 {
  max-width: 832% !important;
}

.width-adjust-833 {
  max-width: 833% !important;
}

.width-adjust-834 {
  max-width: 834% !important;
}

.width-adjust-835 {
  max-width: 835% !important;
}

.width-adjust-836 {
  max-width: 836% !important;
}

.width-adjust-837 {
  max-width: 837% !important;
}

.width-adjust-838 {
  max-width: 838% !important;
}

.width-adjust-839 {
  max-width: 839% !important;
}

.width-adjust-840 {
  max-width: 840% !important;
}

.width-adjust-841 {
  max-width: 841% !important;
}

.width-adjust-842 {
  max-width: 842% !important;
}

.width-adjust-843 {
  max-width: 843% !important;
}

.width-adjust-844 {
  max-width: 844% !important;
}

.width-adjust-845 {
  max-width: 845% !important;
}

.width-adjust-846 {
  max-width: 846% !important;
}

.width-adjust-847 {
  max-width: 847% !important;
}

.width-adjust-848 {
  max-width: 848% !important;
}

.width-adjust-849 {
  max-width: 849% !important;
}

.width-adjust-850 {
  max-width: 850% !important;
}

.width-adjust-851 {
  max-width: 851% !important;
}

.width-adjust-852 {
  max-width: 852% !important;
}

.width-adjust-853 {
  max-width: 853% !important;
}

.width-adjust-854 {
  max-width: 854% !important;
}

.width-adjust-855 {
  max-width: 855% !important;
}

.width-adjust-856 {
  max-width: 856% !important;
}

.width-adjust-857 {
  max-width: 857% !important;
}

.width-adjust-858 {
  max-width: 858% !important;
}

.width-adjust-859 {
  max-width: 859% !important;
}

.width-adjust-860 {
  max-width: 860% !important;
}

.width-adjust-861 {
  max-width: 861% !important;
}

.width-adjust-862 {
  max-width: 862% !important;
}

.width-adjust-863 {
  max-width: 863% !important;
}

.width-adjust-864 {
  max-width: 864% !important;
}

.width-adjust-865 {
  max-width: 865% !important;
}

.width-adjust-866 {
  max-width: 866% !important;
}

.width-adjust-867 {
  max-width: 867% !important;
}

.width-adjust-868 {
  max-width: 868% !important;
}

.width-adjust-869 {
  max-width: 869% !important;
}

.width-adjust-870 {
  max-width: 870% !important;
}

.width-adjust-871 {
  max-width: 871% !important;
}

.width-adjust-872 {
  max-width: 872% !important;
}

.width-adjust-873 {
  max-width: 873% !important;
}

.width-adjust-874 {
  max-width: 874% !important;
}

.width-adjust-875 {
  max-width: 875% !important;
}

.width-adjust-876 {
  max-width: 876% !important;
}

.width-adjust-877 {
  max-width: 877% !important;
}

.width-adjust-878 {
  max-width: 878% !important;
}

.width-adjust-879 {
  max-width: 879% !important;
}

.width-adjust-880 {
  max-width: 880% !important;
}

.width-adjust-881 {
  max-width: 881% !important;
}

.width-adjust-882 {
  max-width: 882% !important;
}

.width-adjust-883 {
  max-width: 883% !important;
}

.width-adjust-884 {
  max-width: 884% !important;
}

.width-adjust-885 {
  max-width: 885% !important;
}

.width-adjust-886 {
  max-width: 886% !important;
}

.width-adjust-887 {
  max-width: 887% !important;
}

.width-adjust-888 {
  max-width: 888% !important;
}

.width-adjust-889 {
  max-width: 889% !important;
}

.width-adjust-890 {
  max-width: 890% !important;
}

.width-adjust-891 {
  max-width: 891% !important;
}

.width-adjust-892 {
  max-width: 892% !important;
}

.width-adjust-893 {
  max-width: 893% !important;
}

.width-adjust-894 {
  max-width: 894% !important;
}

.width-adjust-895 {
  max-width: 895% !important;
}

.width-adjust-896 {
  max-width: 896% !important;
}

.width-adjust-897 {
  max-width: 897% !important;
}

.width-adjust-898 {
  max-width: 898% !important;
}

.width-adjust-899 {
  max-width: 899% !important;
}

.width-adjust-900 {
  max-width: 900% !important;
}

.width-adjust-901 {
  max-width: 901% !important;
}

.width-adjust-902 {
  max-width: 902% !important;
}

.width-adjust-903 {
  max-width: 903% !important;
}

.width-adjust-904 {
  max-width: 904% !important;
}

.width-adjust-905 {
  max-width: 905% !important;
}

.width-adjust-906 {
  max-width: 906% !important;
}

.width-adjust-907 {
  max-width: 907% !important;
}

.width-adjust-908 {
  max-width: 908% !important;
}

.width-adjust-909 {
  max-width: 909% !important;
}

.width-adjust-910 {
  max-width: 910% !important;
}

.width-adjust-911 {
  max-width: 911% !important;
}

.width-adjust-912 {
  max-width: 912% !important;
}

.width-adjust-913 {
  max-width: 913% !important;
}

.width-adjust-914 {
  max-width: 914% !important;
}

.width-adjust-915 {
  max-width: 915% !important;
}

.width-adjust-916 {
  max-width: 916% !important;
}

.width-adjust-917 {
  max-width: 917% !important;
}

.width-adjust-918 {
  max-width: 918% !important;
}

.width-adjust-919 {
  max-width: 919% !important;
}

.width-adjust-920 {
  max-width: 920% !important;
}

.width-adjust-921 {
  max-width: 921% !important;
}

.width-adjust-922 {
  max-width: 922% !important;
}

.width-adjust-923 {
  max-width: 923% !important;
}

.width-adjust-924 {
  max-width: 924% !important;
}

.width-adjust-925 {
  max-width: 925% !important;
}

.width-adjust-926 {
  max-width: 926% !important;
}

.width-adjust-927 {
  max-width: 927% !important;
}

.width-adjust-928 {
  max-width: 928% !important;
}

.width-adjust-929 {
  max-width: 929% !important;
}

.width-adjust-930 {
  max-width: 930% !important;
}

.width-adjust-931 {
  max-width: 931% !important;
}

.width-adjust-932 {
  max-width: 932% !important;
}

.width-adjust-933 {
  max-width: 933% !important;
}

.width-adjust-934 {
  max-width: 934% !important;
}

.width-adjust-935 {
  max-width: 935% !important;
}

.width-adjust-936 {
  max-width: 936% !important;
}

.width-adjust-937 {
  max-width: 937% !important;
}

.width-adjust-938 {
  max-width: 938% !important;
}

.width-adjust-939 {
  max-width: 939% !important;
}

.width-adjust-940 {
  max-width: 940% !important;
}

.width-adjust-941 {
  max-width: 941% !important;
}

.width-adjust-942 {
  max-width: 942% !important;
}

.width-adjust-943 {
  max-width: 943% !important;
}

.width-adjust-944 {
  max-width: 944% !important;
}

.width-adjust-945 {
  max-width: 945% !important;
}

.width-adjust-946 {
  max-width: 946% !important;
}

.width-adjust-947 {
  max-width: 947% !important;
}

.width-adjust-948 {
  max-width: 948% !important;
}

.width-adjust-949 {
  max-width: 949% !important;
}

.width-adjust-950 {
  max-width: 950% !important;
}

.width-adjust-951 {
  max-width: 951% !important;
}

.width-adjust-952 {
  max-width: 952% !important;
}

.width-adjust-953 {
  max-width: 953% !important;
}

.width-adjust-954 {
  max-width: 954% !important;
}

.width-adjust-955 {
  max-width: 955% !important;
}

.width-adjust-956 {
  max-width: 956% !important;
}

.width-adjust-957 {
  max-width: 957% !important;
}

.width-adjust-958 {
  max-width: 958% !important;
}

.width-adjust-959 {
  max-width: 959% !important;
}

.width-adjust-960 {
  max-width: 960% !important;
}

.width-adjust-961 {
  max-width: 961% !important;
}

.width-adjust-962 {
  max-width: 962% !important;
}

.width-adjust-963 {
  max-width: 963% !important;
}

.width-adjust-964 {
  max-width: 964% !important;
}

.width-adjust-965 {
  max-width: 965% !important;
}

.width-adjust-966 {
  max-width: 966% !important;
}

.width-adjust-967 {
  max-width: 967% !important;
}

.width-adjust-968 {
  max-width: 968% !important;
}

.width-adjust-969 {
  max-width: 969% !important;
}

.width-adjust-970 {
  max-width: 970% !important;
}

.width-adjust-971 {
  max-width: 971% !important;
}

.width-adjust-972 {
  max-width: 972% !important;
}

.width-adjust-973 {
  max-width: 973% !important;
}

.width-adjust-974 {
  max-width: 974% !important;
}

.width-adjust-975 {
  max-width: 975% !important;
}

.width-adjust-976 {
  max-width: 976% !important;
}

.width-adjust-977 {
  max-width: 977% !important;
}

.width-adjust-978 {
  max-width: 978% !important;
}

.width-adjust-979 {
  max-width: 979% !important;
}

.width-adjust-980 {
  max-width: 980% !important;
}

.width-adjust-981 {
  max-width: 981% !important;
}

.width-adjust-982 {
  max-width: 982% !important;
}

.width-adjust-983 {
  max-width: 983% !important;
}

.width-adjust-984 {
  max-width: 984% !important;
}

.width-adjust-985 {
  max-width: 985% !important;
}

.width-adjust-986 {
  max-width: 986% !important;
}

.width-adjust-987 {
  max-width: 987% !important;
}

.width-adjust-988 {
  max-width: 988% !important;
}

.width-adjust-989 {
  max-width: 989% !important;
}

.width-adjust-990 {
  max-width: 990% !important;
}

.width-adjust-991 {
  max-width: 991% !important;
}

.width-adjust-992 {
  max-width: 992% !important;
}

.width-adjust-993 {
  max-width: 993% !important;
}

.width-adjust-994 {
  max-width: 994% !important;
}

.width-adjust-995 {
  max-width: 995% !important;
}

.width-adjust-996 {
  max-width: 996% !important;
}

.width-adjust-997 {
  max-width: 997% !important;
}

.width-adjust-998 {
  max-width: 998% !important;
}

.width-adjust-999 {
  max-width: 999% !important;
}

.width-adjust-1000 {
  max-width: 1000% !important;
}

.asteric {
  color: #d0021b !important;
}

.text-noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.shadow-1 {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-125 {
  line-height: 1.25 !important;
}

.line-height-075 {
  line-height: 0.75 !important;
}

.line-height-1775 {
  line-height: 1.175 !important;
}

.table-fixed {
  table-layout: fixed !important;
}

.animate-show-hide.ng-hide {
  opacity: 0;
}

.animate-show-hide.ng-hide-add,
.animate-show-hide.ng-hide-remove {
  transition: all linear 2s;
}

.upload-progress {
  height: 0.5rem !important;
}
.upload-progress .progress-bar {
  background-color: #132D77 !important;
}

.transition-all {
  transition: all 0.3s ease-in-out !important;
}

.rotate-180 {
  transform: rotate(180deg);
}

.disable-click {
  pointer-events: none;
}

.enable-click {
  pointer-events: auto;
}

.block-with-text {
  position: relative;
  line-height: 1.2em;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
}

.position-relative {
  position: relative;
}

.jiggle {
  animation: shake 0.92s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 6.25rem;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-0.125rem, 0, 0);
  }
  20%, 80% {
    transform: translate3d(0.25rem, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-0.5rem, 0, 0);
  }
  40%, 60% {
    transform: translate3d(0.5rem, 0, 0);
  }
}
.number-circle {
  border-radius: 50%;
  padding: 3px;
  color: #ffffff;
  text-align: center;
  width: 24px;
  height: 24px;
  background: #74e5d1;
}

textarea + .loader,
input + .loader {
  width: 1.25em;
  height: 1.25em;
  top: 0.5em;
  right: 0.313em;
}

.green {
  color: #3db98c;
}

.yellow {
  color: #ffc74f;
}

/* latin-ext */
@font-face {
  font-family: "plusjakartasans";
  font-weight: 400;
  src: url(../fonts/PlusJakartaSans-Regular.ttf) format("truetype");
}
/* latin-ext */
@font-face {
  font-family: "plusjakartasans-bold";
  font-weight: 700;
  src: url(../fonts/PlusJakartaSans-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "plusjakartasans-light";
  font-weight: 300;
  src: url(../fonts/PlusJakartaSans-Light.ttf) format("truetype");
}
@font-face {
  font-family: "plusjakartasans-italic";
  font-weight: 300;
  src: url(../fonts/PlusJakartaSans-Italic.ttf) format("truetype");
}
@font-face {
  font-family: "icomoon";
  src: url(../fonts/icomoon.ttf) format("truetype");
}
@font-face {
  font-family: "material-icons";
  src: url(../fonts/MaterialIcons-Regular.ttf) format("truetype");
}
.hg-font {
  font-family: "plusjakartasans" !important;
}

.hg-font-bold {
  font-family: "plusjakartasans-bold" !important;
}

.hg-icons, .mh-material-icons, .mh-material-icons-small, .mh-material-icons-big, [class*=icon-], [class^=icon-] {
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^=icon-] {
  font-family: mh-icomoon;
}

[class*=icon-] {
  font-family: mh-icomoon;
}

.mh-material-icons, .mh-material-icons-small, .mh-material-icons-big {
  font-family: "material-icons";
  font-size: 1.25rem;
  /* Preferred icon size */
  display: inline-block;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  color: #4A4947;
  text-rendering: optimizeLegibility;
  vertical-align: middle;
  /* Support for IE. */
  font-feature-settings: "liga";
}

.mh-material-icons-big {
  font-size: 1.5rem;
}

.mh-material-icons-small {
  font-size: 1rem;
}

.icon-campaigns:before {
  content: "\e900";
}

.icon-dashboards:before {
  content: "\e901";
}

.icon-planning_research:before {
  content: "\e902";
}

.icon-settings:before {
  content: "\e903";
}

.icon-target_audience:before {
  content: "\e904";
}

* {
  font-size: 100%;
}
*::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}
*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0.375rem white;
  border-radius: 0.313rem;
}
*::-webkit-scrollbar-thumb {
  border-radius: 0.313rem;
  -webkit-box-shadow: inset 0 0 0.375rem #4A4947;
}

body {
  font-family: "plusjakartasans", Arial, sans-serif !important;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  background: #F9FAFD;
  color: #4A4947;
  height: 100% !important;
  margin: 0;
  padding: 0;
  display: flex !important;
  flex-direction: column !important;
  color: #4A4947 !important;
}

html {
  margin: 0;
  padding: 0;
  height: 100% !important;
  font-family: "plusjakartasans";
}

h1,
.h1 {
  font-size: 2.25rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

h2,
.h2 {
  font-size: 1.5rem;
  font-weight: 500;
}

h3,
.h3 {
  font-size: 1.25rem;
  font-weight: 500;
  color: #4A4947;
}

h4,
.h4 {
  font-size: 1.25rem;
  line-height: 2rem;
  color: #4A4947;
}

h5,
.h5 {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #4A4947;
}

h6,
.h6 {
  font-size: 1rem;
  color: #4A4947;
}

p {
  color: #4A4947;
  font-size: 1rem;
  margin: 0;
  padding: 0;
}

.btn-link-primary {
  text-decoration: none !important;
  color: #132D77;
}
.btn-link-primary:hover {
  color: #2876BF;
}

.link-primary {
  text-decoration: none;
  color: #132D77 !important;
}
.link-primary:hover {
  color: #2876BF !important;
  text-decoration: none;
}
.link-primary:active, .link-primary.active, .link-primary.focus, .link-primary:focus {
  text-decoration: none;
  color: #132D77 !important;
}
.link-primary .mh-material-icons, .link-primary .mh-material-icons-big, .link-primary .mh-material-icons-small {
  color: #132D77;
  text-decoration: none;
}
.link-primary .close-button {
  color: #000;
  text-decoration: none;
}

a.link-interation {
  text-decoration: none;
  color: #0202ea !important;
}
a.link-interation.hover, a.link-interation:hover {
  color: #0202ea !important;
  text-decoration: underline !important;
  text-decoration-color: #0202ea;
}
a.link-interation:active, a.link-interation.active, a.link-interation:focus, a.link-interation.focus {
  text-decoration: none !important;
  color: #0303b4 !important;
}

/*
 * Patient Engagement Solutions Color System
 * source: https://www.figma.com/file/Lu88Lo49fvHlkG9qDh1SfC/_DesignSystem?node-id=0%3A1
 */
/* box-shadow for popover*/
/* box-shadow for card tiles*/
/* variables for button group*/
/* legend bullet for chart  */
/* mercury standard text colors */
/* Form-controls */
/* Bootstrap overrides */
/* border radius for all browsers*/
/* min height for containers*/
/* common style for Map Containers */
/*.btn class for all the bootstrap button features
.btn-sm - for buttons with .75rem text\
btn will take size of the parent it is contained in */
.btn {
  padding: 0.344rem 1.25rem;
  font-size: 0.875rem;
  font-weight: 700;
  max-height: 2.125rem;
}

.btn-sm, .btn-group-sm > .btn {
  font-size: 0.75rem;
}

button:focus, button.focus, button:active, button.active, button:hover,
.btn:focus,
.btn.focus,
.btn:active,
.btn.active,
.btn:hover {
  border: #132D77 1px solid;
  box-shadow: 0 0.063rem 0.188rem 0 #F7F7F7, inset 0 1px 3px 0 rgba(0, 0, 0, 0.16);
}

.btn-primary, .btn-tertiary {
  color: #FFFFFF;
  background-color: #132D77 !important;
  border: #132D77 0.063rem solid;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  border-radius: 0.25rem;
  box-shadow: none !important;
  font-weight: 700;
}
.btn-primary.focus, .focus.btn-tertiary, .btn-primary:active, .btn-tertiary:active, .btn-primary.active, .active.btn-tertiary, .btn-primary:focus, .btn-tertiary:focus {
  color: #FFFFFF;
  background-color: #132D77 !important;
  border: #132D77 0.063rem solid !important;
}
.btn-primary:hover, .btn-tertiary:hover {
  color: #FFFFFF;
  background-color: #2876BF !important;
  border: #2876BF 0.063rem solid !important;
  box-shadow: none !important;
}
.btn-primary .mh-material-icons, .btn-tertiary .mh-material-icons, .btn-primary .mh-material-icons-big, .btn-tertiary .mh-material-icons-big, .btn-primary .mh-material-icons-small, .btn-tertiary .mh-material-icons-small {
  color: #ffffff;
}
.btn-primary.disabled, .btn-primary.disabled-opacity-1, .disabled.btn-tertiary, .btn-tertiary.disabled-opacity-1, .btn-primary:disabled, .btn-tertiary:disabled {
  opacity: 0.5 !important;
  pointer-events: none;
  background-color: #F7F7F7 !important;
  color: #9FA1A2 !important;
  border: #F7F7F7 0.063rem solid !important;
  pointer-events: none;
  font-weight: 700;
}

.btn-tertiary {
  background-color: #4DC7D1 !important;
  border: #4DC7D1 0.063rem solid;
}
.btn-tertiary:hover {
  border: #4DC7D1 0.063rem solid !important;
}

.btn-primary-hollow {
  color: #132D77;
  background-color: #FFFFFF;
  border: #132D77 0.063rem solid;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  border-radius: 0.25rem;
}
.btn-primary-hollow:focus, .btn-primary-hollow:active, .btn-primary-hollow.active, .btn-primary-hollow.focus, .btn-primary-hollow:hover {
  color: #132D77;
  background-color: #FFFFFF;
  border: #132D77 0.063rem solid;
  box-shadow: none;
}
.btn-primary-hollow:hover {
  color: #ffffff !important;
  background-color: #132D77 !important;
  border: #132D77 0.063rem solid !important;
  box-shadow: none;
}
.btn-primary-hollow.disabled, .btn-primary-hollow.disabled-opacity-1, .btn-primary-hollow:disabled {
  background-color: #F7F7F7 !important;
  color: #9FA1A2 !important;
  border: #F7F7F7 0.063rem solid !important;
  pointer-events: none;
  font-weight: 700;
}

.btn-secondary {
  background-color: #132D77 !important;
  border: #132D77 0.063rem solid !important;
  box-shadow: none !important;
}
.btn-secondary:hover {
  border: #132D77 0.063rem solid !important;
}

.btn-secondary-hollow {
  color: #132D77;
  background-color: #ffffff;
  border: #132D77 0.063rem solid;
  margin-bottom: 13.1px;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  border-radius: 0.25rem;
}
.btn-secondary-hollow:focus, .btn-secondary-hollow:active, .btn-secondary-hollow.active, .btn-secondary-hollow.focus, .btn-secondary-hollow:hover {
  color: #132D77;
  background-color: #ffffff;
  border: #132D77 0.063rem solid;
  box-shadow: none;
  outline: none !important;
}

.btn-secondary-hollow-thin {
  border: 0.063rem solid #132D77 !important;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  border-radius: 0.25rem;
}
.btn-secondary-hollow-thin:focus, .btn-secondary-hollow-thin:active, .btn-secondary-hollow-thin.active, .btn-secondary-hollow-thin.focus, .btn-secondary-hollow-thin:hover {
  border: 0.063rem solid #132D77 !important;
  outline: none !important;
}

.btn-link {
  border: none !important;
  text-decoration: none !important;
  color: #132D77;
}
.btn-link:focus, .btn-link:active, .btn-link.active, .btn-link.focus, .btn-link:hover {
  color: #132D77;
  border: none;
  box-shadow: none !important;
}
.btn-link.disabled, .btn-link.disabled-opacity-1, .btn-link:disabled {
  background-color: #F7F7F7 !important;
  color: #9FA1A2 !important;
  border: #F7F7F7 0.063rem solid !important;
  pointer-events: none;
  font-weight: 700;
}

.btn-danger {
  background-color: #b33927 !important;
  border: #b33927 0.063rem solid !important;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  border-radius: 0.25rem;
}
.btn-danger:hover {
  border: #b33927 0.063rem solid !important;
}
.btn-danger.disabled, .btn-danger.disabled-opacity-1, .btn-danger:disabled {
  background-color: #F7F7F7 !important;
  color: #9FA1A2 !important;
  border: #F7F7F7 0.063rem solid !important;
  pointer-events: none;
  font-weight: 700;
}

.btn-light {
  background-color: #ffffff !important;
  border: #ffffff 0.063rem solid;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  border-radius: 0.25rem;
  outline: none !important;
  color: #9FA1A2;
}
.btn-light:not(.bg-primary):hover {
  box-shadow: none;
  border: #ffffff 0.063rem solid;
  color: #000 !important;
}

.disabled, .disabled-opacity-1 {
  opacity: 0.5 !important;
  pointer-events: none;
  font-weight: 700;
}
.disabled > a.link-primary, .disabled-opacity-1 > a.link-primary {
  color: #9FA1A2 !important;
}

.disabled-opacity-1 {
  opacity: 1 !important;
}

.error-message-container {
  height: 80vh;
  background-color: #ffffff;
}

.add-shadow {
  box-shadow: 0 2px 4px 0 hsla(0, 0%, 0%, 0.25);
}

.min-height {
  min-height: 10rem;
  color: #9B9B9B;
}

.wrapper-content {
  margin-top: 5.063rem;
}

#loading-bar-spinner {
  display: none;
}

.spinner-border-sm {
  border-color: #F3F3F3;
  border-top-color: #52514C;
}

@media (min-width: 992px) {
  .vh-lg-100 {
    min-height: 100vh;
  }
}
@media (min-width: 1320px) {
  .container-fluid:not(.login-container), .container-sm:not(.login-container), .container-md:not(.login-container), .container-lg:not(.login-container), .container-xl:not(.login-container), .container-xxl:not(.login-container) {
    margin-left: auto;
    margin-right: auto;
    padding-left: 0.938rem;
    padding-right: 0.938rem;
    width: 100%;
  }
}
/* Pagination Style */
.pagination .page-item.arrow {
  height: 2.25rem;
  width: 36px;
  background: #4DC7D1;
  -webkit-border-radius: 0.188rem;
  -moz-border-radius: 0.188rem;
  -ms-border-radius: 0.188rem;
  border-radius: 0.188rem;
  cursor: pointer;
}
.pagination .page-item.disabled, .pagination .page-item.disabled-opacity-1 {
  pointer-events: none;
}

/* table Style */
table.table thead th span.sortable i {
  color: #4A4947;
  visibility: hidden;
  padding: 0.5rem 0rem 0.5rem 0rem;
}
table.table thead th span.sortable:hover {
  background: #e3e6ee;
  cursor: pointer;
}
table.table thead th span.sortable:hover i {
  visibility: visible;
}
table.table thead th span.sortable.default i, table.table thead th span.sortable.active i {
  visibility: visible;
}
table.table thead th {
  color: #4A4947;
  border-bottom: none;
  font-size: 0.75rem;
  font-weight: 700;
  color: #4A4947 !important;
}
table.table tbody {
  font-size: 1rem;
  color: #4A4947 !important;
}
table.table tbody tr {
  border-bottom: 0.063rem solid #dddddd;
}
table.table tbody tr:last-child {
  border-bottom: none;
}
table.table tbody tr td {
  vertical-align: middle;
  border-bottom: 0.063rem solid #dddddd;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-disabled {
  cursor: not-allowed;
}

/* Alerts */
.alert-panel {
  min-height: 50px;
  -webkit-border-radius: 0.1875rem;
  -moz-border-radius: 0.1875rem;
  -ms-border-radius: 0.1875rem;
  border-radius: 0.1875rem;
}
.alert-panel.warning {
  background: linear-gradient(0deg, rgba(255, 169, 155, 0.35), rgba(255, 169, 155, 0.35)), #FFFFFF;
  border: solid 0.063rem #C41B07;
}
.alert-panel.success {
  background: rgba(218, 244, 245, 0.35);
  border: solid 0.063rem #41B8B2;
}
.alert-panel .toast-body {
  padding: 0;
}
.alert-panel .img-container.warning {
  background-color: #C41B07;
}
.alert-panel .img-container.success {
  background-color: rgba(218, 244, 245, 0.35);
}
.alert-panel .img-container .mh-material-icons-warning {
  font-size: 1.5rem;
  color: #ffffff;
}
.alert-panel .message {
  font-size: 0.875rem;
  color: #4A4947;
}

.ui-notification {
  max-width: 100%;
  width: auto !important;
  z-index: 9999;
}
.ui-notification.alert-panel.killed {
  transition: opacity 5s ease-out;
  opacity: 0;
  display: none !important;
}

.tags .tag {
  background: #74e5d1;
  color: #4a4a4a;
  -webkit-border-radius: 0.125rem;
  -moz-border-radius: 0.125rem;
  -ms-border-radius: 0.125rem;
  border-radius: 0.125rem;
}
.tags .tag > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tags .tag .cancel {
  line-height: 1;
}

.alert-success {
  color: #4A4947;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.5);
  border: solid 0.063rem #132D77;
  background-color: rgba(32, 148, 138, 0.08);
}

.fix-table-layout {
  table-layout: fixed;
}

.link-disabled {
  pointer-events: none;
}

.alert .close {
  display: none;
}

.export-modal .download-icon {
  width: 179px;
  height: 115px;
  cursor: pointer;
  -webkit-border-radius: 0.188rem;
  -moz-border-radius: 0.188rem;
  -ms-border-radius: 0.188rem;
  border-radius: 0.188rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.export-modal .img-col {
  width: 32px;
  height: 32px;
}

.no-transition {
  -webkit-transition-property: none;
  -moz-transition-property: none;
  -o-transition-property: none;
  transition-property: none;
}

.page-min-height {
  min-height: 25rem;
}

.text-grey {
  color: #737373;
}

.text-grey-2 {
  color: #666666;
}

.text-red {
  color: #B10500;
}

.warning-message-text {
  color: #B10500;
  font-size: 0.75rem;
  font-style: italic;
  font-family: "plusjakartasans-italic";
  display: inline-block;
  vertical-align: middle;
}

.unauthorized-container .hg-nav-container .hg-nav-bar .nav-item .user-nav {
  padding-bottom: 0.875rem !important;
}
.unauthorized-container .hg-nav-container .hg-nav-bar .nav-item .shrink-user {
  padding-bottom: 0rem !important;
}
.unauthorized-container .footer-label {
  margin-bottom: 1.875rem !important;
}
.unauthorized-container .text-secondary {
  color: #636366 !important;
}

.position-sticky {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
}

.logout-notify .current-user-name {
  color: #132D77;
}

.has-warning.error-message-background {
  background-color: rgba(235, 238, 244, 0.7);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}

.spinner-border.small {
  width: 1.5rem;
  height: 1.5rem;
}

.bg-danger {
  background-color: #B33926 !important;
}

@media (min-width: 481px) {
  #preAuthLoginContainer,
  #authLoginContainer {
    padding: 1em 2.5em;
    background-color: #ffffff;
    box-shadow: 0 0.125rem 0.5rem 0 rgba(0, 0, 0, 0.15);
    border-left: 0.375rem solid #1B5162;
  }
  #preAuthLoginContainer .auth0-lock-header,
  #authLoginContainer .auth0-lock-header {
    display: none;
  }
  #preAuthLoginContainer .auth0-lock-error-msg,
  #authLoginContainer .auth0-lock-error-msg {
    width: 6.875rem;
  }
  #preAuthLoginContainer .auth0-sso-notice-container,
  #authLoginContainer .auth0-sso-notice-container {
    margin: 0 0 0.625rem 0;
  }
  #preAuthLoginContainer .auth0-lock.auth0-lock button:hover,
  #authLoginContainer .auth0-lock.auth0-lock button:hover {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
  }
  #preAuthLoginContainer .auth0-lock.auth0-lock .auth0-lock-submit,
  #authLoginContainer .auth0-lock.auth0-lock .auth0-lock-submit {
    letter-spacing: 0;
    padding: 0.344rem 1.25rem;
    font-size: 0.875rem;
    font-weight: 700;
    font-family: "plusjakartasans";
    font-size: 1rem;
    line-height: 1;
    width: 50%;
    border-radius: 0.125rem;
    margin: 0 auto;
    width: 9.375rem;
    transition: none !important;
    background: #ffffff !important;
    border: solid 0.125rem #4A4947;
    color: #4A4947;
  }
  #preAuthLoginContainer .auth0-lock.auth0-lock .auth0-lock-submit span,
  #authLoginContainer .auth0-lock.auth0-lock .auth0-lock-submit span {
    transition: none !important;
  }
  #preAuthLoginContainer .auth0-lock.auth0-lock .auth0-lock-submit .auth0-label-submit svg,
  #authLoginContainer .auth0-lock.auth0-lock .auth0-lock-submit .auth0-label-submit svg {
    display: none;
  }
  #preAuthLoginContainer .auth0-lock.auth0-lock .auth0-lock-content,
  #authLoginContainer .auth0-lock.auth0-lock .auth0-lock-content {
    padding: 0;
    margin-bottom: 1.875rem;
  }
  #preAuthLoginContainer .auth0-lock.auth0-lock .auth0-lock-content .auth0-lock-last-login-pane p,
  #authLoginContainer .auth0-lock.auth0-lock .auth0-lock-content .auth0-lock-last-login-pane p {
    color: #424242;
    font-size: 1.25rem !important;
  }
  #preAuthLoginContainer .auth0-lock.auth0-lock .auth0-lock-content .auth0-lock-alternative-link,
  #authLoginContainer .auth0-lock.auth0-lock .auth0-lock-content .auth0-lock-alternative-link {
    color: #9d9c9c;
    font-size: 1rem;
  }
  #preAuthLoginContainer .auth0-lock.auth0-lock .auth0-lock-content .auth0-lock-alternative-link:hover,
  #authLoginContainer .auth0-lock.auth0-lock .auth0-lock-content .auth0-lock-alternative-link:hover {
    color: #0000ff;
  }
  #preAuthLoginContainer .auth0-lock.auth0-lock .auth0-lock-view-content,
  #authLoginContainer .auth0-lock.auth0-lock .auth0-lock-view-content {
    margin-top: 1.563rem;
  }
  #preAuthLoginContainer .auth0-lock.auth0-lock .auth0-lock-form p,
  #authLoginContainer .auth0-lock.auth0-lock .auth0-lock-form p {
    margin-top: 0;
    text-align: center;
  }
  #preAuthLoginContainer .auth0-lock.auth0-lock .auth0-loading-container .auth0-loading,
  #authLoginContainer .auth0-lock.auth0-lock .auth0-loading-container .auth0-loading {
    left: 38%;
  }
  #preAuthLoginContainer .auth0-lock.auth0-lock .auth0-lock-widget,
  #authLoginContainer .auth0-lock.auth0-lock .auth0-lock-widget {
    width: 24rem;
  }
  #preAuthLoginContainer .auth0-lock.auth0-lock .auth0-global-message.auth0-global-message-error,
  #authLoginContainer .auth0-lock.auth0-lock .auth0-global-message.auth0-global-message-error {
    text-align: left;
    background: #FFA99B;
    border: 0.063rem solid #E64A31;
    color: #4A4947;
    border-radius: 0.25rem;
  }
  #preAuthLoginContainer .auth0-lock.auth0-lock .auth0-global-message.auth0-global-message-error .animated.fadeInUp::before,
  #authLoginContainer .auth0-lock.auth0-lock .auth0-global-message.auth0-global-message-error .animated.fadeInUp::before {
    content: url("../images/legacy/error-octagon.svg");
    vertical-align: middle;
    padding-right: 0.625rem;
    line-height: 1;
  }
  #preAuthLoginContainer .auth0-lock.auth0-lock .auth0-global-message.auth0-global-message-error .animated > span,
  #authLoginContainer .auth0-lock.auth0-lock .auth0-global-message.auth0-global-message-error .animated > span {
    vertical-align: middle;
    display: inline-block;
  }
  #preAuthLoginContainer #userName,
  #authLoginContainer #userName {
    text-transform: lowercase;
  }
  #preAuthLoginContainer .auth0-lock-center,
  #authLoginContainer .auth0-lock-center {
    padding-top: 0;
    padding-bottom: 0.8em;
    padding: 0;
  }
}
/*
   * Sticky Footer Behavior
   */
app-root {
  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;
}

#app-header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
  background-color: #F9FAFD;
}

#app-content {
  width: 100%;
  background-color: #F9FAFD;
  margin-top: 9.5rem;
}

#app-footer {
  width: 100%;
}

/*
   * Helper class for styling links/href elements over the template dark background.
   */
.mh-link-on-dark-bg,
.mh-link-on-dark-bg:hover,
.mh-link-on-dark-bg:focus {
  color: #CDECEB;
  text-decoration: none;
}

/*
   * Helper class for styling text over the template dark background.
   */
.mh-text-on-dark-bg {
  color: #FFFFFF;
}

#app-content > .mh-section-content {
  background-color: #F9FAFD;
}

.dropdown-item {
  font-family: "plusjakartasans";
  font-size: 0.875rem;
}
.dropdown-item:active {
  background-color: transparent !important;
  color: inherit;
}

/** input
.form-group -->div
.form-control ==> input(text,number text area)
should have label
**/
.form-group label {
  color: #4A4947;
  font-size: 0.875rem;
}

.form-control:focus {
  border: 0.063rem solid #132D77 !important;
  box-shadow: 0 0.063rem 0.188rem 0 #F7F7F7, inset 0 0.063rem 0.188rem 0 rgba(0, 0, 0, 0.16);
}
.form-control::placeholder,
.form-control .select-placeholder {
  color: #bbbbbb;
  opacity: 1;
  font-weight: normal;
}
.form-control::-webkit-input-placeholder, .form-control::-moz-placeholder, .form-control:-ms-input-placeholder, .form-control::-ms-input-placeholder {
  color: #bbbbbb;
  opacity: 1;
}

.save-loader {
  width: 1.875rem;
  height: 1.875rem;
}

input.form-control,
textarea.form-control,
select.form-control,
.input-group > input + i {
  font-size: 1rem;
  text-align: left;
  background: #ffffff;
  border: 0.063rem solid #d5eff7 !important;
  -webkit-border-radius: 0.188rem;
  -moz-border-radius: 0.188rem;
  -ms-border-radius: 0.188rem;
  border-radius: 0.188rem;
  color: #4A4947;
}
input.form-control option,
textarea.form-control option,
select.form-control option,
.input-group > input + i option {
  color: #4A4947;
}
input.form-control option:hover, input.form-control option:focus,
textarea.form-control option:hover,
textarea.form-control option:focus,
select.form-control option:hover,
select.form-control option:focus,
.input-group > input + i option:hover,
.input-group > input + i option:focus {
  background-color: #ffffff !important;
  color: #4A4947 !important;
}
input.form-control option:not(:checked),
textarea.form-control option:not(:checked),
select.form-control option:not(:checked),
.input-group > input + i option:not(:checked) {
  background-color: #ffffff;
}
input.form-control:focus,
textarea.form-control:focus,
select.form-control:focus,
.input-group > input + i:focus {
  background: #e9f0fd;
  border: 0.063rem solid #132D77;
  box-shadow: 0 0.063rem 0.188rem 0 #F7F7F7, inset 0 0.063rem 0.188rem 0 rgba(0, 0, 0, 0.16);
  -webkit-border-radius: 0.188rem;
  -moz-border-radius: 0.188rem;
  -ms-border-radius: 0.188rem;
  border-radius: 0.188rem;
}
input.form-control:focus > option:checked,
textarea.form-control:focus > option:checked,
select.form-control:focus > option:checked,
.input-group > input + i:focus > option:checked {
  background-color: #132D77 !important;
  color: #FFFFFF !important;
}
input.form-control[disabled], input.form-control.disabled, input.form-control.disabled-opacity-1,
input.form-control option[disabled],
textarea.form-control[disabled],
textarea.form-control.disabled,
textarea.form-control.disabled-opacity-1,
textarea.form-control option[disabled],
select.form-control[disabled],
select.form-control.disabled,
select.form-control.disabled-opacity-1,
select.form-control option[disabled],
.input-group > input + i[disabled],
.input-group > input + i.disabled,
.input-group > input + i.disabled-opacity-1,
.input-group > input + i option[disabled] {
  background-color: #F7F7F7;
  border: solid 1px #c5ced3;
  color: #bbbbbb;
  opacity: 1;
}
input.form-control input[disabled].control-label.dollar-thick,
textarea.form-control input[disabled].control-label.dollar-thick,
select.form-control input[disabled].control-label.dollar-thick,
.input-group > input + i input[disabled].control-label.dollar-thick {
  border: 1px solid #c5ced3;
  background-color: #f0f3fa;
  color: #343434;
}

select.form-control,
.selectbox {
  height: auto;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.controls > input:focus + .control-label,
.controls > input + .control-label, .controls > input:disabled + .control-label {
  float: left;
  padding: 3px 6px 1px;
  font-size: 1.25rem;
  border-radius: 3px 0 0 3px;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  -ms-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  outline: none;
}
.controls > input:focus + .control-label {
  background-color: #132D77;
  border: solid 0.063rem #132D77;
  color: #ffffff;
}
.controls > input:disabled + .control-label {
  background-color: #F7F7F7;
  border: solid 1px #c5ced3;
  color: #888b8d !important;
}

.controls > input {
  outline: none;
  padding: 5px 5px 4px 10px;
  border: solid 1px #c5ced3;
  border-left: none;
  width: 55%;
  margin-left: -1px;
  line-height: 1.6;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -ms-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}
.controls > input:disabled {
  background: #F7F7F7;
}
.controls > input + .control-label {
  border: solid 1px #c5ced3;
  background-color: #f0f3fa;
  color: #4A4947;
  font-weight: 600;
}
.controls > input:focus {
  border: solid #132D77;
  box-shadow: 0 0.063rem 0.188rem 0 #F7F7F7, inset 0 0.063rem 0.188rem 0 rgba(0, 0, 0, 0.16);
}
.controls > input::-webkit-input-placeholder, .controls > input::-moz-placeholder, .controls > input:-ms-input-placeholder, .controls > input::-ms-input-placeholder, .controls > input::placeholder {
  color: #bbbbbb;
  opacity: 1;
}

textarea {
  resize: none;
}

/* warning css*/
.form-group.has-warning label,
.form-group.has-warning i {
  color: #000;
}
.form-group.has-warning.error-text,
.form-group.has-warning small.error-text,
.form-group.has-warning .error-text.small,
.form-group.has-warning p.error-text,
.form-group.has-warning span.error-text,
.form-group.has-warning div.error-text {
  color: #C41B07;
  font-size: 0.75rem;
  font-style: italic;
  font-family: "plusjakartasans-italic";
}
.form-group.has-warning .form-control,
.form-group.has-warning input {
  background: #ffffff;
  border: 0.063rem solid #C41B07;
  -webkit-border-radius: 0.125rem;
  -moz-border-radius: 0.125rem;
  -ms-border-radius: 0.125rem;
  border-radius: 0.125rem;
}
.form-group.has-warning .form-control:focus,
.form-group.has-warning input:focus {
  border: solid 0.063rem #C41B07;
}
.form-group.has-warning .control-label.dollar-thick {
  background-color: #C41B07;
  color: white;
  border: 0.063rem solid #C41B07;
}
.form-group.has-warning .warning-image {
  font-size: 1.25rem !important;
  color: #C41B07;
  position: relative;
  top: -3px !important;
  right: 0px !important;
}
.form-group.has-warning .warning-image-number-inline {
  font-size: 1.25rem;
  color: #C41B07;
  position: absolute;
  top: 50%;
  margin-top: -0.625rem;
  right: 0;
  margin-right: 0.5rem;
  overflow: visible;
}
.form-group.has-warning .form-control-number-with-icon {
  padding-right: 2rem;
}
.form-group.has-warning .dropdown-menu {
  margin-top: -22px !important;
}

/* warning css Ends */
/*Success CSS */
.has-success .form-control {
  border: solid 1px #0ba131;
}
.has-success .form-control:focus {
  border: solid 1px #0ba131;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.16);
}
.has-success .form-control.material-icon {
  color: #0ba131;
}

.success-image {
  color: #0ba131;
}

/* input end*/
/*  checkbox/Radio  */
.radio,
.checkbox {
  margin: 0.625rem 0rem;
}

.checkbox > [type=checkbox]:checked,
.checkbox > [type=checkbox]:not(:checked),
[type=radio]:checked,
[type=radio]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.checkbox > [type=checkbox]:checked + label,
.checkbox > [type=checkbox]:not(:checked) + label {
  position: relative;
  padding-left: 2.3rem;
  cursor: pointer;
  line-height: 1.72;
  padding-top: 0.1rem;
}

[type=radio]:checked + label:before {
  background: #132D77;
}

.checkbox > [type=checkbox]:checked + label:before,
.checkbox > [type=checkbox]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: -2px;
  width: 1.5rem;
  height: 1.5rem;
  outline: 1px solid #9fa5ab;
}

.checkbox > [type=checkbox]:checked + label:before {
  outline: 0.063rem solid #132D77;
}

.checkbox > [type=checkbox]:checked + label:after,
.checkbox > [type=checkbox]:not(:checked) + label:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 4px;
  font-size: 1.3em;
  line-height: 0.8;
}

.checkbox > [type=checkbox]:not(:checked) + label:after {
  opacity: 1;
  transform: scale(1);
  border: 8px solid #f9f8f6;
}

.checkbox > [type=checkbox]:checked + label:after {
  opacity: 1;
  transform: scale(1);
  border: 0.5rem solid #132D77;
}

.checkbox > [type=checkbox]:focus + label::before {
  border: 0.063rem solid #0202ea;
  box-shadow: 0 0.063rem 0.188rem 0 #c5ced3, inset 0 0.063rem 0.188rem 0 rgba(0, 0, 0, 0.16);
}

/* disabled checkbox/radio */
.checkbox > [type=checkbox]:disabled:checked + label:before,
.checkbox > [type=checkbox]:disabled:not(:checked) + label:before,
[type=radio]:disabled:checked + label:before,
[type=radio]:disabled:not(:checked) + label:before {
  box-shadow: none;
  border-color: #F7F7F7;
  background-color: #ddd;
}

.checkbox > [type=checkbox]:disabled:checked + label:after,
[type=radio]:disabled:checked + label:after {
  color: #999;
}

.checkbox > [type=checkbox]:disabled + label,
[type=radio]:disabled + label {
  color: #aaa;
}

input[type=radio] {
  opacity: 0;
  -moz-opacity: 0;
  -webkit-opacity: 0;
  -o-opacity: 0;
  margin-left: 25px;
}

input[type=radio] + label {
  position: relative;
  padding: 0;
  font-size: 16px;
  line-height: 25px;
  margin: 0 0 10px 0;
}

input[type=radio] + label:before {
  content: "";
  display: inline-block;
  position: relative;
  top: 7px;
  height: 24px;
  width: 24px;
  margin-right: 10px;
  background-color: #f1f1f1;
  border: 1px solid #9fa5ab;
  box-shadow: inset 0px 0px 0px 4px white;
  -webkit-box-shadow: inset 0px 0px 0px 4px white;
  -moz-box-shadow: inset 0px 0px 0px 4px white;
  -o-box-shadow: inset 0px 0px 0px 4px white;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  border-radius: 12px;
}

input[type=radio]:checked + label:before {
  background: #132D77;
  border: 0.063rem solid #132D77;
}

/*  checkbox/Radio  Ends */
/* Campaign togglebutton */
.toggle-button label input[type=checkbox][disabled] + .toggle, .toggle-button label .toggle {
  content: "";
  display: inline-block;
  width: 48px;
  height: 25px;
  background-color: #ffffff;
  -webkit-border-radius: 0.938rem;
  -moz-border-radius: 0.938rem;
  -ms-border-radius: 0.938rem;
  border-radius: 0.938rem;
  margin-right: 0.938rem;
  transition: background 0.3s ease;
  vertical-align: middle;
  border: 1px solid #9fa5ab;
}

/* Toggle Button */
.toggle-button {
  vertical-align: middle;
  display: inline-block;
  padding-left: 10px;
  user-select: none;
  padding-bottom: 3px;
}
.toggle-button label {
  user-select: none;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.26);
}
.toggle-button label input[type=checkbox] {
  opacity: 0;
  width: 0;
  height: 0;
}
.toggle-button label input[type=checkbox]:checked + .toggle {
  background-color: #ffffff;
}
.toggle-button label input[type=checkbox]:checked + .toggle:after {
  left: 23px;
  background-color: #132D77;
  border-color: #132D77;
}
.toggle-button label input[type=checkbox]:focus + .toggle {
  border: 0.063rem solid #0202ea;
  box-shadow: 0 0.063rem 0.188rem 0 #c5ced3, inset 0 0.063rem 0.188rem 0 rgba(0, 0, 0, 0.16);
}
.toggle-button label .toggle {
  text-align: left;
}
.toggle-button label .toggle:after {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  background-color: #f1f1f1;
  border: 1px solid #9fa5ab;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  border-radius: 20px;
  position: relative;
  box-shadow: inset 0 0 0 4px #ffffff;
  -webkit-box-shadow: inset 0 0 0 4px #ffffff;
  -moz-box-shadow: inset 0 0 0 4px #ffffff;
  -o-box-shadow: inset 0 0 0 4px #ffffff;
  left: -1px;
  top: -1px;
  transition: left 0.1s ease, background 0.1s ease, box-shadow 0.1s ease;
}
.toggle-button label input[type=checkbox][disabled] + .toggle:after {
  background-color: #bdbdbd;
}
.toggle-button label input[type=checkbox][disabled] + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.1);
}
.toggle-button label input[type=checkbox][disabled]:checked + .toggle:after {
  background-color: #bdbdbd;
}
.toggle-button input {
  user-select: none;
}
.toggle-button .toggle {
  user-select: none;
}

.form-group.is-focused .togglebutton label {
  color: rgba(0, 0, 0, 0.26);
}
.form-group.is-focused .togglebutton label:hover {
  color: rgba(0, 0, 0, 0.54);
}
.form-group.is-focused .togglebutton label:focus {
  color: rgba(0, 0, 0, 0.54);
}

fieldset[disabled] .form-group.is-focused .togglebutton label {
  color: rgba(0, 0, 0, 0.26);
}

.is-disabled,
.disabled,
.disabled-opacity-1 {
  color: #9FA1A2;
  pointer-events: none;
  cursor: none;
}

/* slider component
For Tablets, iPads (Portrait and Landscape) */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .rzslider ul li:last-child {
    transform: translateX(175px) !important;
  }
}
/* For Tablets, iPads (Portrait) */
@media screen and (min-width: 768px) {
  .rzslider ul li:last-child {
    transform: translateX(175px) !important;
  }
}
/* For Tablets, iPads, Laptops (Landscape) */
@media screen and (min-width: 1024px) {
  .rzslider ul li:last-child {
    transform: translateX(260px) !important;
  }
}
/* For Desktops and Laptops */
@media screen and (min-width: 1224px) {
  .rzslider ul li:last-child {
    transform: translateX(330px) !important;
  }
}
@media screen and (min-width: 1440px) {
  .rzslider ul li:last-child {
    transform: translateX(360px) !important;
  }
}
/* For Large screens */
@media screen and (min-width: 1824px) {
  .rzslider ul li:last-child {
    transform: translateX(500px) !important;
  }
}
@media screen and (min-width: 2560px) {
  .rzslider ul li:last-child {
    transform: translateX(700px) !important;
  }
}
.rzslider {
  margin: 1.625rem 0 0.9375rem;
  /*override default to match with Zeplin*/
}
.rzslider ul li:last-child {
  transform: translateX(280px) !important;
  margin-left: 0.825rem;
}
.rzslider ul li:last-child > span {
  left: 0.5rem;
}
.rzslider .rz-bar {
  height: 0.6875rem;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  border-radius: 0.3125rem;
  background: #dbdfe7;
}
.rzslider .rz-bar-wrapper {
  padding-top: 0;
  margin-top: 0;
}
.rzslider .rz-tick {
  background: inherit;
  margin-left: 0;
  left: 0.3125rem;
  height: 0.5rem;
}
.rzslider .rz-tick.rz-selected {
  background: inherit;
}
.rzslider .rz-selection {
  background: #0000bf;
}
.rzslider .rz-bubble {
  font-size: 0.75rem;
  font-weight: bold;
  color: #4A4947;
  bottom: 0.5rem;
  padding: 0.0625rem 0.25rem;
}
.rzslider .rz-pointer {
  top: -0.25rem;
  width: 1.25rem;
  height: 1.25rem;
  background-color: #132D77;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  border-radius: 1rem;
}
.rzslider .rz-pointer:after {
  top: 0.3125rem;
  left: 0.3125rem;
  width: 0;
  height: 0;
  background: transparent;
}
.rzslider .rz-ticks-values-under .rz-tick-value {
  font-size: 0.75rem;
  color: #4A4947;
  opacity: 0.7;
}

select::-ms-expand {
  display: none;
}

*::-ms-backdrop,
textarea {
  overflow: hidden;
}

input ~ .mh-material-icons.form-control-feedback, input ~ .form-control-feedback.mh-material-icons-big, input ~ .form-control-feedback.mh-material-icons-small {
  color: #dddddd;
}

i.mh-material-icons.more-info-icon, i.more-info-icon.mh-material-icons-big, i.more-info-icon.mh-material-icons-small {
  color: #132D77;
}

/*.btn class for all the bootstrap button features
.btn-sm - for buttons with .75rem text\
btn will take size of the parent it is contained in */
.btn {
  padding: 0.344rem 1.25rem;
  font-size: 0.875rem;
  font-weight: 700;
  max-height: 2.125rem;
}

.btn-sm, .btn-group-sm > .btn {
  font-size: 0.75rem;
}

button:focus, button.focus, button:active, button.active, button:hover,
.btn:focus,
.btn.focus,
.btn:active,
.btn.active,
.btn:hover {
  border: #132D77 1px solid;
  box-shadow: 0 0.063rem 0.188rem 0 #F7F7F7, inset 0 1px 3px 0 rgba(0, 0, 0, 0.16);
}

.btn-primary, .btn-tertiary {
  color: #FFFFFF;
  background-color: #132D77 !important;
  border: #132D77 0.063rem solid;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  border-radius: 0.25rem;
  box-shadow: none !important;
  font-weight: 700;
}
.btn-primary.focus, .focus.btn-tertiary, .btn-primary:active, .btn-tertiary:active, .btn-primary.active, .active.btn-tertiary, .btn-primary:focus, .btn-tertiary:focus {
  color: #FFFFFF;
  background-color: #132D77 !important;
  border: #132D77 0.063rem solid !important;
}
.btn-primary:hover, .btn-tertiary:hover {
  color: #FFFFFF;
  background-color: #2876BF !important;
  border: #2876BF 0.063rem solid !important;
  box-shadow: none !important;
}
.btn-primary .mh-material-icons, .btn-tertiary .mh-material-icons, .btn-primary .mh-material-icons-big, .btn-tertiary .mh-material-icons-big, .btn-primary .mh-material-icons-small, .btn-tertiary .mh-material-icons-small {
  color: #ffffff;
}
.btn-primary.disabled, .disabled.btn-tertiary, .btn-primary.disabled-opacity-1, .btn-tertiary.disabled-opacity-1, .btn-primary:disabled, .btn-tertiary:disabled {
  opacity: 0.5 !important;
  pointer-events: none;
  background-color: #F7F7F7 !important;
  color: #9FA1A2 !important;
  border: #F7F7F7 0.063rem solid !important;
  pointer-events: none;
  font-weight: 700;
}

.btn-tertiary {
  background-color: #4DC7D1 !important;
  border: #4DC7D1 0.063rem solid;
}
.btn-tertiary:hover {
  border: #4DC7D1 0.063rem solid !important;
}

.btn-primary-hollow {
  color: #132D77;
  background-color: #FFFFFF;
  border: #132D77 0.063rem solid;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  border-radius: 0.25rem;
}
.btn-primary-hollow:focus, .btn-primary-hollow:active, .btn-primary-hollow.active, .btn-primary-hollow.focus, .btn-primary-hollow:hover {
  color: #132D77;
  background-color: #FFFFFF;
  border: #132D77 0.063rem solid;
  box-shadow: none;
}
.btn-primary-hollow:hover {
  color: #ffffff !important;
  background-color: #132D77 !important;
  border: #132D77 0.063rem solid !important;
  box-shadow: none;
}
.btn-primary-hollow.disabled, .btn-primary-hollow.disabled-opacity-1, .btn-primary-hollow:disabled {
  background-color: #F7F7F7 !important;
  color: #9FA1A2 !important;
  border: #F7F7F7 0.063rem solid !important;
  pointer-events: none;
  font-weight: 700;
}

.btn-secondary {
  background-color: #132D77 !important;
  border: #132D77 0.063rem solid !important;
  box-shadow: none !important;
}
.btn-secondary:hover {
  border: #132D77 0.063rem solid !important;
}

.btn-secondary-hollow {
  color: #132D77;
  background-color: #ffffff;
  border: #132D77 0.063rem solid;
  margin-bottom: 13.1px;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  border-radius: 0.25rem;
}
.btn-secondary-hollow:focus, .btn-secondary-hollow:active, .btn-secondary-hollow.active, .btn-secondary-hollow.focus, .btn-secondary-hollow:hover {
  color: #132D77;
  background-color: #ffffff;
  border: #132D77 0.063rem solid;
  box-shadow: none;
  outline: none !important;
}

.btn-secondary-hollow-thin {
  border: 0.063rem solid #132D77 !important;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  border-radius: 0.25rem;
}
.btn-secondary-hollow-thin:focus, .btn-secondary-hollow-thin:active, .btn-secondary-hollow-thin.active, .btn-secondary-hollow-thin.focus, .btn-secondary-hollow-thin:hover {
  border: 0.063rem solid #132D77 !important;
  outline: none !important;
}

.btn-link {
  border: none !important;
  text-decoration: none !important;
  color: #132D77;
}
.btn-link:focus, .btn-link:active, .btn-link.active, .btn-link.focus, .btn-link:hover {
  color: #132D77;
  border: none;
  box-shadow: none !important;
}
.btn-link.disabled, .btn-link.disabled-opacity-1, .btn-link:disabled {
  background-color: #F7F7F7 !important;
  color: #9FA1A2 !important;
  border: #F7F7F7 0.063rem solid !important;
  pointer-events: none;
  font-weight: 700;
}

.btn-danger {
  background-color: #b33927 !important;
  border: #b33927 0.063rem solid !important;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  border-radius: 0.25rem;
}
.btn-danger:hover {
  border: #b33927 0.063rem solid !important;
}
.btn-danger.disabled, .btn-danger.disabled-opacity-1, .btn-danger:disabled {
  background-color: #F7F7F7 !important;
  color: #9FA1A2 !important;
  border: #F7F7F7 0.063rem solid !important;
  pointer-events: none;
  font-weight: 700;
}

.btn-light {
  background-color: #ffffff !important;
  border: #ffffff 0.063rem solid;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  border-radius: 0.25rem;
  outline: none !important;
  color: #9FA1A2;
}
.btn-light:not(.bg-primary):hover {
  box-shadow: none;
  border: #ffffff 0.063rem solid;
  color: #000 !important;
}

.disabled, .disabled-opacity-1 {
  opacity: 0.5 !important;
  pointer-events: none;
  font-weight: 700;
}
.disabled > a.link-primary, .disabled-opacity-1 > a.link-primary {
  color: #9FA1A2 !important;
}

.disabled-opacity-1 {
  opacity: 1 !important;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 37.5rem;
    margin: 1.75rem auto;
    top: 20%;
  }
  .modal-content {
    -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    border-radius: 0px;
    color: #4A4947;
  }
  .modal-sm {
    max-width: 26.06rem;
    margin-top: 10rem;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    background-color: #fdfdfd;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
    color: #4A4947;
  }
  .modal-md {
    max-width: 37.5rem;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    max-width: 56.25rem;
    color: #4A4947;
  }
  .modal-lg.start-campaign {
    width: 45.5% !important;
  }
  .modal-lg.risk-model {
    height: 35.625rem;
    width: 45rem !important;
  }
  .modal-lg.audience-list-model {
    max-width: 70rem !important;
  }
}
.modal-content {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  color: #4A4947;
}
.modal-content .modal-body {
  padding-left: 1.15rem;
  padding-top: 0;
}
.modal-content .modal-body h4, .modal-content .modal-body .h4 {
  font-size: 1.25rem;
  font-weight: bold;
  color: #4A4947;
}
.modal-content .modal-body .table th,
.modal-content .modal-body .table tbody,
.modal-content .modal-body .table span,
.modal-content .modal-body .table p {
  color: #4A4947;
}
.modal-content .modal-header {
  border-bottom: none;
  color: #4A4947;
}
.modal-content .modal-header h3, .modal-content .modal-header .h3 {
  font-size: 1.5em;
  font-weight: bold;
  color: #4A4947;
}
.modal-content .modal-footer {
  border-top: none;
}
.modal-content .modal-divider {
  border: 1px solid #D9D9D9;
}

.modal-backdrop-opacity {
  opacity: 15% !important;
}

.close-button {
  cursor: pointer;
  color: #4A4947;
}

.modal-header > .btn-close:hover, .modal-header > .btn-close:active, .modal-header > .btn-close:focus {
  border: none;
  box-shadow: none;
}

.campaign-overview-report-modal .modal-sm {
  margin-top: 10rem;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
}

.alert-warning {
  background-color: rgba(249, 250, 253, 0.3);
}

.modal-backdrop.modal-backdrop-opacity.fade.show {
  z-index: 1050 !important;
}

.session-notifier-modal {
  z-index: 1070 !important;
}

.nav-tabs {
  border-bottom: 0.063rem solid #C6C6C7;
  z-index: 9;
  background-color: #ffffff;
}
.nav-tabs .nav-item {
  cursor: pointer;
  list-style-type: none;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: #4DC7D1;
  padding: 0rem 1rem;
  border-bottom: 0.063rem solid transparent;
  border-radius: 0.125rem;
}
.nav-tabs .nav-item.active {
  border-bottom: 0.063rem solid #4DC7D1;
  border-radius: 0.125rem;
}
.nav-tabs .nav-item a.nav-link {
  color: #4A4947;
  padding: 0.56rem 1rem;
  border: none;
}
.nav-tabs .nav-item a.nav-link .close-button {
  color: #343437;
}
.nav-tabs .nav-item a.nav-link .circle {
  color: #343437;
  width: 1.6rem;
  height: 1.62rem;
  line-height: 1rem;
  background: transparent;
  border: 0.063rem solid #969697;
  border-radius: 50%;
  cursor: pointer;
  font-weight: 600;
}
.nav-tabs .nav-item a.nav-link:hover, .nav-tabs .nav-item a.nav-link:focus, .nav-tabs .nav-item a.nav-link:active {
  border: none;
}
.nav-tabs .nav-item a.nav-link.active .circle {
  color: #ffffff;
  background-color: #4DC7D1;
  font-weight: 600;
  border: 0.063rem solid #4DC7D1;
}
.nav-tabs .nav-item.active a.nav-link {
  font-weight: 600;
  color: #4A4947;
}
.nav-tabs .nav-item.active a.nav-link .circle {
  color: #ffffff;
  background-color: #4DC7D1;
  font-weight: 600;
  border: 0.063rem solid #4DC7D1;
}
.nav-tabs .nav-item + li > .badge {
  background-color: rgba(113, 129, 173, 0.1490196078);
  bottom: 0.625rem;
  color: #0F245F;
}
.nav-tabs .nav-item + li > .badge + .beta-badge-tooltip .tooltip-inner {
  max-width: 13.75rem;
}

.sorter {
  right: 0.938rem;
  top: 0.938rem;
  width: 22%;
  font-size: 0.75rem;
}
.sorter span {
  font-weight: 400;
}

.select-custom {
  border-radius: 0.188rem;
  position: relative;
}
.select-custom select {
  padding-right: 1.875rem;
}
@supports (-moz-appearance: none) {
  .select-custom select {
    padding-right: 2.375rem;
  }
}
.select-custom > i {
  position: absolute;
  top: calc(50% - 1em);
  padding: 0.5rem;
  pointer-events: none;
  right: 0;
}

/*
 * Patient Engagement Solutions Color System
 * source: https://www.figma.com/file/Lu88Lo49fvHlkG9qDh1SfC/_DesignSystem?node-id=0%3A1
 */
/* box-shadow for popover*/
/* box-shadow for card tiles*/
/* variables for button group*/
/* legend bullet for chart  */
/* mercury standard text colors */
/* Form-controls */
/* Bootstrap overrides */
/* border radius for all browsers*/
/* min height for containers*/
/* common style for Map Containers */
.shadow {
  box-shadow: 0 0.063rem 0.188rem 0 rgba(0, 0, 0, 0.25);
}

.exec-dash-min-height {
  min-height: 17.69rem;
}

.exec-dash-min-height-big {
  min-height: 25rem;
}
.exec-dash-min-height-big .error-container {
  min-height: 18rem;
}

.no-data-found {
  min-height: 33rem !important;
}
.no-data-found .error-container {
  min-height: 31rem;
}

.less-height {
  min-height: 5.75rem;
}

.chart-panel {
  margin-top: 1.5rem;
}

.helper-text {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  bottom: 0;
}
.helper-text hr {
  border: 0;
  border-top: 0.063rem solid rgba(0, 0, 0, 0.1);
  margin-bottom: 0.2rem !important;
  margin-top: 0.2rem !important;
}
.helper-text p {
  color: #4A4947;
  margin-bottom: 0.3rem;
}

.chart-bg-container {
  background-color: #ffffff;
  border: solid 0.063rem #d2d5dc;
  border-radius: 0.313rem;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  border-radius: 0.25rem;
}
.chart-bg-container .chart-container {
  width: 100%;
  display: block;
  padding: 0.625rem;
}
.chart-bg-container.container-border {
  border: solid 0.063rem #CDD0D1;
  box-shadow: none !important;
}
.chart-bg-container .chart-title-icon {
  padding-top: 0.625rem;
  padding-left: 1.25rem;
  color: #4A4947;
  font-size: 0.938rem;
  padding-right: 0.625rem;
  font-family: "plusjakartasans-bold";
  text-align: left;
  margin-bottom: 0;
}
.chart-bg-container .chart-title-icon .mh-material-icons, .chart-bg-container .chart-title-icon .mh-material-icons-big, .chart-bg-container .chart-title-icon .mh-material-icons-small {
  color: #4A4947;
}

.highcharts-legend-item > span {
  width: 9.65rem;
}

.highcharts-legend-item .custom-legend > span {
  word-wrap: break-word;
  white-space: normal;
}

.pie-chart .highcharts-legend div div {
  margin-top: 0.188rem;
}

.highcharts-tooltip span {
  opacity: 1;
  z-index: 9999 !important;
}

.highcharts-legend g g {
  display: none;
}

.display-legend .highcharts-legend g g {
  display: block !important;
}

.highcharts-subtitle hr {
  margin-top: 0.425rem;
  margin-bottom: 0.375rem;
}

.highcharts-legend-item:hover text,
.highcharts-legend-item:hover span {
  cursor: default !important;
}

.error-container {
  min-height: 16.8rem;
}

.circle {
  margin-right: 0.313rem;
  width: 0.875rem;
  height: 0.875rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  border-radius: 0.5rem;
  font-size: 0.625rem;
  color: #ffffff;
  line-height: 0.9375rem;
  text-align: center;
  display: inline-block;
  float: left;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  _::-webkit-full-page-media,
  _:future,
  :root,
  .custom-legend {
    line-height: 0.9 !important;
  }
}
.metric h4, .metric .h4 {
  font-size: 0.9375rem;
  font-family: "plusjakartasans-bold";
  color: #4A4947;
}
.metric h4 .mh-material-icons, .metric .h4 .mh-material-icons, .metric h4 .mh-material-icons-big, .metric .h4 .mh-material-icons-big, .metric h4 .mh-material-icons-small, .metric .h4 .mh-material-icons-small {
  color: #53565a;
}
.metric .metric-value {
  color: #4A4947;
}
.metric h5, .metric .h5 {
  font-size: 0.75rem;
  font-family: "plusjakartasans-bold";
  line-height: 1rem !important;
  color: #4A4947;
}
.metric span {
  color: #4A4947;
}
.metric a {
  color: #132D77 !important;
}

.campaign-reports-wrapper .chart-bg-container {
  margin: 1.25rem 0;
}
.campaign-reports-wrapper .chart-bg-container .metric h4, .campaign-reports-wrapper .chart-bg-container .metric .h4 {
  font-size: 1.25rem;
  color: #4A4947;
  line-height: 1.25rem;
}
.campaign-reports-wrapper .chart-bg-container .metric .error-container {
  min-height: 17rem;
}

.campaign-reports-desc {
  border-left: solid 0.063rem #969697;
  float: left;
}

.popover {
  font-family: "plusjakartasans";
  border-radius: 0px;
}
.popover .popover-header {
  border-bottom: none;
  background: transparent;
  text-align: center;
}
.popover .popover-body {
  padding: 0.625rem 1.5625rem 1.25rem 1.5625rem !important;
  max-height: 20rem;
  overflow-y: auto;
}
.popover.preview-popover {
  min-width: 500px;
}

.criteria-segment {
  min-width: 32rem;
}
.criteria-segment .popover-body {
  width: 11.625rem;
  padding: 0.125rem !important;
}
.criteria-segment .popover-body .btn-close {
  padding: 0.25rem 0.8125rem !important;
}
.criteria-segment .popover-body .btn-yes {
  padding: 0.25rem 1.375rem !important;
}

.warning-message {
  min-width: 8.125rem;
}
.warning-message .popover-body {
  width: 14.625rem;
  padding: 0.125rem !important;
}
.warning-message .popover-body .btn-close {
  padding: 0.25rem 0.8125rem !important;
  margin-right: 0.525rem !important;
}
.warning-message .popover-body .btn-yes {
  padding: 0.25rem 1.375rem !important;
}

.tooltip {
  font-family: "plusjakartasans";
}
.tooltip.bs-tooltip-top, .tooltip.bs-tooltip-auto[data-popper-placement^=top], .tooltip.bs-tooltip-bottom, .tooltip.bs-tooltip-auto[data-popper-placement^=bottom] {
  margin: 0.4rem 0 !important;
}
.tooltip.bs-tooltip-start, .tooltip.bs-tooltip-auto[data-popper-placement^=left], .tooltip.bs-tooltip-end, .tooltip.bs-tooltip-auto[data-popper-placement^=right] {
  margin: 0 0.4rem !important;
}
.tooltip .tooltip-inner {
  color: #4A4947;
  background-color: #ffffff;
  border: solid 0.063rem #c5ced3;
  padding: 0.5rem;
  text-align: center;
  word-wrap: break-word;
}

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.bs-tooltip-auto[x-placement^=top] .tooltip-arrow::before {
  border-top-color: #c5ced3 !important;
}

.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.bs-tooltip-auto[x-placement^=right] .tooltip-arrow::before {
  border-right-color: #c5ced3 !important;
}

.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.bs-tooltip-auto[x-placement^=bottom] .tooltip-arrow::before {
  border-bottom-color: #c5ced3 !important;
}

.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.bs-tooltip-auto[x-placement^=left] .tooltip-arrow::before {
  border-left-color: #c5ced3 !important;
}

/*
 * Patient Engagement Solutions Color System
 * source: https://www.figma.com/file/Lu88Lo49fvHlkG9qDh1SfC/_DesignSystem?node-id=0%3A1
 */
/* box-shadow for popover*/
/* box-shadow for card tiles*/
/* variables for button group*/
/* legend bullet for chart  */
/* mercury standard text colors */
/* Form-controls */
/* Bootstrap overrides */
/* border radius for all browsers*/
/* min height for containers*/
/* common style for Map Containers */
.uib-datepicker-popup {
  color: #4A4947;
  padding: 0.313rem !important;
  -webkit-border-radius: 0.125rem;
  -moz-border-radius: 0.125rem;
  -ms-border-radius: 0.125rem;
  border-radius: 0.125rem;
  box-shadow: 0 1.125rem 2.375rem 0 rgba(0, 0, 0, 0.3), 0 0.9375rem 0.75rem 0 rgba(0, 0, 0, 0.22);
  border: solid 0.0625rem transparent;
  background-image: linear-gradient(#fafafa, #fafafa), linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.4) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0));
  background-origin: border-box;
  background-clip: content-box, border-box;
}
.uib-datepicker-popup .btn-light {
  font-size: 0.766rem;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 700;
}
.uib-datepicker-popup .disabled, .uib-datepicker-popup .disabled-opacity-1 {
  background-color: #F7F7F7 !important;
  color: #9FA1A2 !important;
  border: #F7F7F7 0.063rem solid !important;
  pointer-events: none;
  font-weight: 700;
}
.uib-datepicker-popup .ngb-dp-header .ngb-dp-arrow button.btn-link.ngb-dp-arrow-btn {
  color: #4A4947;
  font-size: 12px;
}
.uib-datepicker-popup .ngb-dp-content.ngb-dp-months .ngb-dp-month {
  width: 100%;
}
.uib-datepicker-popup .ngb-dp-content.ngb-dp-months .ngb-dp-month .ngb-dp-week {
  margin-bottom: 5px;
  display: flex !important;
  justify-content: space-between !important;
}
.uib-datepicker-popup .ngb-dp-content.ngb-dp-months .ngb-dp-month .ngb-dp-week.ngb-dp-weekdays > .ngb-dp-weekday.small {
  color: #4A4947;
  font-weight: 700;
  font-size: 0.766rem;
  font-style: normal;
}
.uib-datepicker-popup .ngb-dp-content.ngb-dp-months .ngb-dp-month .ngb-dp-week > .ngb-dp-day.ngb-dp-today .btn-light.bg-primary {
  color: #FFFFFF;
  background-color: #132D77 !important;
  border: #132D77 0.063rem solid !important;
}
.uib-datepicker-popup .ngb-dp-content.ngb-dp-months .ngb-dp-month .ngb-dp-week > .ngb-dp-day.ngb-dp-today .btn-light.bg-primary:hover {
  color: #FFFFFF !important;
}
.uib-datepicker-popup .ngb-dp-content.ngb-dp-months .ngb-dp-month .ngb-dp-week > .ngb-dp-day .btn-light.text-muted.outside {
  color: #6c757d !important;
}
.uib-datepicker-popup .ngb-dp-content.ngb-dp-months .ngb-dp-month .ngb-dp-week > .ngb-dp-day .btn-light.bg-primary.text-white {
  color: #FFFFFF;
  background-color: #132D77 !important;
  border: #132D77 0.063rem solid !important;
}

.drop-dashed-border {
  background: linear-gradient(to right, #d5eff7 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(#d5eff7 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(to right, #d5eff7 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(#d5eff7 50%, rgba(255, 255, 255, 0) 0%);
  background-position: top, right, bottom, left;
  background-repeat: repeat-x, repeat-y;
  background-size: 1em 0.063em, 0.063em 1em;
}
.drop-dashed-border.dragover {
  background: linear-gradient(to right, #132D77 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(#132D77 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(to right, #132D77 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(#132D77 50%, rgba(255, 255, 255, 0) 0%);
  background-position: top, right, bottom, left;
  background-repeat: repeat-x, repeat-y;
  background-size: 1em 0.063em, 0.063em 1em;
}

.tags-container {
  background: #ffffff;
  -webkit-border-radius: 0.188rem;
  -moz-border-radius: 0.188rem;
  -ms-border-radius: 0.188rem;
  border-radius: 0.188rem;
  color: #4A4947;
  border: 0.063rem solid #d5eff7;
  border-top-color: transparent;
  margin-bottom: 0.625rem;
}