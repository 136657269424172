.hg-icons {
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"] {
    font-family: mh-icomoon;
    @extend .hg-icons;
}

[class*="icon-"] {
    font-family: mh-icomoon;
    @extend .hg-icons;
}

//material icons with 20px. Default(used mostly accross app)
.mh-material-icons {
    font-family: $material-icons;
    @extend .hg-icons;
    font-size: 1.25rem;
    /* Preferred icon size */
    display: inline-block;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    color: $text-dark;
    text-rendering: optimizeLegibility;
    vertical-align: middle;
    /* Support for IE. */
    font-feature-settings: 'liga';
}

//material icons as 24px
.mh-material-icons-big {
    @extend .mh-material-icons;
    font-size: 1.5rem;
}

//material icons as 16px
.mh-material-icons-small {
    @extend .mh-material-icons;
    font-size: 1rem;
}

.icon-campaigns {
    &:before {
        content: "\e900";
    }
}

.icon-dashboards {
    &:before {
        content: "\e901";
    }
}

.icon-planning_research {
    &:before {
        content: "\e902";
    }
}

.icon-settings {
    &:before {
        content: "\e903";
    }
}

.icon-target_audience {
    &:before {
        content: "\e904";
    }
}