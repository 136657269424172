.drop-dashed-border {
    background: linear-gradient(to right, $color_border_inactive 50%, rgba(255, 255, 255, 0) 0%), linear-gradient($color_border_inactive 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(to right, $color_border_inactive 50%, rgba(255, 255, 255, 0) 0%), linear-gradient($color_border_inactive 50%, rgba(255, 255, 255, 0) 0%);
    background-position: top, right, bottom, left;
    background-repeat: repeat-x, repeat-y;
    background-size: 1em 0.063em, 0.063em 1em;
    &.dragover {
        background: linear-gradient(to right, $color_primary 50%, rgba(255, 255, 255, 0) 0%), linear-gradient($color_primary 50%, rgba(255, 255, 255, 0) 0%), linear-gradient(to right, $color_primary 50%, rgba(255, 255, 255, 0) 0%), linear-gradient($color_primary 50%, rgba(255, 255, 255, 0) 0%);
        background-position: top, right, bottom, left;
        background-repeat: repeat-x, repeat-y;
        background-size: 1em 0.063em, 0.063em 1em;
    }
}